<template>
    <div v-if="!this.$store.getters.getStandby" :class="layoutContainerClass" @click="onDocumentClick">
        <loader v-if="getAppLoading" :title="loaderTitle" :style="'fullscreen'"></loader>
        <Toast :breakpoints="{ '920px': { width: '100%', right: '0', left: '0' } }" />
        <Toast :breakpoints="{ '920px': { width: '100%', right: '0', left: '0' } }" position="bottom-center" group="bc">
            <template #message="slotProps">
                <div class="d-flex flex-column">
                    <div class="text-center">
                        <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                        <h4>{{ slotProps.message.summary }}</h4>
                        <p>{{ slotProps.message.detail }}</p>
                    </div>
                    <div class="grid fluid">
                        <div class="col-6">
                            <Button class="p-button-success" label="Yes" @click="onConfirm"></Button>
                        </div>
                        <div class="col-6">
                            <Button class="button-secondary" label="No" @click="onReject"></Button>
                        </div>
                    </div>
                </div>
            </template>
        </Toast>
        <AppTopBar
            :horizontal="menuMode === 'horizontal'"
            :topbarMenuActive="topbarMenuActive"
            :activeTopbarItem="activeTopbarItem"
            :mobileTopbarActive="mobileTopbarActive"
            @topbar-mobileactive="onTopbarMobileButtonClick"
            @menubutton-click="onMenuButtonClick"
            @topbar-menubutton-click="onTopbarMenuButtonClick"
            @topbaritem-click="onTopbarItemClick"
            @rightpanel-button-click="onRightPanelButtonClick"
            :searchActive="searchActive"
            @search-toggle="onSearchToggle"
            @search-click="onSearchClick"
            @search-hide="onSearchHide"
        ></AppTopBar>

        <div class="menu-wrapper">
            <div class="layout-menu-container" @click="onMenuClick">
                <AppInlineMenu v-if="inlineMenuPosition === 'top' || inlineMenuPosition === 'both'" v-model:active="inlineMenuTopActive" @change-inlinemenu="onChangeInlineMenu" inlineMenuKey="top" :menuMode="menuMode"></AppInlineMenu>
                <AppMenu :model="menu" :menuMode="menuMode" :active="menuActive" :mobileMenuActive="mobileMenuActive" :isSlimOrHorItemClick="isSlimOrHorItemClick" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick"></AppMenu>
                <AppInlineMenu v-if="inlineMenuPosition === 'bottom' || inlineMenuPosition === 'both'" v-model:active="inlineMenuBottomActive" @change-inlinemenu="onChangeInlineMenu" inlineMenuKey="bottom" :menuMode="menuMode"></AppInlineMenu>
            </div>
        </div>

        <div class="layout-main">
            <AppBreadcrumb></AppBreadcrumb>

            <div class="layout-content" v-if="!getAppLoading">
                <router-view />
            </div>

            <AppFooter :layoutMode="layoutMode" />
        </div>

        <AppRightPanel :expanded="rightPanelActive" @content-click="onRightPanelClick" @hide="onHideClick"></AppRightPanel>
        <div v-if="mobileMenuActive" class="layout-mask modal-in"></div>
    </div>
    <div v-else :class="layoutContainerClass" @click="onDocumentClick">
        <div class="pages-body flex flex-column">
            <div class="topbar p-3 flex justify-content-between flex-row align-items-center bg-red">
                <router-link class="topbar-left ml-3 flex" to="/">
                    <div class="logo">
                        <h2 class="text-light m-0 fg-white pl-0">E<span class="fg-yellow">N</span>AT.AT</h2>
                    </div>
                </router-link>
            </div>

            <div class="align-self-center mt-auto mb-auto">
                <div class="pages-panel card flex flex-column">
                    <div class="pages-header bg-amber px-3 py-1">
                        <h2>STANDBY</h2>
                    </div>
                    <div class="card mt-3 px-6">
                        <img src="layout/icons/cute-tech/051-computer.svg" width="250" height="250" alt=""/>
                    </div>
                    <div class="pages-detail pb-2">{{ this.customerName }}</div>
                    <div class="pages-detail pb-3">{{ this.customerDesc }}</div>
                    <div class="pages-detail pb-3 fg-lightYellow">{{ this.currentTimeFormatted }}</div>
                    
                    <router-link to="/">
                        <Button type="button" label="DASHBOARD" class="p-button-text"></Button>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppInlineMenu from './AppInlineMenu.vue';
import AppRightPanel from './AppRightPanel.vue';
import AppMenu from './AppMenu.vue';
// import AppConfig from './AppConfig.vue';
import AppFooter from './AppFooter.vue';
import AppBreadcrumb from './AppBreadcrumb.vue';
import EventBus from './event-bus';
import mainService from '@/services/mainService.js';
import { findIndexByLabel, Role, formatDateGui } from '@/helpers.js';
import store from '@/store';
import { mapGetters, mapActions } from "vuex";
import { addEventListener, removeEventListener } from '@/services/eventListener';

export default {
    emits: ['layout-mode-change', 'menu-theme', 'menuTheme', 'topbar-theme', 'topbarTheme', 'layoutModeChange', 'loading'],
    props: {
        topbarTheme: String,
        menuTheme: String,
        layoutMode: String,
    },
    mixins: [addEventListener, removeEventListener],
    data() {
        return {
            d_topbarTheme: this.topbarTheme,
            d_menuTheme: this.menuTheme,
            d_layoutMode: this.layoutMode,
            view_menu: null,
            mobileTopbarActive: false,
            mobileMenuActive: false,
            search: false,
            searchClick: false,
            searchActive: false,
            menuMode: 'static',
            inlineMenuClick: false,
            inlineMenuPosition: 'bottom',
            inlineMenuTopActive: false,
            inlineMenuBottomActive: false,
            overlayMenuActive: false,
            rotateMenuButton: false,
            topbarMenuActive: false,
            activeTopbarItem: null,
            isSlimOrHorItemClick: false,
            worker: null,
            timer: undefined,
            loading: false,
            nums: [],
            counter: 0,
            time: null,
            preTime: null,
            darkMenu: true,
            theme: 'bluegrey',
            themes: [
                { name: 'indigo', color: '#2f8ee5' },
                { name: 'pink', color: '#E91E63' },
                { name: 'purple', color: '#9C27B0' },
                { name: 'deeppurple', color: '#673AB7' },
                { name: 'blue', color: '#2196F3' },
                { name: 'lightblue', color: '#03A9F4' },
                { name: 'cyan', color: '#00BCD4' },
                { name: 'teal', color: '#009688' },
                { name: 'green', color: '#4CAF50' },
                { name: 'lightgreen', color: '#8BC34A' },
                { name: 'lime', color: '#CDDC39' },
                { name: 'yellow', color: '#FFEB3B' },
                { name: 'amber', color: '#FFC107' },
                { name: 'orange', color: '#FF9800' },
                { name: 'deeporange', color: '#FF5722' },
                { name: 'brown', color: '#795548' },
                { name: 'bluegrey', color: '#607D8B' },
            ],
            menuThemes: [
                { name: 'light', color: '#FDFEFF' },
                { name: 'dark', color: '#434B54' },
                { name: 'indigo', color: '#1A237E' },
                { name: 'bluegrey', color: '#37474F' },
                { name: 'brown', color: '#4E342E' },
                { name: 'cyan', color: '#006064' },
                { name: 'green', color: '#2E7D32' },
                { name: 'deeppurple', color: '#4527A0' },
                { name: 'deeporange', color: '#BF360C' },
                { name: 'pink', color: '#880E4F' },
                { name: 'purple', color: '#6A1B9A' },
                { name: 'teal', color: '#00695C' },
            ],
            topbarThemes: [
                { name: 'lightblue', color: '#2E88FF' },
                { name: 'dark', color: '#363636' },
                { name: 'white', color: '#FDFEFF' },
                { name: 'blue', color: '#1565C0' },
                { name: 'deeppurple', color: '#4527A0' },
                { name: 'purple', color: '#6A1B9A' },
                { name: 'pink', color: '#AD1457' },
                { name: 'cyan', color: '#0097A7' },
                { name: 'teal', color: '#00796B' },
                { name: 'green', color: '#43A047' },
                { name: 'lightgreen', color: '#689F38' },
                { name: 'lime', color: '#AFB42B' },
                { name: 'yellow', color: '#FBC02D' },
                { name: 'amber', color: '#FFA000' },
                { name: 'orange', color: '#FB8C00' },
                { name: 'deeporange', color: '#D84315' },
                { name: 'brown', color: '#5D4037' },
                { name: 'grey', color: '#616161' },
                { name: 'bluegrey', color: '#163A52' },
                { name: 'indigo', color: '#3F51B5' },
                { name: 'inari', color: '#546E7A' },
            ],
            rightPanelActive: false,
            menuActive: true,
            menu: [
                {
                    label: 'Zentral',
                    icon: 'pi pi-fw pi-home',
                    items: [
                        { label: 'Dashboard', icon: 'mif-dashboard', to: '/view/dashboard' },
                        { label: 'Wetterstation', icon: 'mif-weather', to: '/view/weather', badge: 1, badgeStyleClass: 'p-badge-success' },
                        { label: 'Alarmliste', icon: 'mif-bell', to: '/alarms', badgeText: () => this.getAlarmCount, badgeStyleClass: 'p-badge-danger' },
                        { label: 'Alarm-Log', icon: 'mif-list', to: '/alarmlog' },
                        { label: 'Änderungs-Log', icon: 'mif-list', to: '/changelog' }
                    ],
                },
                {
                    label: 'Bedienung',
                    icon: 'pi pi-fw pi-align-left',
                    items: this.displayMenu,
                },
                {
                    label: 'Einstellungen',
                    icon: 'pi pi-fw pi-align-left',
                    visible: () => this.isUserMaster,
                    items: [
                        {
                            label: 'Systemeinstellungen',
                            icon: 'mif-cogs pi-align-left',
                            items: [
                                { label: 'System', icon: 'mif-equalizer', to: '/systemconfig', visible: () => this.isUserAdmin },
                                { label: 'Netzwerk', icon: 'mif-satellite', to: '/networkconfig', visible: () => this.isUserAdmin },
                                { label: 'Benachrichtigungen', icon: 'mif-comment', to: '/notificationconfig', visible: () => this.isUserMaster },
                            ],
                        },
                        {
                            label: 'Seiten und Menüs',
                            icon: 'mif-open-book pi-align-left',
                            items: [
                                { label: 'Menü', icon: 'mif-menu', to: '/menuconfig', visible: () => this.isUserAdmin },
                                { label: 'Seiten', icon: 'mif-files-empty', to: '/pageconfig', visible: () => this.isUserAdmin },
                                { label: 'Benachrichtigungen', icon: 'mif-comment', to: '/ioconfig' },
                            ],
                        },
                        {
                            label: 'PLC-Konfiguration',
                            icon: 'pi pi-fw pi-box',
                            items: [
                                { label: 'OPC-UA', icon: 'pi pi-fw pi-server', to: '/opcConfig', visible: () => this.isUserAdmin },
                                { label: 'Module', icon: 'pi pi-fw pi-sitemap', to: '/moduleConfig', visible: () => this.isUserAdmin },
                                { label: 'Module Scheme', icon: 'pi pi-fw pi-globe', to: '/moduleScheme', visible: () => this.isUserAdmin },
                                { label: 'Module Bindings', icon: 'pi pi-fw pi-paperclip', to: '/moduleBindings', visible: () => this.isUserAdmin },
                                { label: 'Parameter', icon: 'pi pi-fw pi-table', to: '/parameterConfig', visible: () => this.isUserAdmin },
                            ],
                        },
                        { label: 'Benutzer', icon: 'mif-users', to: '/users', visible: () => this.isUserAdmin },
                        { label: 'System Log', icon: 'mif-list', to: '/systemlog', visible: () => this.isUserMaster },
                    ],
                },
            ],
        };
    },
    watch: {
        $route() {
            this.menuActive = this.isStatic() && !this.isMobile();
            this.$toast.removeAllGroups();
        },
        topbarTheme(newValue) {
            this.d_topbarTheme = newValue;
        },
        menuTheme(newValue) {
            this.d_menuTheme = newValue;
        },
        layoutMode(newValue) {
            this.d_layoutMode = newValue;
        },
        // '$store.getters.getMenuInterface': function () {
        //     this.getMenuList();
        // },
        '$store.getters.viewMenu': function () {
            this.getMenuList();
        },
        isLoggedIn() {
            if (this.isLoggedIn) console.log(`[APP] logged in!`);
            else console.log(`[APP] not logged in!`);
        }
    },
    methods: {
        ...mapActions({
            setAppLoading: 'setAppLoading',
            loadModules: 'opcua/loadModules',
            loadOpcuaServers: 'opcua/loadOpcuaServers',
            setGlobalLoader: 'setGlobalLoader',
        }),
        onDocumentClick() {
            if (!this.searchClick && this.searchActive) {
                this.onSearchHide();
            }

            if (!this.topbarItemClick) {
                this.activeTopbarItem = null;
                this.topbarMenuActive = false;
            }

            if (!this.menuClick) {
                if (this.isHorizontal() || this.isSlim()) {
                    this.menuActive = false;
                    this.isSlimOrHorItemClick = false;
                    EventBus.emit('reset-active-index');
                }

                if (this.isOverlay()) {
                    this.menuActive = false;
                }

                this.hideOverlayMenu();
                this.unblockBodyScroll();
            }

            if (!this.rightPanelClick) {
                this.rightPanelActive = false;
            }

            if (!this.inlineMenuClick) {
                this.inlineMenuTopActive = false;
                this.inlineMenuBottomActive = false;
            }

            this.topbarItemClick = false;
            this.menuClick = false;
            this.rightPanelClick = false;
            this.searchClick = false;
            this.inlineMenuClick = false;
        },
        onSearchToggle() {
            this.searchActive = !this.searchActive;
            this.searchClick = true;
        },
        onSearchClick() {
            this.searchClick = true;
        },
        onSearchHide() {
            this.searchActive = false;
            this.searchClick = false;
        },
        isHorizontal() {
            return this.menuMode === 'horizontal';
        },
        isSlim() {
            return this.menuMode === 'slim';
        },
        isOverlay() {
            return this.menuMode === 'overlay';
        },
        isStatic() {
            return this.menuMode === 'static';
        },
        isDesktop() {
            return window.innerWidth > 991;
        },
        isMobile() {
            return window.innerWidth <= 991;
        },
        hideOverlayMenu() {
            this.rotateMenuButton = false;
            this.overlayMenuActive = false;
            this.mobileMenuActive = false;
        },
        onMenuButtonClick(event) {
            this.menuClick = true;
            this.menuActive = !this.menuActive;
            this.topbarMenuActive = false;
            this.topbarRightClick = true;
            this.rotateMenuButton = !this.rotateMenuButton;
            this.topbarMenuActive = false;

            if (!this.isDesktop()) {
                this.mobileMenuActive = !this.mobileMenuActive;
                if (this.mobileMenuActive) {
                    this.blockBodyScroll();
                } else {
                    this.unblockBodyScroll();
                }
            }

            event.preventDefault();
        },
        onTopbarMenuButtonClick(event) {
            this.topbarItemClick = true;
            this.topbarMenuActive = !this.topbarMenuActive;
            this.hideOverlayMenu();
            event.preventDefault();
        },
        onTopbarItemClick(event) {
            this.topbarItemClick = true;

            if (this.activeTopbarItem === event.item) this.activeTopbarItem = null;
            else this.activeTopbarItem = event.item;

            event.originalEvent.preventDefault();
        },
        onTopbarMobileButtonClick(event) {
            this.mobileTopbarActive = !this.mobileTopbarActive;
            event.preventDefault();
        },
        onRightPanelButtonClick(event) {
            this.rightPanelClick = true;
            this.rightPanelActive = !this.rightPanelActive;

            event.preventDefault();
        },
        onRightPanelClick() {
            this.rightPanelClick = true;
        },
        onHideClick(expanded) {
            this.rightPanelActive = expanded;
        },
        onEditMode(expanded) {
            this.rightPanelActive = expanded;
        },
        onAddWidget(widget) {
            console.log(widget);
        },
        onMenuClick() {
            this.menuClick = true;
        },
        onRootMenuItemClick(event) {
            if (event.isSameIndex) {
                this.isSlimOrHorItemClick = false;
            } else {
                this.isSlimOrHorItemClick = true;
            }
            this.menuActive = !this.menuActive;
        },
        onMenuItemClick(event) {
            if (!event.item.items) {
                this.isSlimOrHorItemClick = false;
                this.hideOverlayMenu();
                EventBus.emit('reset-active-index');
            }

            if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
                this.menuActive = false;
            }
        },
        onMenuModeChange(menuMode) {
            this.menuMode = menuMode;
            this.overlayMenuActive = false;

            if (menuMode === 'static') {
                this.menuActive = true;
            }

            if (menuMode === 'horizontal') {
                this.inlineMenuPosition = 'bottom';
            }
        },
        onLayoutModeChange(menuColor) {
            this.$emit('layout-mode-change', menuColor);

            const layoutLink = document.getElementById('layout-css');
            const layoutHref = 'layout/css/layout-' + menuColor + '.css';
            this.replaceLink(layoutLink, layoutHref);

            const themeLink = document.getElementById('theme-css');
            const urlTokens = themeLink.getAttribute('href').split('/');
            urlTokens[urlTokens.length - 1] = 'theme-' + menuColor + '.css';
            const newURL = urlTokens.join('/');

            this.replaceLink(themeLink, newURL, () => {
                this.$appState.isNewThemeLoaded = true;
            });
        },
        onInlineMenuPositionChange(position) {
            this.inlineMenuPosition = position;
        },
        onChangeInlineMenu(e, key) {
            if (key === 'top') {
                if (this.inlineMenuBottomActive) {
                    this.inlineMenuBottomActive = false;
                }
                this.inlineMenuTopActive = !this.inlineMenuTopActive;
            }
            if (key === 'bottom') {
                if (this.inlineMenuTopActive) {
                    this.inlineMenuTopActive = false;
                }
                this.inlineMenuBottomActive = !this.inlineMenuBottomActive;
            }

            this.inlineMenuClick = true;
        },
        changeTheme(theme) {
            this.theme = theme;
            this.changeStyleSheetUrl('theme-css', theme);
        },
        onTopbarThemeChange(theme) {
            this.$emit('topbar-theme', theme);
        },
        onMenuTheme(menuTheme) {
            this.$emit('menu-theme', menuTheme);
        },
        changeStyleSheetUrl(id, value) {
            const element = document.getElementById(id);
            const urlTokens = element.getAttribute('href').split('/');
            urlTokens[urlTokens.length - 2] = value;
            const newURL = urlTokens.join('/');
            this.replaceLink(element, newURL);
        },
        replaceLink(linkElement, href, callback) {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);

                if (callback) {
                    callback();
                }
            });
        },
        blockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.add('blocked-scroll');
            } else {
                document.body.className += ' blocked-scroll';
            }
        },
        unblockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.remove('blocked-scroll');
            } else {
                document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        },
        getMenuList() {
            let index = findIndexByLabel(this.menu, 'Bedienung');
            this.menu[index].items = this.viewMenu;
        },
        alertUser() {
            this.$toast.add({severity:'info', summary: 'Standby-Modus', detail: 'In 10 Sekunden geht die App schlafen', life: 3000});
        },
        standby() {
            console.log('STANDBY MODE');
            this.$toast.add({severity:'warn', summary: 'Standby-Modus', detail: 'STANDBY-Modus aktiv', life: 3000});
            // store.dispatch('setStandby', true);
        },
        resetInactivityTimer() {
            let timeoutMessage;
            // timeout = store.getters.getClientData.app.config.standbyTimeout;
            const timeout = this.appTimeout;
            if (timeout >= 10) {
                timeoutMessage = timeout - 10;
                clearTimeout(this.time);
                this.time = setTimeout(this.standby, 1000 * timeout); // 10 seconds
                clearTimeout(this.preTime);
                this.preTime = setTimeout(this.alertUser, 1000 * timeoutMessage); // 10 seconds

                if (store.getters.getStandby) {
                    this.$toast.add({severity:'info', summary: 'Standby-Modus', detail: 'App wurde aufgeweckt', life: 3000});
                    store.dispatch('setStandby', false);
                    // SocketioService.sockEventConnection();
                    // SocketioService.sockEventExtended();
                    // SocketioService.sockEventValues();
                    // SocketioService.sockRequestEssential();

                    // SocketioService.setupSocketConnection();
                }
            }
        },
        loadSystemComponents() {
            console.log(`[APP] starting initial connection phase...`);
            // this.loadOpcuaServers();
            // EventBus.emit('startLoading');
            // this.setAppLoading(true);
            // this.loading = true;

            // this.$store.dispatch('types/loadCategoryTypes');
            // this.$store.dispatch('types/loadDepartmentTypes');

            // this.loaderTitle = 'Module';
            // this.setGlobalLoader({ title: 'Module' });
            // this.loadModules(true).then(() => {
            //     EventBus.emit('doneLoading');
            //     this.setAppLoading(false);
            //     this.loading = false;
            //     console.log(`[APP] initial connection phase done!`);
            // });
        },
    },
    computed: {
        ...mapGetters({
            getAppLoading: 'getAppLoading',
            isLoggedIn: 'auth/isLoggedIn',
            loginStatus: 'auth/loginStatus',
            getUser: 'auth/getUser',
            viewMenu: 'viewMenu',
            getAlarmCount: 'opcua/getAlarmCount',
            getGlobalLoader: 'getGlobalLoader',
            getAppSetup: 'socket/getAppSetup'
        }),
        appTimeout() {
            if (this.getAppSetup && this.getAppSetup.standbyTimeout) {
                if (typeof(this.getAppSetup.standbyTimeout) === 'number') {
                    return this.getAppSetup.standbyTimeout;
                }
                return 900;
            }
            return 900;
        },
        loaderTitle() {
            if (this.getGlobalLoader.title) return this.getGlobalLoader.title;
            else return '';
        },
        layoutContainerClass() {
            return [
                'layout-wrapper',
                'layout-menu-' + this.menuTheme + ' layout-topbar-' + this.topbarTheme,
                {
                    'layout-menu-static': this.menuMode === 'static',
                    'layout-menu-overlay': this.menuMode === 'overlay',
                    'layout-menu-overlay-active': this.overlayMenuActive,
                    'layout-menu-slim': this.menuMode === 'slim',
                    'layout-menu-horizontal': this.menuMode === 'horizontal',
                    'layout-menu-active': this.menuActive,
                    'layout-menu-mobile-active': this.mobileMenuActive,
                    'layout-topbar-mobile-active': this.mobileTopbarActive,
                    'layout-rightmenu-active': this.rightPanelActive,
                    'layout-rtl': this.$appState.RTL,
                    'p-input-filled': this.$primevue.config.inputStyle === 'filled',
                    'p-ripple-disabled': this.$primevue.config.ripple === false,
                },
            ];
        },
        isUserAdmin() {
            // return store.getters.getUser && store.getters.getUser.role === Role.Admin;
            return this.getUser && this.getUser.role === Role.Admin;
        },
        isUserMaster() {
            // return store.getters.getUser && (store.getters.getUser.role === Role.Admin || store.getters.getUser.role === Role.Master);
            return this.getUser && (this.getUser.role === Role.Admin || this.getUser.role === Role.Master);
        },
        interfaceMenu() {
            console.log(">>> GETTERS:", store.getters.getMenuInterface);
            return store.getters.getMenuInterface;
        },
        currentTimeFormatted: function() {
            if (this.dateTime.timestamp) {
                return formatDateGui(this.dateTime.timestamp);
            } else {
                return store.getters.getNoval;
            }
        },
        customerName: function () {
            if (this.installation.client.name !== null) {
                return this.installation.client.name;
            } else {
                return store.getters.getNoval;
            }
        },
        customerDesc: function () {
            if (this.installation.client.desc !== null) {
                return this.installation.client.desc;
            } else {
                return store.getters.getNoval;
            }
        },
        systemLabel: function () {
            if (this.installation.system.label !== null) {
                return this.installation.system.label;
            } else {
                return store.getters.getNoval;
            }
        },
    },
    components: {
        AppTopBar: AppTopBar,
        AppInlineMenu: AppInlineMenu,
        AppRightPanel: AppRightPanel,
        AppMenu: AppMenu,
        // AppConfig: AppConfig,
        AppFooter: AppFooter,
        AppBreadcrumb: AppBreadcrumb,
    },
    created() {
        console.log(`[APP] creating...`);
        EventBus.emit('startLoading');
        this.setAppLoading(true);
        // this.loading = true;
        document.addEventListener.call(window, 'load', event => {
            this.resetInactivityTimer(event);
        });
        document.addEventListener.call(window, 'click', event => {
            this.resetInactivityTimer(event);
            // helpers.playAudio('alarm', false);
        });
        document.addEventListener.call(window, 'keydown', event => {
            this.resetInactivityTimer(event);
        });
        document.addEventListener.call(window, 'mousemove', event => {
            this.resetInactivityTimer(event);
        });
        document.addEventListener.call(window, 'mousedown', event => {
            this.resetInactivityTimer(event);
        });
        document.addEventListener.call(window, 'touchstart', event => {
            this.resetInactivityTimer(event);
        });
        document.addEventListener.call(window, 'scroll', event => {
            this.resetInactivityTimer(event);
        });
        document.addEventListener.call(window, 'wheel', event => {
            this.resetInactivityTimer(event);
        });
        // this.inactivityTimer();
        if (this.isLoggedIn) {
            // SocketioService.setupSocketConnection(window.location.hostname, window.location.port, 'http:');
            // store.dispatch("socketIoSetup", null);
            // store.dispatch("socketIoEventConnection", null);
            // store.dispatch("socketIoConnectionTimer", null);
            // store.dispatch("socketIoRegisterConnectionEvent", null);
            // store.dispatch("socketIoLoadClientData", null);
            // store.dispatch("socketIoLoadConfigIo", null);
            // store.dispatch("socketIoLoadParameterObjects", null);
            // store.dispatch("socketIoLoadStatusIo", null);
            // // store.dispatch("socketIoLoadStatusTree", null);
            // store.dispatch("socketIoLoadOpcTree", null);
            // store.dispatch("socketIoLoadMenu", null);
            // // store.dispatch("socketIoLoadPage", null);
            // // store.dispatch("socketIoLoadUser", null);
            // // store.dispatch("socketIoLoadUserGroup", null);
            // // store.dispatch("socketIoLoadSystemLog", null);
            // // store.dispatch("socketIoLoadAlarmLog", null);
            // // store.dispatch("socketIoLoadChangeLog", null);
            // // store.dispatch("socketIoLoadActiveConnections", null);
            // store.dispatch("socketIoLoadMap");
            // // store.dispatch("socketIoValueEvent", null);
            // store.dispatch("socketIoUuidEvent", null);
            // //store.dispatch("generateItems", null);

            // store.dispatch("startSocketIo", null);
        }
    },
    mounted: function () {
        mainService.setInterval(false);
        console.log(`[APP] mounting...`);

        // EventBus.on('socketIoConnected', () => {
        //   this.loadSystemComponents();
        // });

        // this.timer = setInterval(() => {
        //     this.$store.dispatch("socketIoLoadMap");
        // }, 600*1000);

        // this.$store.dispatch('types/loadCategoryTypes');
        // this.$store.dispatch('types/loadDepartmentTypes');

        // this.loaderTitle = 'Module';
        // this.loadModules().then(() => {
        //     EventBus.emit('doneLoading');
        //     this.setAppLoading(false);
        //     this.loading = false;
        // });


        // this.loading = false;

        // this.$store.dispatch('loadStatusIo');
        // if (this.isLoggedIn) {
        //     this.getMenuList();
        // }
        // this.intval_Alarm = setInterval(this.getAlarmLog, 1000);
        // this.intval_SysStat = setInterval(this.getSystemStatus, 1000);
        // this.intval_Param = setInterval(this.getParameterList, 1000);
        // this.intval_Param2 = setInterval(this.getParameterList2, 1000);
        // this.intval_heartBeat = setInterval(this.getHeartBeat, 1000);

        // this.addEventListener('valueEvent', (vars) => {
        //     this.$store.dispatch('setValues', vars);
        // });
        // this.addEventListener('tmrValueEvent', (vars) => {
        //     vars.forEach((element) => {
        //         this.$store.dispatch('setValues', element);
        //     });
        // });
        // console.log(this.$store.getters.getValues);
    },
    beforeUnmount() {
        // this.removeEventListener('valueEvent', (vars) => {
        //     console.log('Event-Listener removed: ', vars);
        // });
        // this.removeEventListener('tmrValueEvent', (vars) => {
        //     console.log('Event-Listener removed: ', vars);
        // });

        // clearInterval(this.intval_Alarm);
        // clearInterval(this.intval_SysStat);
        // clearInterval(this.intval_Param);
        // clearInterval(this.intval_Param2);
        // clearInterval(this.intval_heartBeat);

        // console.log('cleanup store...');
        // this.$store.dispatch('logout');
        clearInterval(this.timer);
        store.dispatch("clearStates");
        console.log(`[APP] unregistered event`);
    },
    unmounted: function() {
        document.removeEventListener('load', this.resetInactivityTimer());
        document.removeEventListener('click', this.resetInactivityTimer());
        document.removeEventListener('keydown', this.resetInactivityTimer());
        document.removeEventListener('mousemove', this.resetInactivityTimer());
        document.removeEventListener('mousedown', this.resetInactivityTimer());
        document.removeEventListener('touchstart', this.resetInactivityTimer());
        document.removeEventListener('scroll', this.resetInactivityTimer());
        document.removeEventListener('wheel', this.resetInactivityTimer());
    }
};
</script>

<style lang="scss">
@import './App.scss';
</style>
