<template>
    <div class="card box widget-social pt-2 pb-2" :class="componentStyle">
        <widgetTitleBar :moduleData="this.moduleData" :widgetInfo="content" :alarmList="this.alarmList" :menu-items="this.items" @menu-click="($value) => this.toggle($value)"></widgetTitleBar>
        <OverlayPanel
            class="param-overlay"
            ref="alarmOverlay"
            appendTo="body"
            :autoZIndex="true"
            @show="focusElement" 
            :dismissable="true"
            :showCloseIcon="true"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ height: 'auto' }"
        >
            <div v-for="(element) in this.alarmList" :key="element.label">
                <span>🔴 {{ element.label }}</span>
            </div>
        </OverlayPanel>
        <div v-if="isLoading">
            <loader-skeleton :widgetInfo="content"></loader-skeleton>
        </div>
        <OverlayPanel class="param-overlay" ref="weather" appendTo="body" :autoZIndex="true" @show="focusElement" 
            :dismissable="false" :showCloseIcon="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ height: '300px' }">
        </OverlayPanel>
        <Dialog 
            v-model:visible="showDiagCfg"
            @show="focusElement"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '700px' }"
            class="p-fluid"
            >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i>
                    <span class="subtext mt-1">
                        {{ moduleDepartment.key }} - {{ moduleDepartment.label }} ⚡ Einstellungen
                    </span>
                </h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="hideOP" @keydown.enter="saveDiag('cfg')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView v-else :scrollable="true">
                    <TabPanel header="Wind">
                        <Panel header="Schutzparameter">
                            <template #icons>
                                <i class="fi fi-rr-wind-warning bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-12">
                                    <div class="formgrid grid p-1">
                                        <InlineMessage severity="info" class="mb-3 ml-3 mr-3 fg-yellow bg-black" :style="{ border: 'solid #fff44d', borderWidth: '0 0 0 6px' }">
                                            Der manuelle Sturmschutz aktiviert alle Sturmbegrenzungen ohne Verzögerung.
                                        </InlineMessage>
                                        <div class="field-checkbox col justify-content-center">
                                            <InputSwitch v-model="this.getDiagDataCfg('manualStormEnable').value" /><span class="ml-2">{{ this.getDiagDataCfg('manualStormEnable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="stormValue" ref="stormValue" :min="0" :max="50"
                                            v-model="this.getDiagDataCfg('stormValue').value"
                                            :suffix="' ' + this.getDiagDataCfg('stormValue').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" />
                                        <label for="stormValue" class="mr-2">{{ this.getDiagDataCfg('stormValue').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label mr-2">
                                                <InputMask id="stormDelayPost" ref="stormDelayPost" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataCfg('stormDelayPost').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="stormDelayPost" class="mr-2">{{ this.getDiagDataCfg('stormDelayPost').label }}</label>
                                            </span>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Windgeschwindigkeit - Filter" class="mt-3" v-if="isMaster">
                            <template #icons>
                                <i class="fi fi-rr-wind bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="windFilterTime" ref="windFilterTime" :min="0" :max="100000"
                                            v-model="this.getDiagDataCfg('windFilterTime').value"
                                            :suffix="' ' + this.getDiagDataCfg('windFilterTime').unit"
                                            :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" />
                                        <label for="windFilterTime" class="mr-2">{{ this.getDiagDataCfg('windFilterTime').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="windFilterValue" ref="windFilterValue" :min="0.1" :max="100"
                                            v-model="this.getDiagDataCfg('windFilterValue').value"
                                            :suffix="' ' + this.getDiagDataCfg('windFilterValue').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" />
                                        <label for="windFilterValue" class="mr-2">{{ this.getDiagDataCfg('windFilterValue').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="windSpeedFilterUp" ref="windSpeedFilterUp" :min="0" :max="100"
                                            v-model="this.getDiagDataCfg('windSpeedFilterUp').value"
                                            :suffix="' ' + this.getDiagDataCfg('windSpeedFilterUp').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" />
                                        <label for="windSpeedFilterUp" class="mr-2">{{ this.getDiagDataCfg('windSpeedFilterUp').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="windSpeedFilterDown" ref="windSpeedFilterDown" :min="0" :max="100"
                                            v-model="this.getDiagDataCfg('windSpeedFilterDown').value"
                                            :suffix="' ' + this.getDiagDataCfg('windSpeedFilterDown').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" />
                                        <label for="windSpeedFilterDown" class="mr-2">{{ this.getDiagDataCfg('windSpeedFilterDown').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Windrichtung - Filter" class="mt-3" v-if="isMaster">
                            <template #icons>
                                <i class="fi fi-rr-wind bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="windDirectionFilterTime" ref="windDirectionFilterTime" :min="0" :max="100000"
                                            v-model="this.getDiagDataCfg('windDirectionFilterTime').value"
                                            :suffix="' ' + this.getDiagDataCfg('windDirectionFilterTime').unit"
                                            :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" />
                                        <label for="windDirectionFilterTime" class="mr-2">{{ this.getDiagDataCfg('windDirectionFilterTime').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="windDirectionFilterValue" ref="windDirectionFilterValue" :min="0.1" :max="100"
                                            v-model="this.getDiagDataCfg('windDirectionFilterValue').value"
                                            :suffix="' ' + this.getDiagDataCfg('windDirectionFilterValue').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" />
                                        <label for="windDirectionFilterValue" class="mr-2">{{ this.getDiagDataCfg('windDirectionFilterValue').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="windDirectionFilterUp" ref="windDirectionFilterUp" :min="0" :max="100"
                                            v-model="this.getDiagDataCfg('windDirectionFilterUp').value"
                                            :suffix="' ' + this.getDiagDataCfg('windDirectionFilterUp').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" />
                                        <label for="windDirectionFilterUp" class="mr-2">{{ this.getDiagDataCfg('windDirectionFilterUp').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="windDirectionFilterDown" ref="windDirectionFilterDown" :min="0" :max="100"
                                            v-model="this.getDiagDataCfg('windDirectionFilterDown').value"
                                            :suffix="' ' + this.getDiagDataCfg('windDirectionFilterDown').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" />
                                        <label for="windDirectionFilterDown" class="mr-2">{{ this.getDiagDataCfg('windDirectionFilterDown').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Windrichtung" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-windsock bg-bespin fg-lightYellow p-panel-header-icon"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="windDirectionPrimary" ref="windDirectionPrimary" :min="0" :max="360"
                                            v-model="this.getDiagDataCfg('windDirectionPrimary').value"
                                            :suffix="' ' + this.getDiagDataCfg('windDirectionPrimary').unit + ' (' + this.windDirectionHelper + ')'"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="windDirectionPrimary" class="mr-2">{{ this.getDiagDataCfg('windDirectionPrimary').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="windDirectionSwitching" ref="windDirectionSwitching" :min="0" :max="100"
                                            v-model="this.getDiagDataCfg('windDirectionSwitching').value"
                                            :suffix="' ' + this.getDiagDataCfg('windDirectionSwitching').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="windDirectionSwitching" class="mr-2">{{ this.getDiagDataCfg('windDirectionSwitching').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label mr-2">
                                                <InputMask id="windDirectionDelay" ref="windDirectionDelay" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataCfg('windDirectionDelay').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="windDirectionDelay" class="mr-2">{{ this.getDiagDataCfg('windDirectionDelay').label }}</label>
                                            </span>
                                        </div>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label mr-2">
                                                <InputMask id="windDirectionDelayOff" ref="windDirectionDelayOff" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataCfg('windDirectionDelayOff').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="windDirectionDelayOff" class="mr-2">{{ this.getDiagDataCfg('windDirectionDelayOff').label }}</label>
                                            </span>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Alarmeinstellungen" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-alarm-clock bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmStormValue" ref="alarmStormValue" :min="-1" :max="100"
                                            v-model="getDiagDataCfg('alarmStormValue').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('alarmStormValue').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmStormValue" class="mr-2">{{ getDiagDataCfg('alarmStormValue').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataCfg('alarmStormPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label mr-2">
                                                <InputMask id="alarmStormDelay" ref="alarmStormDelay" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataCfg('alarmStormDelay').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="alarmStormDelay" class="mr-2">{{ this.getDiagDataCfg('alarmStormDelay').label }}</label>
                                            </span>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Regen">
                        <Panel header="Schutzparameter">
                            <template #icons>
                                <i class="fi fi-rr-raindrops bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-12">
                                    <div class="formgrid grid p-1">
                                        <InlineMessage severity="info" class="mb-3 ml-3 mr-3 fg-yellow bg-black" :style="{ border: 'solid #fff44d', borderWidth: '0 0 0 6px' }">
                                            Der manuelle Regenschutz aktiviert alle Regenbegrenzungen ohne Verzögerung.
                                        </InlineMessage>
                                        <div class="field-checkbox col justify-content-center">
                                            <InputSwitch v-model="this.getDiagDataCfg('manualRainEnable').value" /><span class="ml-2">{{ this.getDiagDataCfg('manualRainEnable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Verzögerungen" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-clock bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label mr-2">
                                                <InputMask id="rainDelayPost" ref="rainDelayPost" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataCfg('rainDelayPost').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="rainDelayPost" class="mr-2">{{ this.getDiagDataCfg('rainDelayPost').label }}</label>
                                            </span>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Regenbefehl Erkennung" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-raindrops bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <InlineMessage severity="info" class="mb-3 ml-3 mr-3 fg-yellow bg-black" :style="{ border: 'solid #fff44d', borderWidth: '0 0 0 6px' }">
                                    Wird diese Funktion aktiviert, kontrolliert die Steuerung zusätzlich bei Regen auch die Niederschlagsart. Erst wenn sie mindestens die eingestellte Art erreicht oder überschreitet, gilt der Niederschlag als tatsächlicher Niederschlag.
                                </InlineMessage>
                                <div class="field col-6">
                                    <div class="flex flex-column">
                                        <div class="flex align-items-center mt-2 mb-2">
                                            <Dropdown v-model="this.getDiagDataCfg('rainEnableCustom').value" ref="rainEnableCustom" :options="getSynoptoCode" optionLabel="label" optionValue="value" placeholder="Niederschlagsart wählen" class="w-full"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label mr-2">
                                                <InputMask id="rainDelayType" ref="rainDelayType" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataCfg('rainDelayType').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="rainDelayType" class="mr-2">{{ this.getDiagDataCfg('rainDelayType').label }}</label>
                                            </span>
                                        </div>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label mr-2">
                                                <InputMask id="rainDelayTypeDn" ref="rainDelayTypeDn" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataCfg('rainDelayTypeDn').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="rainDelayTypeDn" class="mr-2">{{ this.getDiagDataCfg('rainDelayTypeDn').label }}</label>
                                            </span>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Alarmeinstellungen" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-alarm-clock bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmRainValue" ref="alarmRainValue" :min="-1" :max="100"
                                            v-model="getDiagDataCfg('alarmRainValue').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('alarmRainValue').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmRainValue" class="mr-2">{{ getDiagDataCfg('alarmRainValue').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataCfg('alarmRainPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label mr-2">
                                                <InputMask id="alarmRainDelay" ref="alarmRainDelay" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataCfg('alarmRainDelay').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="alarmRainDelay" class="mr-2">{{ this.getDiagDataCfg('alarmRainDelay').label }}</label>
                                            </span>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Filtereinstellungen" class="mt-3" v-if="isMaster">
                            <template #icons>
                                <i class="fi fi-rr-settings bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="rainIntensityFilterUp" ref="rainIntensityFilterUp" :min="0" :max="100000"
                                                v-model="this.getDiagDataCfg('rainIntensityFilterUp').value" mode="decimal"
                                                :suffix="` ${this.getDiagDataCfg('rainIntensityFilterUp').unit}`"
                                                :minFractionDigits="3" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="rainIntensityFilterUp" class="mr-2">{{ this.getDiagDataCfg('rainIntensityFilterUp').label }}</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="rainIntensityFilterDn" ref="rainIntensityFilterDn" :min="0" :max="100000"
                                                v-model="this.getDiagDataCfg('rainIntensityFilterDn').value" mode="decimal"
                                                :suffix="` ${this.getDiagDataCfg('rainIntensityFilterDn').unit}`"
                                                :minFractionDigits="3" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="rainIntensityFilterDn" class="mr-2">{{ this.getDiagDataCfg('rainIntensityFilterDn').label }}</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Temperatur/Frost">
                        <Panel header="Schutzparameter">
                            <template #icons>
                                <i class="fi fi-rr-temperature-frigid bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col-12">
                                    <div class="formgrid grid p-1">
                                        <InlineMessage severity="info" class="mb-3 ml-3 mr-3 fg-yellow bg-black" :style="{ border: 'solid #fff44d', borderWidth: '0 0 0 6px' }">
                                            Der manuelle Frostschutz aktiviert alle Frostbegrenzungen ohne Verzögerung.
                                        </InlineMessage>
                                        <div class="field-checkbox col justify-content-center">
                                            <InputSwitch v-model="this.getDiagDataCfg('manualFrostEnable').value" /><span class="ml-2">{{ this.getDiagDataCfg('manualFrostEnable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="frostValueDay" ref="frostValueDay" :min="-50" :max="100"
                                            v-model="this.getDiagDataCfg('frostValueDay').value"
                                            :suffix="' ' + this.getDiagDataCfg('frostValueDay').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="frostValueDay" class="mr-2">{{ this.getDiagDataCfg('frostValueDay').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <InputNumber id="frostValueNight" ref="frostValueNight" :min="-50" :max="100"
                                            v-model="this.getDiagDataCfg('frostValueNight').value"
                                            :suffix="' ' + this.getDiagDataCfg('frostValueNight').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="frostValueNight" class="mr-2">{{ this.getDiagDataCfg('frostValueNight').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Alarmeinstellungen" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-alarm-clock bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmTempMin" ref="alarmTempMin" :min="-50" :max="100"
                                            v-model="getDiagDataCfg('alarmTempMin').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('alarmTempMin').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmTempMin" class="mr-2">{{ getDiagDataCfg('alarmTempMin').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataCfg('alarmTempMinPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmTempMax" ref="alarmTempMax" :min="-50" :max="100"
                                            v-model="getDiagDataCfg('alarmTempMax').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('alarmTempMax').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmTempMax" class="mr-2">{{ getDiagDataCfg('alarmTempMax').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataCfg('alarmTempMaxPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col-6">
                                    <span class="p-float-label">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label mr-2">
                                                <InputMask id="alarmTempDelay" ref="alarmTempDelay" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataCfg('alarmTempDelay').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="alarmTempDelay" class="mr-2">{{ this.getDiagDataCfg('alarmTempDelay').label }}</label>
                                            </span>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Filtereinstellungen" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-settings bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label">
                                            <InputNumber id="tempFilterTime" ref="tempFilterTime" :min="0" :max="100000"
                                                v-model="this.getDiagDataCfg('tempFilterTime').value" mode="decimal"
                                                :suffix="` ${this.getDiagDataCfg('tempFilterTime').unit}`"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="tempFilterTime" class="mr-2">{{ this.getDiagDataCfg('tempFilterTime').label }}</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label">
                                            <InputNumber id="tempFilterValue" ref="tempFilterValue" :min="0" :max="100000"
                                                v-model="this.getDiagDataCfg('tempFilterValue').value" mode="decimal"
                                                :suffix="` ${this.getDiagDataCfg('tempFilterValue').unit}`"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="tempFilterValue" class="mr-2">{{ this.getDiagDataCfg('tempFilterValue').label }}</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Strahlung" v-if="isMaster">
                        <Panel header="Strahlung Filter" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-wind bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <span class="p-float-label">
                                        <InputNumber id="radiationFilterTime" ref="radiationFilterTime" :min="0" :max="1000000"
                                            v-model="this.getDiagDataCfg('radiationFilterTime').value"
                                            :suffix="' ' + this.getDiagDataCfg('radiationFilterTime').unit"
                                            :minFractionDigits="0" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" />
                                        <label for="radiationFilterTime" class="mr-2">{{ this.getDiagDataCfg('radiationFilterTime').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label">
                                        <InputNumber id="radiationFilterValue" ref="radiationFilterValue" :min="0.1" :max="100"
                                            v-model="this.getDiagDataCfg('radiationFilterValue').value"
                                            :suffix="' ' + this.getDiagDataCfg('radiationFilterValue').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" />
                                        <label for="radiationFilterValue" class="mr-2">{{ this.getDiagDataCfg('radiationFilterValue').label }}</label>
                                    </span>
                                </div>                                
                            </div>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <span class="p-float-label">
                                        <InputNumber id="radiationFilterUp" ref="radiationFilterUp" :min="0" :max="10000"
                                            v-model="this.getDiagDataCfg('radiationFilterUp').value"
                                            :suffix="' ' + this.getDiagDataCfg('radiationFilterUp').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" />
                                        <label for="radiationFilterUp" class="mr-2">{{ this.getDiagDataCfg('radiationFilterUp').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label">
                                        <InputNumber id="radiationFilterDn" ref="radiationFilterDn" :min="0" :max="10000"
                                            v-model="this.getDiagDataCfg('radiationFilterDn').value"
                                            :suffix="' ' + this.getDiagDataCfg('radiationFilterDn').unit"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            @focus="focusElement" class="p-inputtext-lg" />
                                        <label for="radiationFilterDn" class="mr-2">{{ this.getDiagDataCfg('radiationFilterDn').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Pyranosensor" v-if="isMaster">
                        <Panel header="Pyranosensor (Globalstrahlung, nur positiv)">
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center">
                                            <InputSwitch v-model="this.getDiagDataCfg('pyranoSensEnable').value" /><span class="ml-2">{{ this.getDiagDataCfg('pyranoSensEnable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel> 
                        <Panel header="Sensorskalierung" class="mt-2" v-if="this.getDiagDataCfg('pyranoSensEnable').value">
                            <template #icons>
                                <i class="fi fi-rr-unlock bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="pyranoSensInMin" ref="pyranoSensInMin" :min="0" :max="32767"
                                                v-model="getDiagDataCfg('pyranoSensInMin').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="pyranoSensInMin" class="mr-2">Sensor IN Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="pyranoSensOutMin" ref="pyranoSensOutMin" :min="-10000" :max="10000"
                                                v-model="getDiagDataCfg('pyranoSensOutMin').value"
                                                :suffix="` ${this.getDiagDataCfg('pyranoSensOutMin').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="pyranoSensOutMin" class="mr-2">Sensor OUT Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="pyranoSensInMax" ref="pyranoSensInMax" :min="0" :max="32767"
                                                v-model="getDiagDataCfg('pyranoSensInMax').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="pyranoSensInMax" class="mr-2">Sensor IN Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="pyranoSensOutMax" ref="pyranoSensOutMax" :min="-100000" :max="100000"
                                                v-model="getDiagDataCfg('pyranoSensOutMax').value"
                                                :suffix="` ${this.getDiagDataCfg('pyranoSensOutMax').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="pyranoSensOutMax" class="mr-2">Sensor OUT Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Sensor-Einstellungen" class="mt-2" v-if="this.getDiagDataCfg('pyrgeoSensEnable').value">
                            <template #icons>
                                <i class="fi fi-rr-settings bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="pyranoSensFilterValue" ref="pyranoSensFilterValue" :min="0" :max="1000"
                                            v-model="getDiagDataCfg('pyranoSensFilterValue').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('pyranoSensFilterValue').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="pyranoSensFilterValue" class="mr-2">{{ getDiagDataCfg('pyranoSensFilterValue').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="pyranoSensOffset" ref="pyranoSensOffset" :min="-10000" :max="10000"
                                            v-model="getDiagDataCfg('pyranoSensOffset').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('pyranoSensOffset').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="pyranoSensOffset" class="mr-2">{{ getDiagDataCfg('pyranoSensOffset').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Pyrgeosensor" v-if="isMaster">
                        <Panel header="Pyrgeosensor (Globalstrahlung, +/- 250W/m2)">
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center">
                                            <InputSwitch v-model="this.getDiagDataCfg('pyrgeoSensEnable').value" /><span class="ml-2">{{ this.getDiagDataCfg('pyrgeoSensEnable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel> 
                        <Panel header="Sensorskalierung" class="mt-2" v-if="this.getDiagDataCfg('pyrgeoSensEnable').value">
                            <template #icons>
                                <i class="fi fi-rr-unlock bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="pyrgeoSensInMin" ref="pyrgeoSensInMin" :min="0" :max="32767"
                                                v-model="getDiagDataCfg('pyrgeoSensInMin').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="pyrgeoSensInMin" class="mr-2">Sensor IN Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="pyrgeoSensOutMin" ref="pyrgeoSensOutMin" :min="-10000" :max="10000"
                                                v-model="getDiagDataCfg('pyrgeoSensOutMin').value"
                                                :suffix="` ${this.getDiagDataCfg('pyrgeoSensOutMin').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="pyrgeoSensOutMin" class="mr-2">Sensor OUT Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="pyrgeoSensInMax" ref="pyrgeoSensInMax" :min="0" :max="32767"
                                                v-model="getDiagDataCfg('pyrgeoSensInMax').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="pyrgeoSensInMax" class="mr-2">Sensor IN Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="pyrgeoSensOutMax" ref="pyrgeoSensOutMax" :min="-100000" :max="100000"
                                                v-model="getDiagDataCfg('pyrgeoSensOutMax').value"
                                                :suffix="` ${this.getDiagDataCfg('pyrgeoSensOutMax').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="pyrgeoSensOutMax" class="mr-2">Sensor OUT Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Sensor-Einstellungen" class="mt-2" v-if="this.getDiagDataCfg('pyrgeoSensEnable').value">
                            <template #icons>
                                <i class="fi fi-rr-settings bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col-4">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="pyrgeoSensFilterValue" ref="pyrgeoSensFilterValue" :min="0" :max="1000"
                                            v-model="getDiagDataCfg('pyrgeoSensFilterValue').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('pyrgeoSensFilterValue').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="pyrgeoSensFilterValue" class="mr-2">{{ getDiagDataCfg('pyrgeoSensFilterValue').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-4">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="pyrgeoSensOffset" ref="pyrgeoSensOffset" :min="-10000" :max="10000"
                                            v-model="getDiagDataCfg('pyrgeoSensOffset').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('pyrgeoSensOffset').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="pyrgeoSensOffset" class="mr-2">{{ getDiagDataCfg('pyrgeoSensOffset').label }}</label>
                                    </span>
                                </div>
                                <div class="field col-4">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="pyrgeoSensitivity" ref="pyrgeoSensitivity" :min="-10000" :max="10000"
                                            v-model="getDiagDataCfg('pyrgeoSensitivity').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('pyrgeoSensitivity').unit}`"
                                            :minFractionDigits="2" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="pyrgeoSensitivity" class="mr-2">{{ getDiagDataCfg('pyrgeoSensitivity').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Service" v-if="isMaster">
                        <Panel header="Modbus Alarmierung bei Verbindungsstörung zur Wetterstation" class="mt-2">
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center">
                                            <InputSwitch v-model="this.getDiagDataCfg('alarmModbus').value" /><span class="ml-2">{{ this.getDiagDataCfg('alarmModbus').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Modbus Alarmeinstellungen" class="mt-2" v-if="this.getDiagDataCfg('alarmModbus').value === true">
                            <template #icons>
                                <i class="fi fi-rr-alarm-clock bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label mr-2">
                                                <InputMask id="alarmModbusDelay" ref="alarmModbusDelay" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataCfg('alarmModbusDelay').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="alarmModbusDelay" class="mr-2">{{ this.getDiagDataCfg('alarmModbusDelay').label }}</label>
                                            </span>
                                        </div>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataCfg('alarmModbusPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Kompass (magnetische) Kompensation" class="mt-2">
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center">
                                            <InputSwitch v-model="this.getDiagDataCfg('magneticCorrection').value" /><span class="ml-2">{{ this.getDiagDataCfg('magneticCorrection').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel> 
                        <Panel header="Kompass Alarmeinstellungen" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-alarm-clock bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="alarmCompassValue" ref="alarmCompassValue" :min="1" :max="360"
                                                v-model="getDiagDataCfg('alarmCompassValue').value"
                                                :suffix="` ${this.getDiagDataCfg('alarmCompassValue').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="alarmCompassValue" class="mr-2">{{ this.getDiagDataCfg('alarmCompassValue').label }}</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="alarmCompassDiff" ref="alarmCompassDiff" :min="0" :max="360"
                                                v-model="getDiagDataCfg('alarmCompassDiff').value"
                                                :suffix="` ${this.getDiagDataCfg('alarmCompassDiff').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="alarmCompassDiff" class="mr-2">{{ this.getDiagDataCfg('alarmCompassDiff').label }}</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label mr-2">
                                                <InputMask id="alarmCompassDelay" ref="alarmCompassDelay" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataCfg('alarmCompassDelay').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="alarmCompassDelay" class="mr-2">{{ this.getDiagDataCfg('alarmCompassDelay').label }}</label>
                                            </span>
                                        </div>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataCfg('alarmCompassPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Direkte Thies Parameter" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-alarm-clock bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label mr-2">
                                                <InputMask id="averagingTime" ref="averagingTime" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataCfg('averagingTime').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="averagingTime" class="mr-2">{{ this.getDiagDataCfg('averagingTime').label }}</label>
                                            </span>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="OUT_CompassDiff" ref="OUT_CompassDiff"
                                            v-model="getModuleValue('OUT_CompassDiff').value" mode="decimal"
                                            :suffix="` ${getModuleValue('OUT_CompassDiff').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" :disabled="true" />
                                        <label for="OUT_CompassDiff" class="mr-2">{{ getModuleValue('OUT_CompassDiff').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="OUT_CompassPitch" ref="OUT_CompassPitch"
                                            v-model="getModuleValue('OUT_CompassPitch').value" mode="decimal"
                                            :suffix="` ${getModuleValue('OUT_CompassPitch').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" :disabled="true" />
                                        <label for="OUT_CompassPitch" class="mr-2">{{ getModuleValue('OUT_CompassPitch').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="OUT_CompassRoll" ref="OUT_CompassRoll"
                                            v-model="getModuleValue('OUT_CompassRoll').value" mode="decimal"
                                            :suffix="` ${getModuleValue('OUT_CompassRoll').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" :disabled="true" />
                                        <label for="OUT_CompassRoll" class="mr-2">{{ getModuleValue('OUT_CompassRoll').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="OUT_CompassOffset" ref="OUT_CompassOffset"
                                            v-model="getModuleValue('OUT_CompassOffset').value" mode="decimal"
                                            :suffix="` ${getModuleValue('OUT_CompassOffset').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" :disabled="true" />
                                        <label for="OUT_CompassOffset" class="mr-2">{{ getModuleValue('OUT_CompassOffset').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="OUT_SupplyVoltage" ref="OUT_SupplyVoltage"
                                            v-model="getModuleValue('OUT_SupplyVoltage').value" mode="decimal"
                                            :suffix="` ${getModuleValue('OUT_SupplyVoltage').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" :disabled="true" />
                                        <label for="OUT_SupplyVoltage" class="mr-2">{{ getModuleValue('OUT_SupplyVoltage').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="OUT_WindStatus" ref="OUT_WindStatus"
                                            v-model="getModuleValue('OUT_WindStatus').value" mode="decimal"
                                            :suffix="` ${getModuleValue('OUT_WindStatus').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" :disabled="true" />
                                        <label for="OUT_WindStatus" class="mr-2">{{ getModuleValue('OUT_WindStatus').label }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="OUT_WindchillTemperature" ref="OUT_WindchillTemperature"
                                            v-model="getModuleValue('OUT_WindchillTemperature').value" mode="decimal"
                                            :suffix="` ${getModuleValue('OUT_WindchillTemperature').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" :disabled="true" />
                                        <label for="OUT_WindchillTemperature" class="mr-2">{{ getModuleValue('OUT_WindchillTemperature').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="OUT_RainTemperature" ref="OUT_RainTemperature"
                                            v-model="getModuleValue('OUT_RainTemperature').value" mode="decimal"
                                            :suffix="` ${getModuleValue('OUT_RainTemperature').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" :disabled="true" />
                                        <label for="OUT_RainTemperature" class="mr-2">{{ getModuleValue('OUT_RainTemperature').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="OUT_HeatIndex" ref="OUT_HeatIndex"
                                            v-model="getModuleValue('OUT_HeatIndex').value" mode="decimal"
                                            :suffix="` ${getModuleValue('OUT_HeatIndex').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" :disabled="true" />
                                        <label for="OUT_HeatIndex" class="mr-2">{{ getModuleValue('OUT_HeatIndex').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Alarmausgang" v-if="isMaster">
                        <widgetAlarmOutput :value="this.getDiagDataCfg('alarmAssignedOutput')" @setOutput="($value) => {this.getDiagDataCfg('alarmAssignedOutput').value = $value}"></widgetAlarmOutput>
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <widgetDialogFooter :widgetName="`${this.$options.name}_misc`" :department="moduleDepartment.key" :exchange="true" format="json"
                    @diagAbort="diagToggleCfg"
                    @diagSave="saveDiag('cfg')"
                    @import="($value) => {this.nodeDataCfg = $value}"
                    :exportObject="this.nodeDataCfg"
                ></widgetDialogFooter>
            </template>
        </Dialog>      
        <div v-if="!isLoading">
            <div class="flex justify-content-between align-items-center p-0 flex-wrap">
                <div class="flex justify-content-start align-items-center p-0">
                    <moduleIconEnat :icon="moduleData.metadata.icon" :alarmStatus="alarmStatus" :alarmQueue="alarmQueue" @toogleAlarmOverlay="toogleAlarmOverlay"></moduleIconEnat>
                    <div class="hidden sm:flex justify-content-between align-items-center ml-2">
                        <i :class="iconRain + 'fi fs-xlarge mr-2 cursor-pointer'" @click="showChart('rain')" v-tooltip="getModuleValue('OUT_Regen').label"></i>
                        <i :class="iconStorm + 'fi fs-xlarge mr-2 cursor-pointer'" @click="showChart('storm')" v-tooltip="getModuleValue('OUT_Sturm').label"></i>
                        <i :class="iconCold + 'fi fs-xlarge mr-2 cursor-pointer'" @click="showChart('frost')" v-tooltip="getModuleValue('OUT_Frost').label"></i>
                        <i :class="iconFog + 'fi fs-xlarge mr-2 cursor-pointer'" @click="showChart('fog')" v-tooltip="getModuleValue('OUT_Nebel').label"></i> 
                        <i :class="iconWindDir + 'fi fs-xlarge mr-2 cursor-pointer'" @click="showChart('winddir')" v-tooltip="getModuleValue('OUT_WindDirectionDirect').label"></i>
                    </div>
                </div>
                <div class="flex justify-content-end align-items-center p-0">
                    <widget-header :value="this.getModuleValue('OUT_AT')" :moduleData="this.moduleData" @showChart="showChart('value1')" :fgColor="fontColor" />
                </div>
            </div>

            <div class="stats flex mt-1">
                <div class="left">
                    <ul class="widget-list">
                        <Widget_rowValues2 :value1="getModuleValue('OUT_24hTemp_Min')" :value2="getModuleValue('OUT_24hTemp_Max')" title="24h Min/Max Temperaturen"></Widget_rowValues2>
                        <Widget_rowValues2 :value1="getModuleValue('OUT_WindSpeed')" :value2="getModuleValue('OUT_WindSpeedRaw')" title="Windgeschwindigkeit/Böhen"></Widget_rowValues2>
                        <!-- <Widget_rowValues2 :value1="getModuleValue('OUT_WindDirection')" :value2="getModuleValue('OUT_WindDirectionRaw')" title="Windrichtung/Böhen"></Widget_rowValues2> -->
                        <Widget_rowValues2 :value1="getModuleValue('OUT_WindDirection')" :value2="getModuleValue('OUT_WindDirectionTxt')" title="Windrichtung Steuerung"></Widget_rowValues2>
                        <Widget_rowValues2 :value1="getModuleValue('OUT_WindDirectionFilter')" :value2="getModuleValue('OUT_WindDirectionRawTxt')" title="Windrichtung Messung"></Widget_rowValues2>
                        <Widget_rowValues2 :value1="getModuleValue('OUT_RADIATION')" :value2="getModuleValue('OUT_LUX')" title="Globalstrahlung/Helligkeit"></Widget_rowValues2>
                        <Widget_rowValues2 :value1="getModuleValue('OUT_Globalstrahlung')" :value2="getModuleValue('OUT_GlobalstrahlungGestern')" title="Einstrahlung heute/gestern"></Widget_rowValues2>
                        <!-- <Widget_rowValues2 :value1="getModuleValue('OUT_Sonnenstunden')" :value2="getModuleValue('OUT_SonnenstundenGestern')" title="Sonnenstunden heute/gestern"></Widget_rowValues2> -->

                    </ul>
                </div>

                <div class="right flex flex-column">
                    <ul class="widget-list">
                        <Widget_rowValues2 :value1="getModuleValue('OUT_RH')" :value2="getModuleValue('OUT_RHABS')" title="RH rel/abs"></Widget_rowValues2>
                        <Widget_rowValues2 :value1="getModuleValue('OUT_RHABSKG')" :value2="getModuleValue('OUT_RHDEF')" title="Sättigung/Defizit"></Widget_rowValues2>
                        <Widget_rowValues2 :value1="getModuleValue('OUT_RainIntensity')" :value2="getModuleValue('OUT_RainAmount')" title="Regenmenge"></Widget_rowValues2>
                        <Widget_rowValues2 :value1="getModuleValue('OUT_AirpressureRel')" :value2="getModuleValue('OUT_AirpressureAbs')" title="Luftdruck rel/abs."></Widget_rowValues2>
                        <Widget_rowValues2 :value1="getModuleValue('OUT_Dewpoint')" :value2="getModuleValue('OUT_SunPosElevation')"></Widget_rowValues2>
                        <Widget_rowValues2 :value1="getModuleValue('OUT_Energie')" :value2="getModuleValue('OUT_EnergieGestern')" title="Energiemenge heute/gestern"></Widget_rowValues2>
                        <Widget_rowValues2 :value1="getModuleValue('OUT_ThermalEnergyNet')" :value2="getModuleValue('OUT_Pyrgeometer')" title="Wärmestrahlung"></Widget_rowValues2>
                    </ul>
                </div>
            </div>
            <textbox-enat title="Niederschlag" :value="this.getModuleValue('OUT_RainType').value" :moduleData="moduleData" icon="fi fi-rr-comment-alt" />
            <div class="flex justify-content-center align-items-center p-0 flex-wrap">
                <div class="flex-1 align-items-center justify-content-center mb-2 mt-2" v-if="isMaster">
                    <button-enat title="Allgemeine Einstellungen" subtitle="Alarme, Meldungen" icon="fi fi-rr-settings" fgColor="yellow" bgColor="darkSteel"
                       @click="toggleOP($event, 'cfg')" >
                    </button-enat>
                </div>
            </div>
        </div>
        <div v-if="entryDialog === true">
            <dialog-io-single v-bind:showDialog="entryDialog" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
                @entrySave="
                    ($value) => {
                        changeVal($value);
                    }
                " @entryAbort="hideDialog()"></dialog-io-single>
        </div>
        <dialog-chart-single :icon="getModuleValue('OUT_AT').icon" :node="getModuleValue('OUT_AT')" v-bind:show="showChart1" @chart-close="showChart1 = false"></dialog-chart-single>
        <dialog-chart-single :icon="getModuleValue('OUT_Regen').icon" :node="getModuleValue('OUT_Regen')" v-bind:show="showChartRain" @chart-close="showChartRain = false"></dialog-chart-single>
        <dialog-chart-single :icon="getModuleValue('OUT_Sturm').icon" :node="getModuleValue('OUT_Sturm')" v-bind:show="showChartStorm" @chart-close="showChartStorm = false"></dialog-chart-single>
        <dialog-chart-single :icon="getModuleValue('OUT_Frost').icon" :node="getModuleValue('OUT_Frost')" v-bind:show="showChartFrost" @chart-close="showChartFrost = false"></dialog-chart-single>
        <dialog-chart-single :icon="getModuleValue('OUT_Nebel').icon" :node="getModuleValue('OUT_Nebel')" v-bind:show="showChartFog" @chart-close="showChartFog = false"></dialog-chart-single>
        <dialog-chart-single :icon="getModuleValue('OUT_WindDirectionDirect').icon" :node="getModuleValue('OUT_WindDirectionDirect')" v-bind:show="showChartWindDir" @chart-close="showChartWindDir = false"></dialog-chart-single>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import store from '@/store';
import helpers from '@/helpers';
// import { isUUIDv4 } from "@/helpers";
import { parseNode, setOpcValueToast } from '@/helpers';
import SocketioService from '@/services/socketioService.js';
import Widget_rowValues2 from '@/components/fragments/widget_rowValues2.vue';
import { mapGetters, mapActions } from 'vuex';
import dialogChartSingle from '@/components/dialog/chartSingle.vue';
import widgetAlarmOutput from '@/components/fragments/widgetAlarmOutput.vue';
import widgetDialogFooter from '@/components/fragments/widgetDialogFooter.vue';
import moduleIconEnat from '@/components/fragments/moduleIconEnat.vue';
import widgetTitleBar from '@/components/fragments/widgetTitleBar.vue';

export default defineComponent({
    name: 'widgetWeatherStation',
    setup() { },
    components: {
        Widget_rowValues2,
        dialogChartSingle,
        widgetAlarmOutput,
        widgetDialogFooter,
        moduleIconEnat,
        widgetTitleBar,
    },
    props: {
        content: {
            type: null,
            required: true,
        },
        dragObj: {
            type: null,
            required: false,
        },
        valueObj: {
            type: null,
            required: false,
        },
        dataObj: {
            type: null,
            required: false,
        },
        styleObj: {
            type: null,
            required: false,
        },
        createdLayout: {
            type: Boolean,
            required: false,
        },
        readyLayout: {
            type: Boolean,
            required: false,
        },
    },
    watch: {
        createdLayout() {
            this.watchCreatedLayout();
        },
        readyLayout() {
            this.watchLayout();
        },
        '$store.getters.getPageData.editMode': function () {
            this.checkEditMode();
        },
    },  
    computed: {
        ...mapGetters({
            editMode: 'getEditMode',
            getNodeWidget: 'getNodeWidget',
            getOneDepartment: 'types/getOneDepartment',
            getOneCategory: 'types/getOneCategory',
            getStyleColors: 'getStyleColors',
            getStylePatterns: 'getStylePatterns',
            getModules: 'opcua/getModules',
            getModule: 'opcua/getModule',
            getNode: 'opcua/getNode',
            getModuleFetched: 'opcua/getModuleFetched',
            getSynoptoCode: 'types/getSynoptoCode',
            isMaster: 'auth/isMaster',
            getOpcuaServers: 'opcua/getServers',
        }),
        isLoading: function() {
            if (!this.moduleData || !this.getModuleFetched(this.moduleHash) || this.loading) return true;
            return false;
        },
        
        connectedModule: function () {
            if (this.content.data.module) {
                return this.content.data.module;
            }
            return null;
        },
        moduleData: function () {
            if (this.connectedModule) {
                const module = this.getModule(this.connectedModule);
                if (module) return module;
            }
            return null;
        },
        moduleHash: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.source.server !== null && this.moduleData.source.identifier !== null) {
                    // if (isUUIDv4(this.moduleData.source.server) && this.getOpcuaServers !== undefined) {
                    //     const srv = this.getOpcuaServers.find(obj => obj.uuid === this.moduleData.source.server);
                    //     if (srv.key) return `${srv.key}@${this.moduleData.source.identifier}`
                    // }
                    return `${this.moduleData.source.server}@${this.moduleData.source.identifier}`
                }
            }
            return null;
        },
        moduleDepartment: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.department !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.department);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        moduleCategory: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.category !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.category);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        componentName: function () {
            if (this.content.name !== undefined) {
                return this.content.name;
            } else {
                return '???';
            }
        },
        componentLabel: function () {
            if (this.content.label !== undefined) {
                return this.content.label;
            } else {
                return '???';
            }
        },
        componentContent: function () {
            if (this.content.content !== undefined) {
                return this.content.content;
            } else {
                return '???';
            }
        },
        componentStyle: function () {
            if (this.content.style && this.content.style !== 'standard') {
                let background = `bg-${this.content.style.background}`;
                if (this.alarmStatus > 0) background = 'alarm';
                return `box ptrn-${this.content.style.pattern} ${background} fg-${this.content.style.foreground}`;
            } else {
                return '';
            }
        },
        fontColor: function () {
            if (this.content.style && this.content.style.foreground) {
                return `fg-${this.content.style.foreground}`;
            }
            return 'gray';
        },
        icon: function () {
            if (this.alarmStatus === 0) {
                return this.moduleData.metadata.icon
            } else {
                return `${this.moduleData.metadata.icon} fg-red pulse-loop cursor-pointer bg-lightCrimson`;
            }
        },
        alarmEntriesCount: function () {
            if (this.getModuleValue(`AlarmEntriesCount`).value > 0) {
                return this.getModuleValue(`AlarmEntriesCount`).value;
            } else {
                return 0;
            }
        },
        alarmStatus: function () {
            let alarmCount = this.alarmEntriesCount;
            for (let i=0; i<this.alarmEntriesCount; i++) {
                // console.log(this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`));
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value <= 0) {
                    alarmCount -= 1;
                }
            }
            return alarmCount;
        },
        alarmQueue: function () {
            let alarmCount = this.alarmEntriesCount;
            for (let i=0; i<this.alarmEntriesCount; i++) {
                // console.log(this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`));
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value === 0) {
                    alarmCount -= 1;
                }
            }
            return alarmCount;
        },
        alarmList: function () {
            let activeAlarms = [];
            for (let i=0; i<this.alarmEntriesCount; i++) {
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value !== 0) {
                    const alarm = {
                        label: this.getModuleValue(`AlarmEntries[${i}].CFG.alarmLabel`).value,
                        state: this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value
                    }
                    activeAlarms.push(alarm);
                }
            }
            return activeAlarms;
        },
        alarmTicker: function () {
            let alarmStr = '';
            for (let i=0; i<this.alarmEntriesCount; i++) {
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value > 0) {
                    alarmStr += `🔴 ${this.getModuleValue(`AlarmEntries[${i}].CFG.alarmLabel`).value} `;
                }
            }
            return alarmStr;
        },
        valueListClass: function () {
            return "flex justify-content-center person-tag fg-lightGray bg-olive p-1 text-sm";
        },
        iconStorm: function () {
            if (this.getModuleValue('OUT_Sturm').value) return 'fi-rr-wind-warning fg-orange pulse-loop ';
            else return 'fi-rr-wind-warning fg-darkGray ';
        },
        iconRain: function () {
            if (this.getModuleValue('OUT_Regen').value) return 'fi-rr-cloud-showers fg-blue pulse-loop ';
            else return 'fi-rr-cloud-showers fg-darkGray ';
        },
        iconCold: function () {
            if (this.getModuleValue('OUT_Frost').value) return 'fi-rr-temperature-frigid fg-lightTeal pulse-loop ';
            else return 'fi-rr-temperature-frigid fg-darkGray ';
        },
        iconFog: function () {
            if (this.getModuleValue('OUT_Nebel').value) return 'fi-rr-fog fg-lightOlive pulse-loop ';
            else return 'fi-rr-fog fg-darkGray ';
        },
        iconWindDir: function () {
            if (this.getModuleValue('OUT_WindDirectionDirect').value) return 'fi-rr-windsock fg-lightMagenta pulse-loop ';
            else return 'fi-rr-windsock fg-darkGray ';
        },
        windDirectionHelper: function () {
            if (this.getDiagDataCfg('windDirectionPrimary').value) return helpers.windDirection(Number(this.getDiagDataCfg('windDirectionPrimary').value));
            else return '???';
        },
    },
    data() {
        return {
            loading: false,
            showChart1: false,
            showChartRain: false,
            showChartStorm: false,
            showChartFrost: false,
            showChartFog: false,
            showChartWindDir: false,
            entryDialog: false,
            valveDialog: false,
            ioDialogData: {},
            ioObj: {},
            timeSetGrp: null,
            currentGrp: {
                name: null,
                label: null,
                enable: null,
                timeSet: null,
                limits: {},
                controller: {},
            },
            node: null,
            saveWait: false,
            dialogLoading: false,
            showDiagCfg: false,
            nodeDataCfg: [],
            nodeDataCfgOld: [],
            submitted: false,
            selectedKey: {},
            expandedKeys: {},
            items: [],
            items1: [
                {
                    label: 'Einstellungen',
                    icon: 'fi fi-rr-settings',
                    command: () => {
                        // this.editEntry(this.content);
                        this.toggleOP(null, 'cfg');
                    },
                },
            ],
            items2: [
                {
                    separator: true,
                },
                {
                    label: 'Widget Config',
                    icon: 'pi pi-cog',
                    command: () => {
                        this.editEntry(this.content);
                    },
                },
                {
                    label: 'Duplizieren',
                    icon: 'pi pi-clone',
                    command: () => {
                        this.duplicateEntry();
                    },
                },
                {
                    label: 'Löschen',
                    icon: 'pi pi-times',
                    command: () => {
                        this.deleteEntry();
                    },
                },
            ],
        };
    },
    created() {
        this.loading = true;
    },
    mounted() {
        this.checkEditMode();
        if (!this.getModuleFetched(this.moduleHash)) {
            this.loadModule(this.connectedModule).then((module) => {
                const moduleHash = `${module.source.server}@${module.source.identifier}.*`;
                SocketioService.getRedisNodesParam(moduleHash, (err, response) => {
                    if (!err && response) {
                        if (Array.isArray(response) && response.length > 0) {
                            this.setNodesFull(response);
                        }
                    }
                }).then(() => { 
                    if (this.editMode) console.log(`[ENATWIDGET] ${this.moduleHash} : component ready!`);
                    this.setModuleFetched(this.moduleHash);
                    this.loading = false;
                });
            });
        } else {
            this.loading = false;
        }
    },
    methods: {
        ...mapActions({
            loadNode: 'opcua/loadNode',
            loadModule: 'opcua/loadModule',
            setNodesFull: 'opcua/setNodesFull',
            setModuleFetched: 'opcua/setModuleFetched',
        }),
        loadingState(state) {
            if (state) {
                this.loading = true;
            } else {
                this.loading = false;
            }
        },
        getModuleValue(schemeEntry) {
            const key = `${this.moduleHash}.${schemeEntry}`;
            return this.getNode(key);
        },
        getModuleValueStr(schemeEntry) {
            const key = `${this.moduleHash}.${schemeEntry}`;
            const node = this.getNode(key);
            if (typeof node.unit === 'string' && node.unit.length > 0) {
                return `${node.value}${node.unit}`;
            }
            return `${node.value}`;
        },
        watchCreatedLayout() {
            // this.loading = true;
        },
        watchLayout() {
        },
        checkEditMode() {
            if (this.$store.getters.getPageData.editMode) {
                this.items = [...this.items1, ...this.items2];
            } else {
                this.items = [...this.items1];
            }
        },
        resetCurrentGrp() {
            this.currentGrp = {
                name: null,
                label: null,
                enable: null,
                timeSet: null,
                limits: {},
                controller: {},
            }
        },
        clearVals() {
            this.nodeDataCfg = [];
            this.nodeDataCfg = [];
            this.saveWait = false;
            this.dialogLoading = false;
        },
        getParameter(lvl1, lvl2, index) {
            let parameter = null;
            if (!lvl1) return false;
            if (!lvl2) lvl2 = '';
            if (!isNaN(index)) {
                parameter = this.node.get(lvl1 + '[' + index + '].' + lvl2);
            } else {
                parameter = this.node.get(lvl1 + lvl2);
            }
            return parameter;
        },
        getDiagDataCfg(displayName) {
            if (this.nodeDataCfg.length > 0 && displayName) {
                const result = this.nodeDataCfg.find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        returnChild(childStr) {
            return helpers.returnChild(this.nodeEntry, childStr);
        },
        returnParameter(node, key, parameter) {
            const path = helpers.findPath(node, key, parameter);
            let obj = helpers.getObjectFromPath(node, path);
            return obj;
        },
        timeDialog(time) {
            console.log(time);
        },
        toogleAlarmOverlay(event) {
            this.$refs.alarmOverlay.toggle(event);
        },
        toggle(event) {
            this.$refs.menu.toggle(event);
            // this.getParameterStatusIOTree();
            // this.getOPCAppTree();
        },
        toggleOP(event, grp) {
            if (grp === 'cfg') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.CFG*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            this.nodeDataCfg = JSON.parse(JSON.stringify(response));
                            this.nodeDataCfgOld = JSON.parse(JSON.stringify(response));
                        }
                            console.log(this.nodeDataCfg);
                            console.log(this.nodeDataCfgOld);
                            // console.log(this.getDiagDataHzg(0, 'PeriodName'));
                        }
                }).then(() => {
                    this.showDiagCfg = true;
                }); 
            }
        },
        diagToggleCfg() {
            if (this.showDiagCfg) {
                this.showDiagCfg = false;
            } else {
                this.showDiagCfg = true;
            }
            this.hideOP();
        },
        hideOP() {
            this.clearVals();
        },
        saveDiag(diag) {
            if (diag) {
                const saveArray = [];
                this.dialogLoading = true;
                this.saveWait = true;

                if (diag === 'cfg') {
                    console.log(this.nodeDataCfg);
                    console.log(this.nodeDataCfgOld);
                    const flatHzg = this.nodeDataCfg.flatMap(innerArr => innerArr);
                    const flatHzgOld = this.nodeDataCfgOld.flatMap(innerArr => innerArr);

                    flatHzg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = flatHzgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                }

                // console.log(saveArray);
                if (saveArray.length > 0) {
                    SocketioService.setOpcValue(saveArray, (err, response) => {
                        if (err) {
                            console.log(err);
                        } else {
                            console.log(response);
                            setOpcValueToast(response, this.$root);
                            this.dialogLoading = false;
                            if (diag === 'cfg') this.showDiagCfg = false;
                            this.hideOP();
                        }
                    });
                } else {
                    setOpcValueToast(null, this.$root);
                    this.dialogLoading = false;
                    if (diag === 'cfg') this.showDiagCfg = false;
                    this.hideOP();
                }
            }
        },
        focusElement(event) {
            if (event !== undefined) {
                event.target.select();
            }
        },
        hideDialog() {
            this.entryDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        resetDialogData() {
            this.ioDialogData = {
                style: {},
                components: {},
                data: {},
            };
            this.selectedKey = {};
            this.expandedKeys = {};
        },
        changeVal(ioData) {
            // console.log(ioData);
            console.log(ioData);
            this.$emit('entrySave', ioData);
        },
        editEntry(ioData) {
            this.ioDialogData = JSON.parse(JSON.stringify(ioData));
            this.ioObj = JSON.parse(JSON.stringify(this.moduleData));
            this.entryDialog = true;
        },
        saveDialog() {
            this.submitted = true;
            if (this.ioDialogData.i) {
                this.$emit('entrySave', this.ioDialogData);
                this.entryDialog = false;
                this.resetDialogData();
            }
        },
        duplicateEntry() {
            this.$emit('entryDuplicate', this.content);
            this.resetDialogData();
        },
        deleteEntry() {
            this.$emit('entryDelete', this.content);
            this.resetDialogData();
        },
        onNodeSelect(node) {
            const newObj = store.getters.getValue(node.key);
            this.selectedKey = node.key;
            this.ioDialogData.data.id = node.key;

            this.$toast.add({
                severity: 'success',
                summary: 'Node ausgewählt',
                detail: newObj.metadata.name + '\n' + this.selectedKey + '\n' + newObj.nodeId,
                life: 3000,
            });
        },
        editValve(ioData) {
            console.log(ioData);
        },
        hideValveDialog() {
            this.valveDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        showChart(value) {
        if (value === 'value1') this.showChart1 = true;
        else if (value === 'rain') this.showChartRain = true;
        else if (value === 'storm') this.showChartStorm = true;
        else if (value === 'frost') this.showChartFrost = true;
        else if (value === 'fog') this.showChartFog = true;
        else if (value === 'winddir') this.showChartWindDir = true;
        },
    },
});
</script>

<style lang="scss" scoped>
.box {
    overflow: hidden;
    // text-shadow: 1px 1px 1px black;
}
.card {
    font-family: 'SensorFont';
    line-height: 1.3rem;
    position: relative;
}

.pulse-enter-active {
    animation: pulse 1s;
}

.pulse-leave-active {
    animation: pulse 0.5s;
}.pulse-loop {
    animation: pulse 1s;
    animation-iteration-count: infinite;
}
@keyframes pulse {
    0% {
        // transform: scale(1); /* scaling to 0 */
        opacity: 0.8;
        // color: yellowgreen;
        // transform-origin: center center;
        // transform: scaleY(.9);
        text-shadow: black 2px 0 10px;
        transform: scale(0.9);
        /* increasing the size */
    }

    30% {
        transform: scale(1.1);
        /* increasing the size */
        text-shadow: gray 1px 0 10px;
    }

    100% {
        transform: scale(1);
        /* seeting back to initial size */
        opacity: 1;
    }
}
.alarm {
    animation: fadeInOut 1s infinite;
    -webkit-animation: fadeInOut 1s infinite;
}
@keyframes fadeInOut {
    0% {
        // opacity: 0;
    }
    50% {
        // opacity: 1;
        background-color: rgba(117, 18, 0, 0.5);
    }
    100% {
        // opacity: 0;
    }
}
</style>