export const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};

export const generateItems = (count, creator) => {
  const result = [];
  for (let i = 0; i < count; i++) {
    result.push(creator(i));
  }
  return result;
};

export const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getDigits = (x) => {
  return (Math.log10((x ^ (x >> 31)) - (x >> 31)) | 0) + 1;
}

export const strTransformData = (str) => {
  const split = str.split("_");
  const arr = split.map(word => {
    return word[0].toLowerCase() + word.slice(1);
  });
  return arr.join("_");
};

export const findIndexById = (object, id) => {
  let index = -1;
  for (let i = 0; i < object.length; i++) {
    if (object[i]._id === id) {
      index = i;
      break;
    }
  }
  return index;
};

export const findIndexByName1 = (object, name) => {
  let index = -1;
  for (let i = 0; i < object.length; i++) {
    if (object[i].name === name) {
      index = i;
      break;
    }
  }
  return index;
}

export const findIndexByLabel = (object, label) => {
  let index = -1;
  for (let i = 0; i < object.length; i++) {
    if (object[i].label === label) {
      index = i;
      break;
    }
  }
  return index;
};

export const findIndexByValue = (object, value) => {
  let index = -1;
  for (let i = 0; i < object.length; i++) {
    if (object[i].value === value) {
      index = i;
      break;
    }
  }
  return index;
};

export const findIndexByDisplayName = (object, displayName) => {
  let index = -1;
  for (let i = 0; i < object.length; i++) {
    if (object[i].displayName === displayName) {
      index = i;
      break;
    }
  }
  return index;
};

export const findIndexByKey = (object, displayName) => {
  let index = -1;
  for (let i = 0; i < object.length; i++) {
    if (object[i].key === displayName) {
      index = i;
      break;
    }
  }
  return index;
};

export function findIndexByTag(object, tag, compareWith) {
  let index = -1;
  if (object !== null && object.length > 0) {
    for (let i = 0; i < object.length; i++) {
      if (object[i] !== null && object[i][tag] === compareWith) {
        index = i;
        break;
      }
    }
  }
  return index;
}

export function findByKey(tree, nodeId) {
  for (let node of tree) {
    if (node.key === nodeId) return node

    if (node.children) {
      let desiredNode = findByKey(node.children, nodeId)
      if (desiredNode) return desiredNode
    } else {
      if (Array.isArray(node)) {
        let desiredNode = findByKey(node, nodeId)
        if (desiredNode) return desiredNode
      }
    }
  }
  return false;
}

export function findByUuid(tree, uuid) {
  for (let node of tree) {
    if (node.uuid === uuid) return node;

    if (node.children) {
      let desiredNode = findByUuid(node.children, uuid);
      if (desiredNode) return desiredNode;
    } else {
      if (Array.isArray(node)) {
        let desiredNode = findByUuid(node, uuid);
        if (desiredNode) return desiredNode;
      }
    }
  }
  return false;
}

export function findByUuidObj(tree, uuid) {
  for (const node in tree) {
    if (node.uuid === uuid) return node;

    if (node.children) {
      let desiredNode = findByUuid(node.children, uuid);
      if (desiredNode) return desiredNode;
    } else {
      if (Array.isArray(node)) {
        let desiredNode = findByUuid(node, uuid);
        if (desiredNode) return desiredNode;
      }
    }
  }
  return false;
}

export function findByParameter(tree, parameter) {
  for (let node of tree) {
    if (node.parameter === parameter) {
      return node;
    }

    if (node.children) {
      let desiredNode = findByParameter(node.children, parameter);
      if (desiredNode) {
        return desiredNode;
      }
    }
  }
  return false;
}

export function findPath(ob, key, value) {
  const path = [];
  const keyExists = (obj) => {
    if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
      return false;
    }
    else if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] === value) {
      return true;
    }
    else if (Array.isArray(obj)) {
      let parentKey = path.length ? path.pop() : "";

      for (let i = 0; i < obj.length; i++) {
        path.push(`${parentKey}[${i}]`);
        const result = keyExists(obj[i], key);
        if (result) {
          return result;
        }
        path.pop();
      }
    }
    else {
      for (const k in obj) {
        path.push(k);
        const result = keyExists(obj[k], key);
        if (result) {
          return result;
        }
        path.pop();
      }
    }

    return false;
  };

  keyExists(ob);

  return path.join(".");
}

// export function getObjectFromPath(arr, path) {
//   const [first, prop, index] = path.match(/[a-zA-Z]+|\d+/g);
//   return arr[first][prop][index];
// }

export function setObjectFromPath(arr, path, updateObj) {
  const [first, prop, index] = path.match(/[a-zA-Z]+|\d+/g);
  arr[first][prop][index] = updateObj;
}

export function getObjectFromPath(o, s){
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}

export function groupBy(xs, f) {
  return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
}

export function nodeScaler(node, objName) {
  if (node.unit !== undefined && node.unit !== null && node.value !== undefined) {
    if (node.unit === '#HMS' || node.unit === '#HM') {
      if (typeof objName === 'string') {
        console.log("IS STRING: ", node.value, node.key);
        node.value = timeStringToInt(node.value);
        if (node.plusminus !== undefined) {
          // MINUS!!!
          if (node.plusminus === true) {
              node.value = node.value*(-1);
          }
        }
      } else {
        if (node.value < 0) {
          node.value=node.value*(-1);
          node.plusminus = true;
        } else {
          node.plusminus = false;
        }
        if (node.unit === '#HMS') node.value = hms(node.value);
        else if (node.unit === '#HM') node.value = hm(node.value);
      }
    //   console.log("HMS: ", node);
    // } else if (typeof node.value === 'string') {
    //   console.log("STRING: ", node);
    // } else if (typeof node.value === 'boolean') {
    //   console.log("BOOL: ", node);
    } else if (node.unit === '#STRING') {
      node.value = node.value.toString();
    } else if (node.unit === '#BOOL') {
      if (node.value) {
        node.value = true;
      } else {
        node.value = false;
      }
    } else {
      if (node.factor > 0) {
        // if string -> its a parameter from the GUI!
        if (typeof objName === 'string') {
          node.value = node.value * node.factor;
        } else {
          node.value = node.value / node.factor;
        }
        if(typeof node.value == 'number') {
          let digits = null;
          if (Object.prototype.hasOwnProperty.call(node, 'decimal') && typeof node.decimal == 'number') {
            digits = node.decimal;
          } else {
            digits = getDigits(node.factor)-1;
          }
          if(digits > -1) {
            const num =  node.value.toFixed(digits);
            // const num = formatNumLocale(node.value, digits);
            node.value = parseFloat(num);
            // node.value = formatNumLocale(node.value, digits);
          }
      }
        // console.log("FACTOR: ", node);
      } else {
        console.log("NO FACTOR: ", node);
      }
    }
    if (node.key !== null && objName !== null) {
      // node.displayName = objName + "." + node.key;
      node.displayName = node.key;
    }
  } else {
    return "🤷🏼‍♂️";
  }
}

export function scaleVal(obj, objName, arr) {
  if (obj === null) return {error: 'NO CONTENT'};
  for (let [, node] of obj.entries()) {
    nodeScaler(node, objName);

    if (Array.isArray(arr) && node.key !== undefined) {
      if (node.children) {
        scaleVal(node.children, objName + "." + node.key, arr);
      } else {
        arr.push(node);
      }
    } else {
      if (node.children) {
        if (Array.isArray(node.children)) {
          scaleVal(node.children, null);
        } else {
          scaleVal(node.children, objName, arr);
        }
      }
    }
  }
  if (Array.isArray(arr)) {
    return arr;
  } else {
    return null;
  }
}

export function scaleVal2(obj, objName, arr) {
  if (obj === null) return {error: 'NO CONTENT'};

  // console.log("SCALEVAL ENTRY: ", obj);
  
  if (obj instanceof Array) {
    // console.log("IS ARRAY");
    for (let [, node] of obj.entries()) {
      nodeScaler(node, objName);
      // console.log("THE NODE: ", typeof node, node);
      if (Array.isArray(arr) && node.key !== undefined) {
        if (node.children) {
          scaleVal2(node.children, objName + "." + node.key, arr);
        } else {
          arr.push(node);
        }
      } else {
        if (node.children) {
          scaleVal2(node.children, objName);
        } else {
          // console.log("NO CHILDREN FOUND");
          if (Array.isArray(node)) {
            scaleVal2(node, objName);
          } else {
            // nodeScaler(node, objName);
          }
        }
      }
    }
  } else if (obj instanceof Object) {
    // console.log("IS OBJECT");
  } else { 
    // console.log("HERE WE HAVE NO ARRAY: ", obj);
    // nodeScaler(obj, objName);
  }
  if (Array.isArray(arr)) {
    return arr;
  } else {
    return null;
  }
}

export function scaleValSingle(obj, value) {
  if (obj === null) return {error: 'NO CONTENT'};

  if (value) {
    obj.value = value;
    nodeScaler(obj, null);
  }
}

export function scaleMapSingle(parameter) {
  if (!parameter) return {error: 'NO CONTENT'};
  nodeScaler(parameter, null);
}

export function processValues (value) {
	const date = new Date(value.timestamp);
	const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
	date.toLocaleDateString('de-DE', options);
	value.timestamp = date;
	if (value.source.parameter) {
		scaleVal2(value.children, null);
	}
  return value;
}

export function paramValue(paramEntry, mode) {
  if (paramEntry) {
    if (mode === 'value') {
      if (paramEntry.value !== undefined) {
        return paramEntry.value;
      }
    } else if (mode === 'unit') {
      if (paramEntry.unit === '#BOOL') {
        return '0/1';
      } else if (paramEntry.unit === '#HMS') {
        return 'h:m:s';
      } else if (paramEntry.unit === '#HM') {
        return 'h:m';
      } else if (paramEntry.unit === '#STRING') {
        return 'Text';
      } else {
        return paramEntry.unit;
      }
    } else {
      return paramEntry.value + paramEntry.unit;
    }
  }
  return "!!!";
}

// export function scaleVal(obj, objName, arr) {
//   if (obj === null) return {error: 'NO CONTENT'};
//   for (let [, node] of obj.entries()) {
//     nodeScaler(node, objName);
//     // if (node.unit !== undefined && node.unit !== null) {
//     //   if (node.unit === 'HMS-Convert') {
//     //     if (typeof objName === 'string') {
//     //       node.value = timeStringToInt(node.value);
//     //       if (node.plusminus !== undefined) {
//     //         // MINUS!!!
//     //         if (node.plusminus === true) {
//     //             node.value = node.value*(-1);
//     //         }
//     //       }
//     //     } else {
//     //       if (node.value < 0) {
//     //         node.value=node.value*(-1);
//     //         node.plusminus = true;
//     //       } else {
//     //         node.plusminus = false;
//     //       }
//     //       node.value = hms(node.value);
//     //     }
//     //   //   console.log("HMS: ", node);
//     //   // } else if (typeof node.value === 'string') {
//     //   //   console.log("STRING: ", node);
//     //   // } else if (typeof node.value === 'boolean') {
//     //   //   console.log("BOOL: ", node);
//     //   } else {
//     //     if (node.factor > 0) {
//     //       if (typeof objName === 'string') {
//     //         node.value = node.value * node.factor;
//     //       } else {
//     //         node.value = node.value / node.factor;
//     //       }
//     //       // console.log("FACTOR: ", node);
//     //     } else {
//     //       console.log("NO FACTOR: ", node);
//     //     }
//     //   }
//     //   if (node.key !== null && objName !== null) {
//     //     // node.displayName = objName + "." + node.key;
//     //     node.displayName = node.key;
//     //   }
//     // }

//     if (Array.isArray(arr) && node.key !== undefined) {
//       if (node.children) {
//         // arr.push(scaleVal(node.children, objName + "[" + index + "]", arr));
//         // arr.push(scaleVal(node.children, objName + "." + node.key, arr));
//         scaleVal(node.children, objName + "." + node.key, arr);
//       } else {
//         arr.push(node);
//       }
//     } else {
//       if (node.children) {
//         console.log(node.parameter, node.children.length, node.size);
//         if (Array.isArray(node.children)) {
//           if (node.size > 1) {
//             for (let i=0; i<node.size; i++) {
//               console.log("OIDA: ", node.children[i]);
//               scaleVal(node.children[i], null);
//             }
//           } else if (node.size === 1) {
//             console.log("OIDA2: ", node.children[0]);
//             scaleVal(node.children, null);
//           }
//         } else {
//           // scaleVal(node.children, objName, arr);
//           console.log("nu");
//         }
//       }
//     }
//   }
//   if (Array.isArray(arr)) {
//     return arr;
//   } else {
//     return null;
//   }
// }

export function addDataField(obj, path) {
  for (let [, node] of obj.entries()) {
    if (node.parameter !== null) {
      if (typeof node.parameter === 'string') {
        const data = strTransformData(node.parameter);
        node.data = data;
        if (typeof path !== 'string') {
          node.key = node.parameter;
          console.log(node.key);
        } else {
          node.key = path + node.parameter;
          console.log(node.key);
        }
        // console.log("Node Key: ", node.key, "Node Data: ", node.data, "Transformed Data: ", data);
      }
    }

    if (node.children) {
      if (typeof node.parameter === 'string') {
        if (node.size && node.size > 1) {
          addDataField(node.children, node.key + '[].');
        } else {
          addDataField(node.children, node.key + '.');
        }
      } else {
        addDataField(node.children, node.key);
      }
    }
  }
}

function mapNodes(obj, map) {
  if (!obj) return;
  if (!map) {
    map = new Map();
  }
  for (let [, node] of obj.entries()) {
    if (node.children) {
      if (node.uuid) {
        if (node.size && node.size > 1) {
          for (let i=0; i<node.size; i++) {
            mapNodes(node.children[i], map);
          }
        } else {
          mapNodes(node.children, map);
        }
      }
    } else {
      map.set(node.key, node);
    }
  }
  return map;
}


// this.nodeEntry.children.forEach((element, ) => {
//   if (element.children && element.size > 1 && element.type === 'array') {
//       element.children.forEach((entry) => {
//           entry.forEach((arrObj) => {
//               if (arrObj.children) {
//                   arrObj.children.forEach((entry2) => {
//                       this.node.set(entry2.key, entry2);
//                   });
//               } else {
//                   this.node.set(arrObj.key, arrObj);
//               }
//           });
//       });
//   } else if (element.children && element.size === 1 && element.type === 'array') {
//       element.children.forEach((entry) => {
//           if (entry.children) {
//               entry.children.forEach((entry2) => {
//                   this.node.set(entry2.key, entry2);
//               });
//           } else {
//               this.node.set(entry.key, entry);
//           }
//       });
//   } else {
//       this.node.set(element.key, element);
//   }
// });

// export function addDataField(obj, path) {
//   for (let [, node] of obj.entries()) {
//     if (node.key !== null) {
//       if (typeof node.key === 'string') {
//         const data = strTransformData(node.key);
//         node.data = data;
//         if (typeof path !== 'string') {
//           node.key2 = node.key;
//         } else {
//           node.key2 = path + node.key;
//         }
//         // console.log("Node Key: ", node.key, "Node Data: ", node.data, "Transformed Data: ", data);
//       }
//     }

//     if (node.children) {
//       if (typeof node.key2 === 'string') {
//         if (node.size && node.size > 1) {
//           addDataField(node.children, node.key2 + '[].');
//         } else {
//           addDataField(node.children, node.key2 + '.');
//         }
//       } else {
//         addDataField(node.children, node.key2);
//       }
//     }
//   }
// }

export function findByKeyAndUpdate(tree, nodeId, updateObj) {
  for (let node of tree) {
    if (node.key === nodeId) {
      return node
    }

    if (node.children) {
      let desiredNode = findByKeyAndUpdate(node.children, nodeId, updateObj)
      if (desiredNode && updateObj) {
        if (updateObj !== null) {
          Object.entries(updateObj).forEach(([key, value], ) => {
            //console.log(index, key, value);
            desiredNode[key] = value;
          });
        }
        //desiredNode.type = updateObj;
        return desiredNode
      }
    }
  }
  return false
}

export function findByKeyAndDelete(tree, nodeId) {
  tree.forEach((element, index) => {
    if (element.key === nodeId) {
      tree.splice(index, 1);
      return element;
    }

    if (element.children) {
      let desiredNode = findByKeyAndDelete(element.children, nodeId);
      if (desiredNode) {
        return desiredNode;
      }
    }
  });
  return false
}

export function findObjectById(arr, id) {
  if (Array.isArray(arr)) {
    const result = arr.find(element => element.id === id);
    return result;
  }
  return null;
}

function extractIdentifierName(identifier) {
  const splitted = identifier.split(".");
  let splittedObj = [];
  const displayName = splitted[splitted.length-1];
  let returnObject = {};

  returnObject.root = splitted[0];
  returnObject.application = splitted[1];
  returnObject.program = splitted[2];
  returnObject.moduleName = splitted[3];
  returnObject.displayName = displayName;
  returnObject.schemeName = '';
  
  const size = splitted.length;

  for(let i=4; i<splitted.length-1; i++) {
    const start = splitted[i].indexOf("[");
    const end = splitted[i].indexOf("]");
  
    if (start !== -1 && end !== -1 && start < end) {
      const element = splitted[i].substring(0, start);
      const index = parseInt(splitted[i].substring(start + 1, end));
      splittedObj[i] = {
        arrayName: element,
        index: index
      };
    }
    returnObject[`child${i-4}`] = splittedObj[i];
    
    // if (typeof splitted[i] === 'object') {
    // 	console.log(`${splitted[i].element} is a object`);
    // }
  }
  
  if (size > 3) {
    for(let i=4; i<size; i++) {
      let schemeName = null;
      
      if (returnObject.schemeName === '') schemeName = '';
      else schemeName = `${returnObject.schemeName}.`;
      returnObject.schemeName = `${schemeName}${splitted[i]}`;
    }
  }
  
  return returnObject;
}

export function splitNodeId(nodeId) {
  if (nodeId) {
    const [ns, identifier] = nodeId.split(";");
    if (ns && identifier) {
      const nsValue = ns.replace(/^ns=/, "");
      const identifierValue = identifier.replace(/^s=/, "");
      const node = extractIdentifierName(identifierValue);
      let splittedNode = {};

      splittedNode.ns = nsValue.toString();
      splittedNode.identifier = identifierValue;

      for (const key in node) {
        if (typeof node[key] === 'string') {
          splittedNode[key] = node[key];
        } else if (typeof node[key] === 'object') {
          splittedNode[key] = JSON.stringify(node[key]);
        }
      }

      return splittedNode;
    } else return '';
  } else return '';
}

export function combineNodeId(ns, identifier) {
  const nodeId = `ns=${ns};s=${identifier}`;
  return nodeId;
}

export function combineRedisHash(server, identifier) {
  const hash = `${server}@${identifier}`;
  return hash;
}

export function splitArrayString(identifier) {
  if (typeof identifier === 'string') {
    const match = identifier.match(/^(.*?)\[(\d+)\](.*)$/);

    if (match) {
      const arrayName = match[1];
      const index = parseInt(match[2]);
      const object = match[3] ? match[3].substring(1) : "";
      return {
        arrayName: arrayName,
        index: index,
        object: object
      }
    }
  }
  return null;
}

export function combineArrayString(arrayName, index, displayName) {
  if (typeof arrayName === 'string') {
    if (index) {
      let dpName = '';
      if (typeof displayName === 'string' && displayName.length > 0) dpName = `.${displayName}`;
      return `${arrayName}[${index}]${dpName}`;
    }
   }
  return null;
}

export function replaceArrayNumber(str, number) {
  if (number) {
    let num = null;
    if (typeof number === 'number') {
      num = number;
    } else {
      num = Math.floor(number).toString();
    }
    const replaced = str.replace(/\[(\d+)\]/, `[${num}]`);
    return replaced;
  }
  return null;  
}

export function processModuleSchemeFromTree(obj) {
	if (typeof obj !== 'object' || obj === null) {
    return obj;
  }
  
  const newObj = {};
  
  //if (obj.dataType === 22 || obj.dataType === null) {
  if (Object.prototype.hasOwnProperty.call(obj, 'children')) {
    if (Array.isArray(obj.children) && obj.children.length > 0) {
      if (Object.prototype.hasOwnProperty.call(obj, 'scheme') && typeof obj.scheme === 'string') {
        newObj.scheme = obj.scheme;
        newObj.root = 'true';
        if (Object.prototype.hasOwnProperty.call(obj, 'label') && typeof obj.label === 'string') newObj.label = obj.label;
      } else {
        newObj.label = splitNodeId(obj.key).displayName;
      }
      
      newObj.key = splitNodeId(obj.key).displayName;
      // if (newObj.key && newObj.key.length > 0) {
      //   // const result = key.charAt(0).toLowerCase() + key.slice(1);
      //   const words = newObj.key.split("_");
      //   const capitalizedWords = words.map(word => word.charAt(0).toLowerCase() + word.slice(1));
      //   const result = capitalizedWords.join("_");
      //   newObj.key = result;
      // }
      const checkArr = splitArrayString(obj.children[0].key);
      
      if (checkArr && checkArr.object === '' && Array.isArray(obj.children[0].children)) {
        // console.log(checkArr);
        // newObj.label = splitNodeId(obj.key).displayName;
        newObj.isArray = true;
        newObj.size = obj.children.length.toString();
        newObj.children = obj.children[0].children.map((child) => processModuleSchemeFromTree(child)).filter((child) => child !== null);
      } else {
        newObj.children = obj.children.map((child) => processModuleSchemeFromTree(child)).filter((child) => child !== null);
      }
    } else {
      newObj.label = splitNodeId(obj.key).displayName;
      newObj.key = splitNodeId(obj.key).displayName;
      // if (newObj.key.length > 0) {
      //   const words = newObj.key.split("_");
      //   const capitalizedWords = words.map(word => word.charAt(0).toLowerCase() + word.slice(1));
      //   const result = capitalizedWords.join("_");
      //   newObj.key = result;
      // }
      newObj.key2 = splitNodeId(obj.key).schemeName;
      newObj.description = '';
      newObj.unit = '°C';
      newObj.factor = 1;
      newObj.decimal = 1;
      newObj.icon = 'mif-florist';
      const checkArr = splitArrayString(newObj.key);
      if (checkArr && checkArr.arrayName) newObj.rootArray = checkArr.arrayName;
    }
  }
  return newObj;
}

export function flattenTree(obj) {
  const result = [];

  function flattenHelper(o, parentKey) {
    if (typeof o !== 'object' || o === null) {
      return;
    }

    if (Object.prototype.hasOwnProperty.call(o, 'key')) {
      const newObj = { ...o };
      delete newObj.children;
      newObj.parent = parentKey;
      result.push(newObj);
    }

    if (Array.isArray(o.children)) {
      o.children.forEach((child) => {
        flattenHelper(child, o.key);
      });
    }
  }

  flattenHelper(obj, null);

  return result;
}

export function flattenPeriodArray(obj) {
  const result = [];

  function flattenHelper(o, parentKey) {
    if (typeof o !== 'object' || o === null) {
      return;
    }

    if (Object.prototype.hasOwnProperty.call(o, 'key2')) {
      const newObj = { ...o };
      delete newObj.children;
      newObj.parent = parentKey;
      result.push(newObj);
    }

    if (Array.isArray(o.children)) {
      o.children.forEach((child) => {
        flattenHelper(child, o.key);
      });
    }
  }

  flattenHelper(obj, null);

  return result;
}

export function extractIdentifierAndValue(ioData) {
  const result = [];
  const recursiveExtract = (arrayOrObject) => {
    console.log(arrayOrObject);
    if (Array.isArray(arrayOrObject)) {
    arrayOrObject.forEach(item => recursiveExtract(item));
    } else if (typeof arrayOrObject === 'object' && arrayOrObject !== null) {
      const { value, key2, arrayKey } = arrayOrObject;
      if (arrayKey !== undefined && arrayKey !== "") {
        result.push({ arrayKey, value });
      } else if (key2 !== undefined && key2 !== "") {
        result.push({ key2, value });
      }
    }
  };

  recursiveExtract(ioData);
  return result;
}

export function updateIdentifierValue(baseObject, importedObject) {
  const updateMap = Object.fromEntries(
      importedObject.map(({ arrayKey, value }) => [arrayKey, value])
  );
  const updateMap2 = Object.fromEntries(
    importedObject.map(({ key2, value }) => [key2, value])
);

  function recursiveUpdate(obj) {
      if (Array.isArray(obj)) {
        obj.forEach(item => recursiveUpdate(item));
      } else if (typeof obj === 'object' && obj !== null) {
        console.log(obj.arrayKey, obj.key2, obj.value);
        if (obj.arrayKey && updateMap[obj.arrayKey] !== undefined && updateMap[obj.arrayKey] !== "") {
          obj.value = updateMap[obj.arrayKey];
        } else if (obj.key2 && updateMap2[obj.key2] !== undefined && updateMap2[obj.key2] !== "") {
          obj.value = updateMap2[obj.key2];
        }
        Object.values(obj).forEach(value => recursiveUpdate(value));
      }
  }

  recursiveUpdate(baseObject);
  return baseObject;
}

export function uploadJsonFile(ev, vueInstance, widgetName, targetObj) {
  const file = ev.target.files[0];
  const reader = new FileReader();

  reader.onload = e => {
      // console.log(e.target.result);
      let jsonObj = null;
      try {
      jsonObj = JSON.parse(e.target.result);
      } catch (e) {
        vueInstance.$toast.add({
              severity: 'error',
              summary: 'Falsches Dateiformat',
              detail: 'Es handelt sich um eine unbekannte oder nicht kompatible Datei.',
              life: 3000,
          });
          return false;
      }
      checkImportType(vueInstance, jsonObj, targetObj, widgetName);
      return true;
  }
  reader.readAsText(file);
}

export function checkImportType(vueInstance, data, baseData, widgetType) {
  const typeCheck = (widgetType === data.type);
  if (typeCheck) {
      updateIdentifierValue(baseData, data.data);
      vueInstance.$toast.add({
          severity: 'success',
          summary: 'Import erfolgreich!',
          detail: 'Import wurde erfolgreich abgeschlossen. Speichern nicht vergessen!',
          life: 3000,
      });
  } else {
    vueInstance.$toast.add({
          severity: 'error',
          summary: 'Fehler!',
          detail: `Falscher Typ! Werte sind nicht kompatibel mit diesem Modul: ${widgetType}`,
          life: 3000,
      });
  }
}

export function exportJson(ioData, widgetName, department) {
  if (ioData && widgetName && department) {
      let saveData = {};
      let tmpData = [];

      tmpData = extractIdentifierAndValue(ioData);
      if (tmpData.length > 0) {
          saveData = {
              type: widgetName,
              data: tmpData
          };
          const currentDate = new Date();
          const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}${(currentDate.getMonth() + 1).toString().padStart(2, '0')}${currentDate.getFullYear()}`;
          const filename = `${department}_${widgetName}_${formattedDate}.json`;
          saveAsFile(filename, saveData);
      }
  }
}

export function saveAsFile(filename, data) {
  const blob = new Blob([JSON.stringify(data)]);
  const link = document.createElement("a");
  link.download = filename;
  link.href = window.URL.createObjectURL(blob);
  link.click();
}

export function mongoResponseToast(response, vueInstance, data) {
  const displayTime = 3000;
  let result = false;
  let label = '';
  let key = '';
  if (data && data.label) label = data.label;
  if (data && data.key) key = data.key;
  // document not changed
  if (response.acknowledged && response.matchedCount > 0 && response.upsertedCount === 0 && response.modifiedCount === 0) {
    vueInstance.$toast.add({
      severity: 'info',
      summary: `Unverändert: ${label}`,
      detail: 'modifiziert: ' + response.modifiedCount + ' , keine Änderung notwendig ',
      life: displayTime,
    });
    // document updated
  } else if (response.acknowledged && response.matchedCount > 0 && response.upsertedCount === 0 && response.modifiedCount > 0) {
    result = true;
    vueInstance.$toast.add({
      severity: 'success',
      summary: `Geändert: ${label}`,
      detail: 'modifiziert: ' + response.modifiedCount + ' , alle Änderungen wurden gespeichert ',
      life: displayTime,
    });
    // document deleted
  } else if (response.acknowledged && response.deletedCount > 0) {
    result = true;
    vueInstance.$toast.add({
      severity: 'success',
      summary: `Gelöscht: ${label}`,
      detail: response.deletedCount + ' Einträge erfolgreich gelöscht',
      life: displayTime,
    });
    // document created
  } else if (response.acknowledged && response.insertedId !== null) {
    result = true;
    vueInstance.$toast.add({
      severity: 'success',
      summary: `Neu erstellt: ${label}`,
      detail: 'Seite mit ID [' + response.insertedId.toString() + '] erfolgreich erstellt',
      life: displayTime,
    });
  } else {
    vueInstance.$toast.add({
      severity: 'error',
      summary: `Datenbankfehler: ${label} ${key}`,
      detail: 'modifiziert: ' + response.modifiedCount + ' , Erstellt: ' + response.upsertedCount + ' [' + response.upsertedId + ']',
      life: 1500,
    });
  }
  return result;
}

export function setOpcValueToast(response, vueInstance) {
  let displayTime = 3 * 1000;
  let displayTimeBad = 3 * 1000;
  let result = false;

  if (Array.isArray(response)) {
    const { goodArray, badArray } = response.reduce(
      (result, element) => {
        if (element.status === 'fulfilled') {
          result.goodArray.push(element);
        } else if (element.status === 'rejected') {
          result.badArray.push(element);
        }
      return result;
      },
      { goodArray: [], badArray: [] }
    );
    if (goodArray.length > 0) {
      vueInstance.$toast.add({
        severity: 'success',
        summary: 'Erfolgreich gespeichert!',
        detail: `${goodArray.length} Änderung(en) wurden in die Steuerung geladen`,
        life: displayTime,
      });
    }
    if (badArray.length > 0) {
      if ((displayTimeBad + (badArray.length*1000)) > 20*1000) displayTimeBad = 20*1000;
      else displayTimeBad = displayTimeBad + badArray.length*1000;
      badArray.forEach((badNode) => {
        if (badNode.reason) {
          if (badNode.reason.error && badNode.reason.error.type) {
            if (badNode.reason.error.type === 'norights') {
              vueInstance.$toast.add({
                severity: 'error',
                summary: `Keine Berechtigung für ${badNode.reason.node.department}!`,
                detail: `Parameter ${badNode.reason.node.label} nicht geändert`,
                life: displayTimeBad,
              });
            } else if (badNode.reason.error.type === 'nowrite') {
              vueInstance.$toast.add({
                severity: 'error',
                summary: `Nur lesen erlaubt!`,
                detail: `Parameter ${badNode.reason.node.label} kann nur gelesen werden`,
                life: displayTimeBad,
              });
            } else if (badNode.reason.error.type === 'promise') {
              vueInstance.$toast.add({
                severity: 'error',
                summary: `Serverfehler!`,
                detail: `Parameter ${badNode.reason.node.label} konnte nicht an den Server gesendet werden`,
                life: displayTimeBad,
              });
            }
          }
        }
      });
    }
  } else {
    vueInstance.$toast.add({
      severity: 'warn',
      summary: 'Keine Änderungen!',
      detail: 'Es wurde kein einziger Wert geändert!',
      life: displayTime,
    });
  }
  return result;
}

export function setOpcAlarmToast(response, vueInstance) {
  let displayTime = 4 * 1000;
  let displayTimeBad = 4 * 1000;
  let result = false;

  if (Array.isArray(response)) {
    const { goodArray, badArray } = response.reduce(
      (result, element) => {
        if (element.status === 'fulfilled') {
          result.goodArray.push(element);
        } else if (element.status === 'rejected') {
          result.badArray.push(element);
        }
      return result;
      },
      { goodArray: [], badArray: [] }
    );
    if (goodArray.length > 0) {
      vueInstance.$toast.add({
        severity: 'success',
        summary: 'Erfolgreich quittiert!',
        detail: `${goodArray.length} Alarm(e) erfolgreich quittiert`,
        life: displayTime,
      });
    }
    if (badArray.length > 0) {
      if ((displayTimeBad + (badArray.length*1000)) > 20*1000) displayTimeBad = 20*1000;
      else displayTimeBad = displayTimeBad + badArray.length*1000;
      badArray.forEach((badNode) => {
        if (badNode.reason) {
          if (badNode.reason.error && badNode.reason.error.type) {
            if (badNode.reason.error.type === 'norights') {
              vueInstance.$toast.add({
                severity: 'error',
                summary: `Keine Berechtigung für ${badNode.reason.node.department}!`,
                detail: `Alarm ${badNode.reason.node.label} nicht quittiert`,
                life: displayTimeBad,
              });
            } else if (badNode.reason.error.type === 'nowrite') {
              vueInstance.$toast.add({
                severity: 'error',
                summary: `Nur lesen erlaubt!`,
                detail: `Alarm ${badNode.reason.node.label} kann nur gelesen werden`,
                life: displayTimeBad,
              });
            } else if (badNode.reason.error.type === 'promise') {
              vueInstance.$toast.add({
                severity: 'error',
                summary: `Serverfehler!`,
                detail: `Quittierung ${badNode.reason.node.label} konnte nicht an den Server gesendet werden`,
                life: displayTimeBad,
              });
            }
          }
        }
      });
    }
  } else {
    vueInstance.$toast.add({
      severity: 'warn',
      summary: 'Keine Änderungen!',
      detail: 'Es wurde kein einziger Alarm ausgewählt!',
      life: displayTime,
    });
  }
  return result;
}

export const isUUIDv4 = (str) => {
  return str.includes('-4');
};

export const formatUnit = (node) => {
  if (node.unit === '#BOOL') {
    if (node.value === true) return '🟢 Ein';
    else return '🔴 Aus';
  }
  else return node.value;
};

export const formatValue = (value, unit, factor) => {
  if (typeof value === 'string') {
    return value;
  } else {
    if (!unit) unit = '';
    if (!factor) factor = 1;
    return `${value/factor} ${unit}`;
  }
};

export const formatCurrency = (value) => {
  if (value)
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  return;
};

export const formatDateLocale = (timestamp) => {
  const dt = new Date(timestamp).toLocaleDateString(
    'de-at', { weekday: "long", year: "numeric", month: "numeric", day: "numeric" }
  );
  return dt;
};

export const formatDateEpoch = (timestamp) => {
  const dt = new Date(timestamp*1000);
  const year = dt.getFullYear();
  const month = IntTwoChars(dt.getMonth() + 1);
  const date = IntTwoChars(dt.getDate());
  const hours = IntTwoChars(dt.getHours());
  const minutes = IntTwoChars(dt.getMinutes());
  const seconds = IntTwoChars(dt.getSeconds());
  return `${date}.${month}.${year} ${hours}:${minutes}:${seconds}`;
};

export const formatDateLog = (timestamp) => {
  const dt = new Date(timestamp);
  const year = dt.getFullYear();
  const month = IntTwoChars(dt.getMonth() + 1);
  const date = IntTwoChars(dt.getDate());
  const hours = IntTwoChars(dt.getHours());
  const minutes = IntTwoChars(dt.getMinutes());
  const seconds = IntTwoChars(dt.getSeconds());
  return year + '-' + month + '-' + date + ' 🕐 ' + hours + ':' + minutes + ':' + seconds;
};

export const formatDateGui = (timestamp) => {
  const dt = new Date(timestamp);
  const year = dt.getFullYear();
  const month = IntTwoChars(dt.getMonth() + 1);
  const date = IntTwoChars(dt.getDate());
  const hours = IntTwoChars(dt.getHours());
  const minutes = IntTwoChars(dt.getMinutes());
  const seconds = IntTwoChars(dt.getSeconds());
  return `${date}.${month}.${year} \u2022 ${hours}:${minutes}:${seconds}`;
};

export const formatDateDay = (timestamp) => {
  const dt = new Date(timestamp);
  const year = dt.getFullYear();
  const month = IntTwoChars(dt.getMonth() + 1);
  const date = IntTwoChars(dt.getDate());
  return year + '-' + month + '-' + date;
};

export const dhm = (ms) => {
  const days = Math.floor(ms / (24 * 60 * 60 * 1000));
  const daysms = ms % (24 * 60 * 60 * 1000);
  const hours = Math.floor(daysms / (60 * 60 * 1000));
  const hoursms = ms % (60 * 60 * 1000);
  const minutes = Math.floor(hoursms / (60 * 1000));
  // const minutesms = ms % (60*1000);
  // const sec = Math.floor(minutesms / 1000);
  // return days + ":" + hours + ":" + minutes + ":" + sec;
  return days + "d " + hours + "h " + minutes + "m";
}

export const hmsdFromSeconds = (s, f) => {

  //const days = Math.floor(s / 86400);
  const hours = Math.floor(s / 3600).toLocaleString('de-DE', { minimumIntegerDigits: 3, useGrouping: false });
  const minutes = Math.floor((s % 3600) / 60).toLocaleString('de-DE', { minimumIntegerDigits: 2, useGrouping: false });
  const sec = Math.floor(s % 60).toLocaleString('de-DE', { minimumIntegerDigits: 2, useGrouping: false });
  
  if (f) {
    return hours + minutes + sec;
  } else {
    return hours + ":" + minutes + ":" + sec;
  }
}

export const hms = (s, f) => {
  // const days = Math.floor(s / (24*60*60*1000));
  let daysms, hours, hoursms, minutes, minutesms, sec;
  if (s <= 86400 && s >= -86400) {
    if (s === 86400) {
      hours = "24";
      minutes = "00";
      sec = "00";
    } else if (s === -86400) {
      hours = "-24";
      minutes = "00";
      sec = "00";
    } else {
      daysms = s % (24 * 60 * 60);
      hours = Math.floor(daysms / (60 * 60)).toLocaleString('de-DE', { minimumIntegerDigits: 2, useGrouping: false });
      hoursms = s % (60 * 60);
      minutes = Math.floor(hoursms / (60)).toLocaleString('de-DE', { minimumIntegerDigits: 2, useGrouping: false });
      minutesms = s % (60);
      sec = Math.floor(minutesms).toLocaleString('de-DE', { minimumIntegerDigits: 2, useGrouping: false });
      // return days + ":" + hours + ":" + minutes + ":" + sec;
    }
    if (f) {
      return hours + minutes + sec;
    } else {
      return hours + ":" + minutes + ":" + sec;
    }
  } else {
    if (f) {
      return 0;
    } else {
      return "00:00:00";
    }
  }
}

export const hm = (s, f) => {
  const daysms = s + 1 % (24 * 60 * 60);
  const hours = Math.floor(daysms / (60 * 60)).toLocaleString('de-DE', { minimumIntegerDigits: 2, useGrouping: false });
  const hoursms = s % (60 * 60);
  const minutes = Math.floor(hoursms / (60)).toLocaleString('de-DE', { minimumIntegerDigits: 2, useGrouping: false });
  if (f) {
    return hours + minutes;
  } else {
    return `${hours}:${minutes}`;
  }
}

export const todToStr = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
}

export const timestampToUnix = function(timestamp) {
  if (timestamp !== null) {
    const time = new Date(timestamp);
    return Math.floor(time.getTime() / 1000);
  } else {
    return 0;
  }
}

export const unixTimeToStr = (unixTimestamp) => {
  const date = new Date(unixTimestamp * 1000);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}

export const timeStringToInt = function (str) {
  if (str !== null && typeof str == 'string') {
    let arr = str.split(":");
    let sec = (parseInt(arr[0], 10) * 60 * 60) + (parseInt(arr[1], 10) * 60) + parseInt(arr[2], 10);
    return sec;
  }
}

export const parseNode = (node, reverse) => {
  if (node) {
    const value = node.value;
    node.valueRaw = value;
    if (node.unit === '#TOD_UDINT') {
      let str = null;
      if (reverse) {
        node.value = timeStringToInt(value);
      } else {
        str = todToStr(parseInt(value));
        node.value = str;
        node.unitStr = 'h';
      }
    } else if (node.unit === '#UNIXTIME') {
      let str = null;
      if (reverse) {
        str = timestampToUnix(timeStringToInt(value));
        node.value = str;
      } else {
        str = unixTimeToStr(parseInt(value));
        node.value = str;
        node.unitStr = '';
      }
    } else if (node.unit === '#UNIXEPOCH') {
      let str = null;
      if (reverse) {
        str = timestampToUnix(timeStringToInt(value));
        node.value = str;
      } else {
        str = formatDateEpoch(parseInt(value));
        node.value = str;
        node.unitStr = '';
      }
    } else if (node.unit === '#HMS' || node.unit === '#HM') {
        if (reverse) {
          if (typeof node.value === 'string') {
            node.value = timeStringToInt(value);
            if ('plusminus' in node) {
              if (node.plusminus === true) node.value = node.value*(-1);
              delete node.plusminus;
            }
            node.value = node.value.toString();
          }
        } else {
          if (typeof value === 'string') {
            node.value = parseInt(value);
          } else {
            node.value = value;
          }
          node.valueRaw = node.value;
          if (node.value < 0) {
            node.value=node.value*(-1);
            node.plusminus = true;
          } else {
            node.plusminus = false;
          }
          if (node.unit === '#HMS') {
            node.value = hms(node.value);
            node.unitStr = '';
          }
          else if (node.unit === '#HM') {
            node.value = hm(node.value);
            node.unitStr = '';
          }
        }
    } else if (node.unit === '#HMSD') {
      if (reverse) {
        if (typeof node.value === 'string') {
          node.value = timeStringToInt(value);
          node.value = node.value.toString();
        }
      } else {
        if (typeof value === 'string') {
          node.value = parseInt(value);
        } else {
          node.value = value;
        }
        node.value = hmsdFromSeconds(node.value);
        node.unitStr = '';
      }
    } else if (node.unit === '#TIMEMODE') {
      node.value = parseInt(value);
      node.unitStr = '';
    } else if (node.unit === '#STRING') {
      node.unitStr = '';
    } else if (node.unit === '#BOOL') {
      if (reverse) {
        if (node.value === true) node.value = 'true';
        else node.value = 'false';
      } else {
        if (node.value === 'true') node.value = true;
        else node.value = false;
        node.unitStr = '';
      }
    } else if (node.unit === '#BOOL_YN') {
      if (node.value === 'true') node.value = 'Ja';
      else node.value = 'Nein';
      node.unitStr = '';
    } else if (node.unit === '#SYNOPTO') {
      const str = rainType(parseInt(node.value));
      node.value = str;
      node.unitStr = '';
    } else {
      let factor = null;
      let decimal = null;
      if (typeof node.factor === 'string') factor = parseInt(node.factor);
      else factor = node.factor;
      if (typeof node.decimal === 'string') decimal = parseInt(node.decimal);
      else decimal = node.decimal;
      if (factor <= 0) factor = 1;
      let val = null;

      if (reverse) {
        val = JSON.stringify(node.value*factor);
        node.value = val;
        node.unitStr = node.unit;
      } else {
        val = (parseFloat(node.value)/factor).toFixed(decimal);
        node.value = Number(val);
        if (decimal > 0 && node.record === 'true' && node.label !== "AlarmEntriesCount" && Number.isInteger(node.value)) {
          node.value = `${node.value}.0`;
        }
        node.unitStr = node.unit;
      }
    }
    return node;
  }
}

export const parseAlarm = (alarm, reverse) => {
  if (alarm) {
    if (typeof alarm.value === 'string' && !reverse) alarm.value = parseInt(alarm.value);
    else alarm.value.toString();

    if (typeof alarm.alarmPrior === 'string' && !reverse) alarm.alarmPrior = parseInt(alarm.alarmPrior);
    else alarm.alarmPrior.toString();
  }
  return alarm;
}

export const isArray = function (a) {
  return (!!a) && (a.constructor === Array);
}

export const isObject = function (a) {
  return (!!a) && (a.constructor === Object);
}

export const isEven = function (number) {
  return number % 2 === 0;
}

export const isOdd = function (number) {
  return number % 2 !== 0;
}

export const getWindowUrl = () => {
  let srvUrl = {
    host: window.location.hostname,
    port: window.location.port,
    protocol: window.location.protocol,
    path: '/',
    options: {}
  }
  return srvUrl;
}

export const Role = {
  Admin: 'Admin',
  Master: 'Master',
  User: 'User'
}

export const manipulateComponentString = function(input) {
  if (input.startsWith("widget")) {
    let modifiedString = input.slice(6);
    modifiedString = modifiedString.charAt(0).toLowerCase() + modifiedString.slice(1);
    return `widgets/${modifiedString}.vue`;
  } else if (input.startsWith("dashboard")) {
    let modifiedString = input.slice(9);
    modifiedString = modifiedString.charAt(0).toLowerCase() + modifiedString.slice(1);
    return `dashboard/${modifiedString}.vue`;
  }
  return input;
}

export const isComponentRegistered = function(app, componentName) {
  return !!app._context.components[componentName];
}

export const consoleDebug = function(...args) {
  if (process.env.VUE_APP_ENVIRONMENT === "development1") {
    console.log.apply(console, [...args]);
  }
}
export const consoleError = function(...args) {
  if (process.env.VUE_APP_ENVIRONMENT === "development1") {
    console.error.apply(console, [...args]);
  }
}

function showToast(severity, summary, detail, life) {
  this.$toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: life,
  });
}

function returnChild(obj, childStr) {
  let result = {};
  if (obj !== undefined && obj.children !== undefined) {
      const index = findIndexByTag(obj.children, "parameter", childStr);
      if (index !== -1) {
          result = obj.children[index];
          return result;
      } else return;
  } else {
      result.value = 0;
      result.label = '🤷🏼‍♂️';
      result.key = '🤷🏼‍♂️';
      result.unit = '🤷🏼‍♂️';
      result.factor = 1;
      result.type = '🤷🏼‍♂️';
      result.icon = '🤷🏼‍♂️';
      return result;
  }
}

function returnParameter(node, key, parameter) {
  const path = findPath(node, key, parameter);
  let obj = getObjectFromPath(node, path);
  return obj;
}

function returnParameterValue(node, key, parameter, unit) {
  const path = findPath(node, key, parameter);
  let obj = getObjectFromPath(node, path);
  if (unit) {
      return obj.value + obj.unit;
  } else {
      return obj.value;
  }
}

function rainType(value) {
  if (value >= 0) {
      const synopto = value;
      if (synopto === 0) return "trocken";
      if (synopto === 40) return "Niederschlag";
      if (synopto === 51) return "leichter Niesel";
      if (synopto === 52) return "mäßiger Niesel";
      if (synopto === 53) return "starker Niesel";
      if (synopto === 61) return "leichter Regen";
      if (synopto === 62) return "mäßiger Regen";
      if (synopto === 63) return "starker Regen";
      if (synopto === 67) return "leichter Schneeregen";
      if (synopto === 68) return "mäßiger Schneeregen";
      if (synopto === 70) return "Schneefall";
      if (synopto === 71) return "leichter Schnee";
      if (synopto === 72) return "mäßiger Schnee";
      if (synopto === 73) return "starker Schnee";
      if (synopto === 74) return "Eiskörner";
      if (synopto === 89) return "starker Hagel";
      if (synopto === 98) return "hohe Luftfeuchte";
      if (synopto === 99) return "Nebel";
      if (synopto === 100) return "Trocknungsphase";
      if (synopto === 101) return "manueller Regenschutz!";
  }
  return value;
}

function windDirection(degree) {
  if (!isNaN(degree)) {
    if ((degree >= 349 && degree <= 360) || (degree >= 0 && degree < 12)) return 'N';
    if (degree >= 12 && degree < 34) return 'NNO';
    if (degree >= 34 && degree < 56) return 'NO';
    if (degree >= 56 && degree < 79) return 'ONO';
    if (degree >= 79 && degree < 101) return 'O';
    if (degree >= 101 && degree < 124) return 'OSO';
    if (degree >= 124 && degree < 146) return 'SO';
    if (degree >= 146 && degree < 169) return 'SSO';
    if (degree >= 169 && degree < 191) return 'S';
    if (degree >= 191 && degree < 213) return 'SSW';
    if (degree >= 213 && degree < 236) return 'SW';
    if (degree >= 236 && degree < 259) return 'WSW';
    if (degree >= 259 && degree < 281) return 'W';
    if (degree >= 281 && degree < 304) return 'WNW';
    if (degree >= 304 && degree < 326) return 'NW';
    if (degree >= 326 && degree < 349) return 'NNW';
  }
  return '???';
}

function ventOrientation(orientation, wing) {
  let ori = orientation;
  if (typeof orientation === 'string') ori = Math.floor(parseInt(orientation));
  if (wing === 1) {
    if (ori === 0) return 'Nord';
    else if (ori === 1) return 'Nord/Ost';
    else if (ori === 2) return 'Ost';
    else if (ori === 3) return 'Süd/Ost';
    else return '🤷🏼‍♂️';
  } else if (wing === 2) {
    if (ori === 0) return 'Süd';
    else if (ori === 1) return 'Süd/West';
    else if (ori === 2) return 'West';
    else if (ori === 3) return 'Nord/West';
    else return '🤷🏼‍♂️';
  } else {
    return '🤷🏼‍♂️';
  }
}

function IntTwoChars(i) {
  return (`${i}`).padStart(2, '0');
}

function formatNumLocale(num, digits) {
  return new Intl.NumberFormat('de-AT', { minimumFractionDigits: 1, maximumFractionDigits: digits }).format(num);
}

function playAudio(name, loop) {
  let file = null;
  if (name === 'info') {
    file = require('@/assets/audio/info.wav');
  }
  if (name === 'alarm') {
    file = require('@/assets/audio/alarm.ogg');
  }
  const sound = new Audio(file);
  if (loop) sound.loop = "loop";
  sound.play();
}

function calcLight(value, mode) {
  let calc = 0;
  if (value && mode) {
      if (mode === 'toKlxh') {
          calc = value*0.36;
      } else if (mode === 'toKlx') {
          calc = value*0.13;
      }
  }
  return calc.toFixed(1);
}

// export default class ProductService {

//   getProductsSmall() {
//     return fetch('data/products.json').then(res => res.json()).then(d => d.data);
//   }

//   getProducts() {
//     return fetch('data/products.json').then(res => res.json()).then(d => d.data);
//   }

//   getProductsWithOrdersSmall() {
//     return fetch('data/products-orders-small.json').then(res => res.json()).then(d => d.data);
//   }
// }

export default {
  Role,
  getRandomInt,
  getDigits,
  getWindowUrl,
  findIndexByLabel,
  findIndexById,
  findIndexByTag,
  findIndexByValue,
  findIndexByName(object, name) {
    let index = -1;
    for (let i = 0; i < object.length; i++) {
      if (object[i].value === name) {
        index = i;
        break;
      }
    }
    return index;
  },
  findIndexByDisplayName,
  findByKey,
  findByUuid,
  findByUuidObj,
  findByParameter,
  findPath,
  getObjectFromPath,
  setObjectFromPath,
  findByKeyAndUpdate,
  findByKeyAndDelete,
  groupBy,
  nodeScaler,
  scaleVal,
  scaleVal2,
  scaleValSingle,
  scaleMapSingle,
  processValues,
  paramValue,
  addDataField,
  hms,
  hm,
  timeStringToInt,
  formatNumLocale,
  showToast,
  isObject,
  isArray,
  returnChild,
  returnParameter,
  returnParameterValue,
  mapNodes,
  rainType,
  windDirection,
  ventOrientation,
  playAudio,
  calcLight
}