<template>
    <Dialog 
        v-model:visible="entryDialog"
        :breakpoints="{ '960px': '85vw', '640px': '90vw' }"
        :style="{ width: '40vw' }"
        :header="'Parameterliste bearbeiten [' + ioDialogData.i + ']'"
        :modal="true"
        class="p-fluid"
        @hide="hideDialog"
    >
        <div v-if="editMode">
            <div class="field">
                <p>Wird das Feld <b>Label</b> nicht ausgefüllt, wird der zugehörige IO-Name automatisch angezeigt</p>
            </div>
            <div class="formgrid grid">
                <div class="field col">
                    <label for="name">Name</label>
                    <InputText id="name" v-model.trim="ioDialogData.name" required="false" autofocus />
                </div>
                <div class="field col">
                    <label for="label">Label</label>
                    <InputText id="label" v-model.trim="ioDialogData.label" required="false" autofocus />
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col">
                    <label for="component">Component</label>
                    <InputText id="component" v-model="ioDialogData.component" required="false" readonly disabled />
                </div>
                <div class="field col">
                    <label for="id">ID</label>
                    <InputText id="id" v-model="ioDialogData.i" required="false" readonly disabled />
                </div>
            </div>

            <div class="formgrid grid">
                <div class="field col">
                    <label for="type">Feldbreite</label>
                    <InputNumber id="width" v-model="ioDialogData.width" mode="decimal" showButtons :min="1" :max="12" />
                </div>

                <div class="field col">
                    <label for="background">Hintergrund</label>
                    <Dropdown id="style.background" v-model="ioDialogData.style.background" :options="colors" optionLabel="label" optionValue="value">
                        <template #value="slotProps">
                            <div :class="'bg-' + slotProps.value">
                                <span>Hintergrund</span>
                            </div>
                        </template>
                        <template #option="slotProps">
                            <div :class="'bg-' + slotProps.option.value">
                                <span>{{ slotProps.option.label }}</span>
                            </div>
                        </template>
                    </Dropdown>
                </div>

                <div class="field col">
                    <label for="foreground">Vordergrund</label>
                    <Dropdown id="style.foreground" v-model="ioDialogData.style.foreground" :options="colors" optionLabel="label" optionValue="value">
                        <template #value="slotProps">
                            <div :class="'fg-' + slotProps.value">
                                <span>Vordergrund</span>
                            </div>
                        </template>
                        <template #option="slotProps">
                            <div :class="'fg-' + slotProps.option.value">
                                <span>{{ slotProps.option.label }}</span>
                            </div>
                        </template>
                    </Dropdown>
                </div>
            </div>

            <div class="field">
                <label for="pattern">Muster</label>
                <Dropdown id="style.pattern" v-model="ioDialogData.style.pattern" :options="patterns" optionLabel="label" optionValue="value">
                    <template #value="slotProps">
                        <div :class="'bg-white fg-black ptrn-' + slotProps.value">
                            <span>Muster</span>
                        </div>
                    </template>
                    <template #option="slotProps">
                        <div :class="'bg-white fg-black ptrn-' + slotProps.option.value">
                            <span>{{ slotProps.option.label }}</span>
                        </div>
                    </template>
                </Dropdown>
            </div>
        </div>

        <div class="formgrid grid mt-2" >
            <div class="field col-8">
              <label for="description">OPC-UA Verzeichnis</label>
              <Tree
                :value="opcTree"
                :loading="!opcTree"
                selectionMode="checkbox"
                v-model:expandedKeys="opcTreeExpandedKeys"
                v-model:selectionKeys="opcTreeSelection"
                @node-select="onOpcTreeSelect"
                @node-unselect="onOpcTreeUnselect"
                scrollHeight="500px"
                :filter="true" filterMode="lenient"
                key="key" label="label"
              ></Tree>
            </div>
            <div class="field col-12">
                <OrderList v-model="opcTreeSelectedNodes" listStyle="height:auto" dataKey="key" @reorder="onReorder" @selection-change="onSelectionChange">
                    <template #header> Ausgewählte Parameter (Sortierung) </template>
                    <template #item="slotProps">
                        <div class="flex flex-wrap p-2 align-items-center gap-3">
                            <div class="flex-1 flex flex-column gap-2">
                                <span class="font-bold">{{ slotProps.item.label }}</span>
                                <div class="flex align-items-center gap-2">
                                    <i class="pi pi-tag text-sm"></i>
                                    <span class="text-xs">{{ splitNodeIdLocal(slotProps.item.key).identifier }}</span>
                                </div>
                            </div>
                            <span class="font-bold">{{ slotProps.item.server }}</span>
                        </div>
                    </template>
                </OrderList>
                <div class="field mt-3" v-if="selectedOrderItem !== null">
                    <label for="specialLabel">Sonderbezeichnung</label>
                    <InputText id="specialLabel" v-model:model-value="selectedOrderItem.labelSpecial" required="false" autofocus />
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
            <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveDialog" :disabled="opcTreeSelectedNodes.length === 0" />
        </template>
    </Dialog>
</template>

<script>
import { defineComponent } from 'vue';
// import helpers from "@/helpers";
import store from '@/store';
import SocketioService from "@/services/socketioService.js";
import { mapGetters } from 'vuex';
import { splitNodeId, combineNodeId, isUUIDv4 } from "@/helpers";

export default defineComponent({
    name: 'dialogOpcTree',
    setup() {},
    emits: ['entrySave', 'entryAbort'],
    props: {
        showDialog: {
            type: Boolean,
            required: true,
        },
        dialogData: {
            type: Object,
            required: true,
        },
        dialogObj: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            loading: true,
            editMode: true,
            entryDialog: false,
            ioDialogData: {
                source: {},
                metadata: {},
            },
            config_io: null,
            selectedScheme: null,
            selectedParameter: null,
            submitted: false,
            opcTree: [],
            opcTreeSelectedNodes: [],
            opcTreeSelection: {},
            opcTreeExpandedKeys: null,
            opcTreeSelectedNode: null,
            selectedOrderItem: null,
        };
    },
    computed: {
        ...mapGetters({
            getUserGroup: 'users/getUserGroups',
            getCategory: 'types/getCategory',
            getDepartments: 'types/getDepartments',
            getUnits: 'types/getUnits',
            getIcons: 'types/getIcons',
            getModules: 'opcua/getModules',
            getModuleScheme: 'opcua/getModuleScheme',
            getModuleTypes: 'opcua/getModuleTypes',
            getModuleBindings: 'opcua/getModuleBindings',
            getObjectFactor: 'getObjectFactor',
            getObjectDecimal: 'getObjectDecimal',
            getOpcuaServers: 'opcua/getServers',
        }),
        ioObj: function () {
            return this.dialogObj;
        },
        entryDiag: function () {
            return this.showDialog;
        },
        // ioDialogData: function () {
        //   return this.dialogData;
        // },
        nodeEntry: function () {
            if (this.content.data.id) {
                return store.getters.getValue(this.content.data.id);
            } else return undefined;
        },
        nodes: function () {
            return store.getters.getIoTree;
        },
        colors: function () {
            return store.getters.getStyleColors;
        },
        patterns: function () {
            return store.getters.getStylePatterns;
        },
        currentModuleScheme: function () {
            if (this.selectedScheme !== null && this.getModuleScheme && this.getModuleScheme.length > 0) {
                const scheme = this.getModuleScheme.find(item => item.scheme === this.selectedScheme);
                if (Array.isArray(scheme.children)) {
                    return scheme.children;
                }
            }
            return null;
        },
    },
    created() {
        this.$store.dispatch('users/loadUserGroups');
        this.$store.dispatch('types/loadUnitTypes');
        this.$store.dispatch('types/loadCategoryTypes');
        this.$store.dispatch('types/loadDepartmentTypes');
        this.$store.dispatch('types/loadIconTypes');
        this.$store.dispatch('opcua/loadModules');
        this.$store.dispatch('opcua/loadModuleScheme');
        this.$store.dispatch('opcua/loadModuleTypes');
        this.$store.dispatch('opcua/loadModuleBindings');
    },
    mounted() {
        this.ioDialogData = this.dialogData;
        this.entryDialog = this.entryDiag;
        if (this.ioDialogData.data && this.ioDialogData.data.moduleScheme) this.selectedScheme = this.ioDialogData.data.moduleScheme;
        console.log(this.ioDialogData);
        this.getParameterArray();
        this.getOpcDirectory();
    },
    beforeUnmount() {
        this.loading = false;
        this.ioDialogData = {};
        this.opcTree = [];
        this.opcTreeSelection = null;
        this.opcTreeExpandedKeys = null;
        this.opcTreeSelectedNode = null;
        this.selectedIOs = null;
        this.submitted = false;
        this.io_data = null;
        this.opcTreeSelectedNodes = [];
    },
    methods: {
        hideDialog() {
            this.$emit('entryAbort');
        },
        resetDialogData() {
            this.ioDialogData = {
                style: {},
                components: {},
                data: [],
            };
            this.selectedParameter = null;
            this.selectedScheme = null;
        },
        saveDialog() {
            this.submitted = true;

            if (this.ioDialogData.i >= 0 || isUUIDv4(this.ioDialogData.i)) {
                if (this.opcTreeSelectedNodes.length > 0) {
                    let addRow = 0;
                    if (this.opcTreeSelectedNodes.length < 5) addRow = 2;
                    this.ioDialogData.h = this.opcTreeSelectedNodes.length + addRow;
                    this.ioDialogData.data = [ ...this.opcTreeSelectedNodes ];
                }
                this.$emit('entrySave', this.ioDialogData);
                this.entryDialog = false;
                this.resetDialogData();
            }
        },
        async getOpcDirectory() {
            if (this.opcTree.length === 0) {
                this.loading = true;
                // SocketioService.getOpcuaBrowse(null, (err, response) => {
                // if (!err && response !== null && response.length > 0) {
                //     response.forEach((tree) => {
                //         this.opcTree.push(tree.children[0]);
                //     });
                //     this.loading = false;
                // }
                // });
                SocketioService.getOpcuaTreeBrowse(null, (err, response) => {
                    if (!err && response !== null && response.length > 0) {
                        // response.forEach((tree) => {
                        // // if (tree.children) delete tree.children;
                        // // this.opcTree.push(tree);
                        // });
                        this.loading = false;
                        console.log(response);
                        this.opcTree = this.groupModulesByServerAndProgram(response);
                        console.log(this.opcTree);
                    }
                });
            }
        },
        groupModulesByServerAndProgram(modules) {
            const result = []; // Final hierarchical structure
            const serverMap = new Map(); // Map to hold servers for grouping
            // Step 1: Group by Server
            for (const module of modules) {
            const srv = this.getOpcuaServers.find(obj => obj.key === module.server);
                const { server, program, label: moduleName, nodeId, children } = module;
                // Create a server entry if it doesn't exist
                if (!serverMap.has(server)) {
                    serverMap.set(server, {
                        label: `${srv.label}`,
                        server: server,
                        key: server,
                        children: new Map(), // Map for grouping programs
                    });
                }
                const serverEntry = serverMap.get(server);
                // Step 2: Group by Program within each Server
                if (!serverEntry.children.has(program)) {
                    serverEntry.children.set(program, {
                        label: program,
                        key: program,
                        children: [],
                        server: server,
                    });
                }
                const programEntry = serverEntry.children.get(program);

                // Step 3: Add the current module as a child of the program
                const moduleEntry = {
                    label: moduleName,
                    key: nodeId,
                    icon: "mif-dashboard",
                    children: children || [], // Use pre-existing children
                };
                this.processTreeChilds(moduleEntry, server);
                programEntry.children.push(moduleEntry);
            }
            // Step 4: Convert Maps to Arrays for the Final Result
            for (const serverEntry of serverMap.values()) {
                const children = [];
                for (const programEntry of serverEntry.children.values()) {
                console.log(programEntry);
                    children.push({
                        label: programEntry.label,
                        key: programEntry.label,
                        children: programEntry.children,
                        icon: "mif-database",
                    });
                }
                result.push({
                    label: serverEntry.label,
                    server: serverEntry.server,
                    key: serverEntry.server,
                    children: children,
                    icon: "mif-server",
                });
            }
            return result;
        },
        processTreeChilds(node, server) {
            node.server = server;
            if (node.children && node.children.length > 0) {
                for (const child of node.children) {
                    this.processTreeChilds(child, server);
                }
            }
        },
        getParameterArray() {
            if (Array.isArray(this.ioDialogData.data) && this.ioDialogData.data.length > 0) {
                let _opcSel = {};
                let _opcExp = {};
                this.opcTreeSelectedNodes = [ ...this.ioDialogData.data ];

                this.opcTreeSelectedNodes.forEach((node) => {
                    const splitted = splitNodeId(node.key);
                    const ns = splitted.ns;
                    const root = splitted.root;
                    const application = splitted.application;
                    const program = splitted.program;
                    if (root !== undefined) {
                        if (application !== undefined) _opcExp[`${combineNodeId(ns, root + '.' + application)}`] = true;
                        if (program !== undefined) _opcExp[`${combineNodeId(ns, root + '.' + application + '.' + program)}`] = true;
                        if (node.parent !== undefined) _opcExp[`${node.parent}`] = true;
                    }
                    if (node.key !== undefined) {
                        _opcSel[node.key] = {checked: true, partialChecked: false};
                        console.log(_opcSel);
                    }
                });
                this.opcTreeSelection = { ..._opcSel };
                this.opcTreeExpandedKeys = { ..._opcExp };
            }
        },
        onOpcTreeSelect(node) {
            this.opcTreeSelectedNodes.push(node);
            // this.opcTreeSelection.forEach((selection) => {
            //     if (selection.checked && !selection.partialChecked) {
            //         console.log(selection);
            //     }
            // });
            console.log(this.opcTreeSelection);
            this.opcTreeSelectedNode = { ...node };
            this.opcTreeSelectedNode.scheme = node.label;
            this.opcTreeSelectedNode.key = null;
            const splittedId = splitNodeId(node.key);
            // this.ioDialogData.moduleName = splittedId.moduleName;
            // this.ioDialogData.source.server = node.server;
            // this.ioDialogData.source.ns = splittedId.ns;
            // this.ioDialogData.source.identifier = splittedId.identifier;
            // this.ioDialogData.source.root = splittedId.root;
            // this.ioDialogData.source.application = splittedId.application;
            // this.ioDialogData.source.program = splittedId.program;
            
            this.$toast.add({
                severity: "success",
                summary: node.label,
                detail: `${node.server}@${splittedId.identifier}`,
                life: 3000,
            });
        },
        onOpcTreeUnselect(node) {
            this.opcTreeSelectedNodes = this.opcTreeSelectedNodes.filter(currentNode => currentNode.key !== node.key);
            
            this.$toast.add({
                severity: "success",
                summary: node.label,
                detail: `${node.server}@${node.key}`,
                life: 3000,
            });
        },
        splitNodeIdLocal(nodeKey) {
            return splitNodeId(nodeKey);
        },
        onReorder(node) {
            console.log(node);
        },
        onSelectionChange(node) {
            this.selectedOrderItem = node.value[0];
            console.log(this.selectedOrderItem);
        },
    },
});
</script>
