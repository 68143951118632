<template>
  <div>    
    <div class="card">
      <Toolbar class="mb-4">
        <template #start>
          <Button
            label="Server hinzufügen"
            icon="pi pi-plus"
            class="p-button-success mr-2"
            @click="openNew"
          />
          <Button
            label="Server löschen"
            icon="pi pi-trash"
            class="p-button-danger mr-2"
            @click="confirmDeleteSelected"
            :disabled="!selectedIOs || !selectedIOs.length"
          />
          <Button
            label="enatcom reload"
            icon="pi pi-refresh"
            class="p-button-info"
            @click="runServerCmd('restart')"
          />
        </template>

        <template #end>
          <FileUpload
            mode="basic"
            accept="image/*"
            :maxFileSize="1000000"
            label="Import"
            chooseLabel="Import"
            class="mr-2 d-inline-block"
          />
          <Button
            label="Export"
            icon="pi pi-upload"
            class="p-button-help"
            @click="exportCSV($event)"
          />
        </template>
      </Toolbar>

      <DataTable
        ref="dt"
        :value="getServers"
        v-model:selection="selectedIOs"
        dataKey="key"
        :loading="loading"
        :paginator="true"
        :rows="50"
        :filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 50, 100]"
        currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Einträgen"
        responsiveLayout="scroll"
      >
        <template #loading> Daten werden geladen... bitte warten </template>
        <template #header>
          <div
            class="
              table-header
              d-flex flex-column flex-md-row jc-md-between
            "
          >
            <h5 class="mb-2 m-md-0 as-md-center">OPC-UA Server</h5>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                placeholder="suchen..."
              />
            </span>
          </div>
        </template>

        <Column selectionMode="multiple" :exportable="false"></Column>
        <Column field="key" header="Key" :sortable="true"></Column>
        <Column field="label" header="Bezeichnung" :sortable="true"></Column>
        <Column field="endpoint" header="Endpoint" :sortable="true"></Column>
        <Column field="port" header="Port" :sortable="true"></Column>
        <Column field="secureConnection" header="Secure Connection" sortable>
          <template #body="slotProps">
            <Tag :value="slotProps.data.secureConnection ? 'SSL/443' : 'HTTP/80'" :severity="slotProps.data.secureConnection ? 'success' : 'warning'" />
          </template>
        </Column>
        <Column field="recordEnable" header="Timeseries" sortable>
          <template #body="slotProps">
            <Tag :value="slotProps.data.recordEnable ? 'RECORD' : 'OFF'" :severity="slotProps.data.recordEnable ? 'success' : 'warning'" />
          </template>
        </Column>

        <Column :exportable="false" style="min-width: 8rem">
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success mr-2"
              @click="editIO(slotProps.data)"
            />
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-warning"
              @click="confirmDeleteIO(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>

    <Dialog
      v-model:visible="ioDialog"
      header="OPC-UA Server bearbeiten"
      :modal="true"
      class="p-fluid"
      @hide="hideDialog"
    >
      <div class="field">
        <label for="key">Key (gültig für alle Redis und OPC Events!)</label>
        <InputText
          id="key"
          v-model.trim="ioDialogData.key"
          required="true"
          autofocus
          :class="{ 'p-invalid': submitted && !ioDialogData.key }"
        />
        <small class="p-error" v-if="submitted && !ioDialogData.key"
          >Key <b>muss</b> ausgefüllt werden!</small
        >
      </div>
      <div class="formgrid grid">
        <div class="field col-8">
            <label for="key">UUID</label>
            <InputText id="uuid" v-model.trim="ioDialogData.uuid" required="true" readonly :disabled="true" :class="{ 'p-invalid': submitted && !ioDialogData.uuid }" />
            <small class="p-error" v-if="submitted && !ioDialogData.uuid">UUID <b>muss</b> ausgefüllt werden!</small>
        </div>
        <div class="field col-4">
          <label for="key">Neue UUID erstellen</label>
          <Button class="block" @click="generateUUID">generieren</Button>
        </div>
      </div>
      <div class="field">
        <label for="label">Bezeichnung</label>
        <Textarea
          id="desc"
          v-model="ioDialogData.label"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <div class="field">
        <div class="formgrid grid">
          <div class="field col-9">
            <label for="endpoint">Endpoint</label>
            <InputText
              id="endpoint"
              v-model.trim="ioDialogData.endpoint"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !ioDialogData.endpoint }"
            />
            <small class="p-error" v-if="submitted && !ioDialogData.endpoint"
              >Endpoint <b>muss</b> ausgefüllt werden!</small
            >
          </div>
          <div class="field col-3">
            <label for="port">Port</label>
            <InputText
              id="port"
              v-model.trim="ioDialogData.port"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !ioDialogData.port }"
            />
            <small class="p-error" v-if="submitted && !ioDialogData.port"
              >Port <b>muss</b> ausgefüllt werden!</small
            >
          </div>
        </div>
      </div>

      <div class="field">
        <div class="formgrid grid">
          <div class="field col-6">
            <label for="opcuser">OPC-User</label>
            <InputText
              id="opcuser"
              v-model.trim="ioDialogData.user"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !ioDialogData.user }"
            />
            <small class="p-error" v-if="submitted && !ioDialogData.user"
              >OPC-User <b>muss</b> ausgefüllt werden!</small
            >
          </div>
          <div class="field col-6">
            <label for="opcpass">OPC-Passwort</label>
            <InputText
              id="opcpass"
              v-model.trim="ioDialogData.password"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !ioDialogData.password }"
            />
            <small class="p-error" v-if="submitted && !ioDialogData.password"
              >Endpoint <b>muss</b> ausgefüllt werden!</small
            >
          </div>
        </div>
      </div>
      <div class="field">
          <label class="mb-3">Verbindungstyp</label>
          <div class="formgrid grid">
            <div class="field-radiobutton col-6">
              <RadioButton
                id="secureConnection"
                name="isSecure"
                :value="true"
                v-model="ioDialogData.secureConnection"
              />
              <label for="secureConnection">Verschlüsselt/Auth</label>
            </div>
            <div class="field-radiobutton col-6">
              <RadioButton
                id="normalConnection"
                name="isSecure"
                :value="false"
                v-model="ioDialogData.secureConnection"
              />
              <label for="normalConnection">Unverschlüsselt/no User</label>
            </div>
        </div>
      </div>
      <div class="field">
        <label for="rootNode">Rootnode für Browse</label>
        <InputText
          id="rootNode"
          v-model.trim="ioDialogData.rootNode"
          required="true"
          autofocus
          :class="{ 'p-invalid': submitted && !ioDialogData.rootNode }"
        />
        <small class="p-error" v-if="submitted && !ioDialogData.rootNode"
          >RootNode <b>muss</b> ausgefüllt werden!</small
        >
      </div>
      <div class="field">
          <label class="mb-3 mt-3">Server beim Start von <b>enatcom</b> scannen?</label>
          <div class="formgrid grid">
            <div class="field-radiobutton col-6">
              <RadioButton
                id="browseOnStartTrue"
                name="browseOnStartTrueField"
                :value="true"
                v-model="ioDialogData.browseOnStart"
              />
              <label for="secureConnection">Browse aktiv</label>
            </div>
            <div class="field-radiobutton col-6">
              <RadioButton
                id="browseOnStartFalse"
                name="browseOnStartFalseField"
                :value="false"
                v-model="ioDialogData.browseOnStart"
              />
              <label for="normalConnection">Browse inaktiv</label>
            </div>
        </div>
      </div>
      <div class="field">
        <div class="formgrid grid">
          <div class="field col-6">
            <label for="userWrite">Subscription Intervall</label>
            <div class="p-inputgroup flex-1">
              <InputText
                id="subInterval"
                v-model.trim="ioDialogData.subscriptionInterval"
                required="true"
                autofocus
                :class="{ 'p-invalid': submitted && !ioDialogData.subscriptionInterval }"
              />
              <span class="p-inputgroup-addon">ms</span>
            </div>
            <small class="p-error" v-if="submitted && !ioDialogData.subscriptionInterval"
              >Subscription Intervall <b>muss</b> ausgefüllt werden!</small
            >
          </div>
          <div class="field col-6">
            <label for="userWrite">Maximal gleichzeitige Requests</label>
            <InputText
              id="maxRequests"
              v-model.trim="ioDialogData.maxRequests"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !ioDialogData.maxRequests }"
            />
            <small class="p-error" v-if="submitted && !ioDialogData.maxRequests"
              >max. Requests <b>muss</b> ausgefüllt werden!</small
            >
          </div>
        </div>
      </div>
      <div class="field">
        <div class="formgrid grid">
          <div class="field col-4">
            <label for="recInterval">Record Intervall</label>
            <div class="p-inputgroup flex-1">
              <InputText
                id="recInterval"
                v-model.trim="ioDialogData.recordTime"
                required="true"
                autofocus
                :class="{ 'p-invalid': submitted && !ioDialogData.recordTime }"
              />
              <span class="p-inputgroup-addon">ms</span>
              <small class="p-error" v-if="submitted && !ioDialogData.recordTime"
                >Record Intervall <b>muss</b> ausgefüllt werden!</small
              >
            </div>
          </div>
          <div class="field-radiobutton col-4">
              <RadioButton
                id="recTrue"
                name="isSecure"
                :value="true"
                v-model="ioDialogData.recordEnable"
              />
              <label for="recTrue">Aufzeichnung aktivieren</label>
            </div>
            <div class="field-radiobutton col-4">
              <RadioButton
                id="recFalse"
                name="isSecure"
                :value="false"
                v-model="ioDialogData.recordEnable"
              />
              <label for="recFalse">Aufzeichnung deaktivieren</label>
            </div>
        </div>
      </div>

      <template #footer>
        <Button
          label="Abbrechen"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideDialog"
        />
        <Button
          label="Speichern"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveIO"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="deleteIoDialog"
      :style="{ width: '450px' }"
      header="Bestätigung"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="ioDialogData"
          >Soll der Eintrag wirklich aus der Datenbank gelöscht werden: <br />
          <span style="font-family:LogFileFont; color: yellow;">{{ ioDialogData.label }}</span> ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Nein"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteIoDialog = false"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteIO"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="deleteIosDialog"
      :style="{ width: '450px' }"
      header="Bestätigung"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="ioDialogData"
          >Sollen die Einträge wirklich aus der Datenbank gelöscht werden?</span
        >
      </div>
      <template #footer>
        <Button
          label="Nein"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteIosDialog = false"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedIOs"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref } from 'vue';
import { mapGetters } from "vuex";
import { findIndexByKey, mongoResponseToast } from "@/helpers";
import { FilterMatchMode } from "primevue/api";
import { uuid } from 'vue-uuid';
// import ProductService from "../helpers";
// import mainService from "@/services/mainService.js";
import SocketioService from "@/services/socketioService.js";

export default {
  name: "opcConfig",
  data() {
    return {
      loading: false,
      ioDialog: false,
      deleteIoDialog: false,
      deleteIosDialog: false,
      ioDialogData: {},
      config_io: null,
      io_type: null,
      selectedIOs: null,
      filters: {},
      submitted: false,
      intval_Value: null,
      elementIndex: -1,
      options: ref([
        { name: 'HTTP', value: false },
        { name: 'HTTPS', value: true }
      ]),
    };
  },
  created() {
    this.initFilters();
    this.$store.dispatch('opcua/loadOpcuaServers');
  },
  mounted() {
  },
  beforeUnmount() {
    this.ioDialogData = null;
    this.filters = null,
    this.selectedIOs = null;
    this.submitted = null;
  },
  computed: {
    ...mapGetters({
      getServers: 'opcua/getServers',
      getServersId: 'opcua/getServersId'
    }),
  },
  watch: {
    getServers: {
      handler: function () {
        if (this.getServers) {
          this.loading = false;
        } else {
          this.loading = true;
        }
      }
    }
  },
  methods: {
    resetDialogData() {
      this.ioDialogData = {
        // group: {},
      };
      this.elementIndex = -1;
    },
    getPageData() {
      this.$store.dispatch('opcua/loadOpcuaServers', true);
    },
    openNew() {
      this.resetDialogData();
      this.submitted = false;
      this.ioDialog = true;
    },
    hideDialog() {
      this.ioDialog = false;
      this.submitted = false;
      // clearInterval(this.intval_Value);
    },
    saveIO() {
      this.submitted = true;

      if (this.ioDialogData.key.trim()) {
        const newObj = [ ... this.getServers ];
        if (this.elementIndex >= 0) {
          newObj[this.elementIndex] = { ...this.ioDialogData };
        } else {
          const elementIndex = findIndexByKey(this.getServers, this.ioDialogData.key);
          if (elementIndex >= 0) {
            newObj[elementIndex] = this.ioDialogData;
          } else {
            newObj.push(this.ioDialogData);
          }
        }
        this.loading = true;
        const data = {
          _id: this.getServersId,
          data: newObj
        }
        SocketioService.setOpcuaServers(data, (err, response) => {
          if (!err && response !== null) {
            this.loading = false;
            const result = mongoResponseToast(response, this.$root);
            if (result) this.getPageData();
          }
        });
        this.ioDialog = false;
        this.resetDialogData();
      }
    },
    editIO(ioData) {
      const _obj = JSON.parse(JSON.stringify(ioData));
      this.ioDialogData = _obj;
      this.elementIndex = findIndexByKey(this.getServers, this.ioDialogData.key);
      console.log(this.elementIndex);
      console.log(this.ioDialogData);
      this.ioDialog = true;
    },
    generateUUID() {
      this.ioDialogData.uuid = uuid.v4();
    },
    confirmDeleteIO(ioData) {
      const _obj = JSON.parse(JSON.stringify(ioData));
      this.ioDialogData = _obj;
      this.deleteIoDialog = true;
    },
    deleteIO() {
      const newObj = [ ... this.getServers ];
      const index = findIndexByKey(newObj, this.ioDialogData.key);
      if (index >= 0) {
        newObj.splice(index, 1);
        console.log(newObj);
        this.loading = true;
        const data = {
          _id: this.getServersId,
          data: newObj
        }
        SocketioService.setOpcuaServers(data, (err, response) => {
          if (!err && response !== null) {
            this.loading = false;
            const result = mongoResponseToast(response, this.$root);
            if (result) this.getPageData();
          }
        });
      }
      this.deleteIoDialog = false;
      this.resetDialogData();
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    runServerCmd(cmd) {
      if (cmd && typeof(cmd === 'string')) {
        this.loading = true;
        SocketioService.runServerCmd(cmd, (err, response) => {
          if (!err && response !== null) {
            this.loading = false;
          }
        });
      }
    },
    confirmDeleteSelected() {
      this.deleteIosDialog = true;
    },
    deleteSelectedIOs() {
      if (this.selectedIOs.length > 0) {
        const newObj = [ ... this.getServers ];
        const newArr = newObj.filter((obj) => {
          return !this.selectedIOs.some((delObj) => {
            return obj.key === delObj.key;
          });
        });
        this.loading = true;
        const data = {
          _id: this.getServersId,
          data: newArr
        }
        SocketioService.setOpcuaServers(data, (err, response) => {
          if (!err && response !== null) {
            this.loading = false;
            const result = mongoResponseToast(response, this.$root);
            if (result) this.getPageData();
          }
        });
      }
      this.deleteIosDialog = false;
      this.selectedIOs = null;
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style lang="scss" scoped>

</style>