// import { createStore } from 'vuex'
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate'
import socket from "./modules/socket";
import types from "./modules/types";
import users from "./modules/users";
import auth from "./modules/auth";
import opcua from "./modules/opcua";
// import io from './modules/io'
import SocketioService from '@/services/socketioService';
// import SocketioService_ from '@/services/socketioService_';
import axios from 'axios'
import { 
  findByUuid,
  scaleVal2,
  scaleValSingle,
  processValues,
  scaleMapSingle,
  getWindowUrl
} from '@/helpers.js'
import _ from 'lodash';
// import EventBus from '@/event-bus';

// const socketWorker = new Worker("@/worker.js", { type: "module" });

// function getMapVal(state, uuid) {
//   let result = null;
//   let valResult = null;
//   result = state.nodes.map.get(uuid);
//   valResult = state.nodes.valueMap.get(uuid);
//   if (!valResult) {
//     if (result) {
//       valResult = result.value;
//     } else {
//       valResult = '???';
//     }
//   }
//   if (result) {
//     return {...result, value: valResult};
//   }
//   result = { data: null, decimal: 1, factor: 10, icon: null, key: null, label: '👻', parameter: '👻', unit: '👻', uuid: '👻', value: '🤷🏼‍♂️' };
//   return result;
// }

const getDefaultState = () => {
  return {
    status: null,
    nums: null,
    working: false,
    // token: '',
    token: localStorage.getItem('token') || '',
    user: null,
    url: {},
    path: {
      name: null,
      label: null,
      path: null,
    },
    appLoading: false,
    loading: 0,
    loadingTimeout: null,
    standby: null,
    alarmCount: 0,
    systemStatus: "???",
    allParam: {},
    allParamContext: {},
    allParam2: {},
    systemLog: [],
    values: [],
    dateTime: "???",
    pageData: {
      editMode: false,
      isEdit: false,
      name: null,
      label: null,
      path: null,
    },
    menu: {
      interface: [],
    },
    socketIo: {
      working: false,
      error: false,
      socket: null,
      lastEvent: null,
    },
    activeConnections: {
      server: {
        connections: null,
        startDate: null,
        endDate: null
      }
    },
    clientData: {
      copyright: {
        versionInfo: null,
        copyrightYear: null,
        copyrightTxt: null,
      },
      installation: {
        customer: {
          name: null,
          desc: null,
          mail: null,
          id: null,
        },
        location: {
          address: null,
          city: null,
          zip: null,
          country: null,
          timezone: null,
          longitude: null,
          latitude: null,
          height: null
        },
      },
      app: {
        label: null,
        config: {
          standbyTimeout: 600,
        },
      }
    },
    globalLoader: {
      loading: false,
      title: null,
      init: false,
    },
    nodes: {
      ids: [],
      widgets: {},
      categoryTree: [],
      categoryTree2: [],
      categoryTree3: [],
      map: null,
      valueMap: null,
      mapLoaded: false,
      departments: null,
    },
    configIo: {
      types_unit: [],
      types_io: [],
      types_category: [],
      types_department: [],
      types_server: [],
      types_icons: [],
    },
    configObjects: {},
    // statusIo: {},
    statusIo: [],
    ioTree: {},
    alarmLog: [],
    style: {
      colors: [
        { label: "Standard", value: "standard" },
        { label: "Weiß", value: "white" },
        { label: "Schwarz", value: "black" },
        { label: "Neo Grau", value: "neoGray"},
        { label: "Neo Grau hell", value: "lightNeoGray"},
        { label: "Bespin", value: "bespin"},
        { label: "Bespin hell", value: "lightBespin"},
        { label: "Grün", value: "green" },
        { label: "Grün dunkel", value: "darkGreen" },
        { label: "Grün hell", value: "lightGreen" },
        { label: "Rot", value: "red" },
        { label: "Rot dunkel", value: "darkRed" },
        { label: "Rot hell", value: "lightRed" },
        { label: "Blau", value: "blue" },
        { label: "Blau dunkel", value: "darkBlue" },
        { label: "Blau hell", value: "lightBlue" },
        { label: "Gelb", value: "yellow" },
        { label: "Gelb dunkel", value: "darkYellow" },
        { label: "Gelb hell", value: "lightYellow" },
        { label: "Lime", value: "lime" },
        { label: "Lime dunkel", value: "darkLime" },
        { label: "Lime hell", value: "lightLime" },
        { label: "Emerald", value: "emerald" },
        { label: "Emerald dunkel", value: "darkEmerald" },
        { label: "Emerald hell", value: "lightEmerald" },
        { label: "Teal", value: "teal" },
        { label: "Teal dunkel", value: "darkTeal" },
        { label: "Teal hell", value: "lightTeal" },
        { label: "Cobalt", value: "cobalt" },
        { label: "Cobalt dunkel", value: "darkCobalt" },
        { label: "Cobalt light", value: "lightCobalt" },
        { label: "Pink", value: "pink" },
        { label: "Pink dunkel", value: "darkPink" },
        { label: "Pink hell", value: "lightPink" },
        { label: "Magenta", value: "magenta" },
        { label: "Magenta dunkel", value: "darkMagenta" },
        { label: "Magenta hell", value: "lightMagenta" },
        { label: "Indigo", value: "indigo" },
        { label: "Indigo dunkel", value: "darkIndigo" },
        { label: "Indigo hell", value: "lightIndigo" },
        { label: "Amber", value: "amber" },
        { label: "Amber dunkel", value: "darkAmber" },
        { label: "Amber hell", value: "lightAmber" },
        { label: "Orange", value: "orange" },
        { label: "Orange dunkel", value: "darkOrange" },
        { label: "Orange hell", value: "lightOrange" },
        { label: "Crimson", value: "crimson" },
        { label: "Crimson dunkel", value: "darkCrimson" },
        { label: "Crimson hell", value: "lightCrimson" },
        { label: "Grau", value: "gray" },
        { label: "Grau dunkel", value: "darkGray" },
        { label: "Grau hell", value: "lightGray" },
        { label: "Graublau", value: "grayBlue" },
        { label: "Graublau dunkel", value: "darkGrayBlue" },
        { label: "Graublau hell", value: "lightGrayBlue" },
        { label: "Stahl", value: "steel" },
        { label: "Stahl dunkel", value: "darkSteel" },
        { label: "Stahl hell", value: "lightSteel" },
        { label: "Olive", value: "olive" },
        { label: "Olive dunkel", value: "darkOlive" },
        { label: "Olive hell", value: "lightOlive" },
        { label: "Braun", value: "brown" },
        { label: "Braun dunkel", value: "darkBrown" },
        { label: "Braun hell", value: "lightBrown" },
        { label: "Mauve", value: "mauve" },
        { label: "Mauve dunkel", value: "darkMauve" },
        { label: "Mauve hell", value: "lightMauve" },
        { label: "Sand", value: "taupe" },
        { label: "Sand dunkel", value: "darkTaupe" },
        { label: "Sand hell", value: "lightTaupe" },
      ],
      patterns: [
        { label: "Keines", value: "none" },
        { label: "Dotted", value: "dotted" },
        { label: "Dotted groß", value: "dotted-lg" },
        { label: "Linien horizontal", value: "hlines" },
        { label: "Linien vertikal", value: "vlines" },
        { label: "Raster", value: "grid" },
        { label: "Raster klein", value: "grid-sm" },
        { label: "Dashed vertikal", value: "vdashed" },
        { label: "Dashed", value: "dashed" },
        { label: "Hexagon", value: "hexagon" },
        { label: "Signal", value: "signal" },
        { label: "Deathstar", value: "deathstar" },
        { label: "Schaltkreis", value: "circuit" },
      ],
      templates: [
        { label: "Standard", value: "standard" },
        { label: "Compact", value: "compact" },
      ],
      chartType: [
        { label: "Line", value: "line" },
        { label: "Area", value: "area" },
        { label: "Bar", value: "bar" },
        { label: "Box plot", value: "boxPlot" },
        { label: "Treemap", value: "treemap" },
        { label: "Donut", value: "donut" },
        { label: "Radial Bar", value: "radialBar" },
      ],
      typo: {
        noval: '\u26a0',
      }
    },
    controllers: {
      timeTypeSelect: [
        {name: 'Sonnenaufgang', icon: 'fi fi-rr-sunrise-alt', value: 1},
        {name: 'Fixe Zeit', icon: 'fi fi-rr-clock', value: 0},
        {name: 'Sonnenuntergang', icon: 'fi fi-rr-sunset', value: 2},
        //{name: 'Universalzeit 1', icon: 'fi fi-rr-dice-one', value: 3},
        //{name: 'Universalzeit 2', icon: 'fi fi-rr-dice-two', value: 4},
      ],
      timeTypeSelectPlusMinus: [
          {name: 'Nach Ereignis', icon: 'fi fi-rr-plus', value: 0},
          {name: 'Vor Ereignis', icon: 'fi fi-rr-minus', value: 1}
      ],
      alarmPrior: [
        {name: 'Kein Alarm [0]', icon: 'fi fi-rr-ban', value: 0},
        {name: 'Stiller Alarm [1]', icon: 'fi fi-rr-browser', value: 1},
        {name: 'Scharfer Alarm [2]', icon: 'fi fi-rr-bell-school', value: 2},
        {name: 'Scharfer Alarm mit Verriegelung [3]', icon: 'fi fi-rr-ambulance', value: 3},
      ],
    },
    ioConfig: {
      objectReadWrite: [
        {name: 'Nur lesen', icon: 'fi fi-rr-sunrise-alt', value: "read"},
        {name: 'Lesen + schreiben', icon: 'fi fi-rr-clock', value: "rw"},
        {name: 'Array', icon: 'fi fi-rr-clock', value: "array"}
      ],
      factor: [
        { label: "1", value: 1 },
        { label: "10", value: 10 },
        { label: "100", value: 100 },
        { label: "1000", value: 1000 },
        { label: "10000", value: 10000 },
        { label: "100000", value: 100000 },
        { label: "1000000", value: 100000 },
      ],
      decimal: [
        { label: "0", value: 0 },
        { label: "0.1", value: 1 },
        { label: "0.12", value: 2 },
        { label: "0.123", value: 3 },
        { label: "0.1234", value: 4 },
        { label: "0.12345", value: 5 },
        { label: "0.123456", value: 6 },
      ],
    },
    weatherWarnings: {
      location: '',
      warnings: [{
        properties: {
          text: '',
          begin: '',
          end: ''
        }
      }],
    },
  };
};

// export default createStore({
const store = new Vuex.Store({
  modules: {
    auth,
    socket,
    types,
    users,
    opcua
  },
  strict: true,
  plugins: [createPersistedState({
    storage: window.sessionStorage,
    paths: ['style', 'user', 'systemStatus', 'dateTime'],
  })],
  state: getDefaultState(),
  getters: {
    isLoggedIn: state => {
      return !!state.token;
    },
    authStatus: state => {
      return state.status;
    },
    getAppLoading: state => {
      return state.globalLoader.loading;
    },
    isLoading: state => {
      return state.loading;
    },
    getStandby: state => {
      return state.standby;
    },
    getUrl: state => {
      return state.url;
    },
    getPath: state => {
      return state.path;
    },
    getUser: state => {
      return state.user;
    },
    currentTimeFormatted: (state, getters, rootState, rootGetters) => {
      return rootGetters['socket/currentTimeFormatted'];
    },
    startTimeFormatted: (state, getters, rootState, rootGetters) => {
      return rootGetters['socket/startTimeFormatted'];
    },
    runTimeFormatted: (state, getters, rootState, rootGetters) => {
      return rootGetters['socket/runTimeFormatted'];
    },
    connectionCount: (state, getters, rootState, rootGetters) => {
      return rootGetters['socket/connectionCount'];
    },
    sessionId: (state, getters, rootState, rootGetters) => {
      return rootGetters['socket/sessionId'];
    },
    clientName: (state, getters, rootState, rootGetters) => {
      return rootGetters['socket/clientName'];
    },
    clientDesc: (state, getters, rootState, rootGetters) => {
      return rootGetters['socket/clientDesc'];
    },
    clientId: (state, getters, rootState, rootGetters) => {
      return rootGetters['socket/clientId'];
    },
    clientEmail: (state, getters, rootState, rootGetters) => {
      return rootGetters['socket/clientEmail'];
    },
    systemLabel: (state, getters, rootSetters, rootGetters) => {
      return rootGetters['socket/systemLabel'];
    },
    versionInfo: (state, getters, rootSetters, rootGetters) => {
      return rootGetters['socket/versionInfo'];
    },
    version: (state, getters, rootSetters, rootGetters) => {
      return rootGetters['socket/version'];
    },
    copyrightTxt: (state, getters, rootSetters, rootGetters) => {
      return rootGetters['socket/copyrightTxt'];
    },
    copyrightYear: (state, getters, rootSetters, rootGetters) => {
      return rootGetters['socket/copyrightYear'];
    },
    copyrightLink: (state, getters, rootSetters, rootGetters) => {
      return rootGetters['socket/copyrightLink'];
    },
    viewMenu: (state, getters, rootSetters, rootGetters) => {
      return rootGetters['socket/viewMenu'];
    },
    changeLog: (state, getters, rootSetters, rootGetters) => {
      return rootGetters['socket/changeLog'];
    },
    getAppSetup: (state, getters, rootSetters, rootGetters) => {
      return rootGetters['socket/getAppSetup'];
    },
    getMenuInterface: state => {
      return state.menu.interface;
    },
    getGlobalLoader: state => {
      return state.globalLoader;
    },
    getAlarm: state => {
      return state.alarmCount;
    },
    getSystemStatus: state => {
      if (state.systemStatus === 'online') return 'verbunden';
      else return 'getrennt';
    },
    getSystemStatusBool: state => {
      if (state.systemStatus === 'online') return true;
      else return false;
    },
    getParameterList: state => {
      return state.allParam;
    },
    getParameterList2: state => {
      return state.allParam2;
    },
    getParameterListContext: state => {
      return state.allParamContext;
    },
    getSystemLog: state => {
      return state.systemLog;
    },
    getConfigIo: state => {
      return state.configIo;
    },
    getParameterObjects: state => {
      return state.configObjects;
    },
    getStatusIo: state => {
      return state.statusIo;
    },
    // getValues: state => {
    //   return state.values;
    // },
    // getValue: (state) => (id) => {
    //   let node = state.values.find(x => x._id === id);
    //   if (node !== undefined) {
    //     return node;
    //   } else {
    //     return { _id: id, metadata: { name: '👻' }, value: { value: '🤷🏼‍♂️', unit: '👻' } };
    //   }
    // },
    getValues: state => {
      // return state.nodes.widgets;
      // console.log(">>> ACCESSING STATE NODE ALL WIDGETS:", state.nodes.ids.map( widgetId => state.nodes.widgets[widgetId] ));
      return state.nodes.ids.map( widgetId => state.nodes.widgets[widgetId] );
    },
    getDepartment: (state) => (department) => {
      if (state.nodes.departments) {
        return state.nodes.departments.get(department);
      } else {
        return '👻';
      }
    },
    getValue: (state) => (id) => {
      if (state.nodes.widgets[id]) {
        // console.log(">>> ACCESSING STATE NODE WIDGETS:", state.nodes.widgets[id]);
        return state.nodes.widgets[id];
      } else {
        return { _id: null, metadata: { name: '\u26a0' }, value: { value: '\u26a0', unit: '\u26a0' } };
        // return { _id: id, metadata: { name: '👻' }, value: { value: '🤷🏼‍♂️', unit: '👻' } };
      }
    },
    getNodeWidget: (state) => (id) => {
      if (state.nodes.widgets[id]) {
        // console.log(">>> ACCESSING STATE NODE WIDGETS:", state.nodes.widgets[id]);
        return state.nodes.widgets[id];
      } else {
        return { _id: null, metadata: { name: '👻' }, value: { value: '🤷🏼‍♂️', unit: '👻' } };
        // return { _id: id, metadata: { name: '👻' }, value: { value: '🤷🏼‍♂️', unit: '👻' } };
      }
    },
    getValueByUuid: (state) => (uuid) => {
      const node = findByUuid(state.nodes.ids.map( widgetId => state.nodes.widgets[widgetId] ), uuid);
      return node;
      // if (state.nodes.widgets[id]) {
      //   // console.log(">>> ACCESSING STATE NODE WIDGETS:", state.nodes.widgets[id]);
      //   return state.nodes.widgets[id];
      // } else {
      //   return { _id: null, metadata: { name: '👻' }, value: { value: '🤷🏼‍♂️', unit: '👻' } };
      //   // return { _id: id, metadata: { name: '👻' }, value: { value: '🤷🏼‍♂️', unit: '👻' } };
      // }
    },
    getMapValues: state => {
      return state.nodes.map;
    },
    getMapValue: (state) => (uuid) => {
      // return getMapVal(state, uuid);
      let result = null;
      let valResult = null;
      result = state.nodes.map.get(uuid);
      valResult = state.nodes.valueMap.get(uuid);
      if (!valResult) {
        if (result) {
          valResult = result.value;
        } else {
          valResult = '???';
        }
      }
      if (result) {
        return {...result, value: valResult};
      }
      result = { data: null, decimal: 1, factor: 10, icon: null, key: null, label: '👻', parameter: '👻', unit: '👻', uuid: '👻', value: '🤷🏼‍♂️' };
      return result;
    },
    // getValue: (state) => (id) => {
    //   // let node = state.values.find(x => x._id === id);
    //   // return node;
    //   let node = state.values.find(x => x._id === id);
    //   // console.log(node);
    //   if (node !== undefined) {
    //     return node;
    //   } else {
    //     SocketioService.sendEvent({
    //       type: 'getValue',
    //       data: id
    //     }, (response => {
    //       console.log(response);
    //       state.values.push(response);
    //     }));
    //     return { _id: id, metadata: {name: '???'}, value: {value: '???', unit: '???'} };
    //   }
    // },
    getDateTime: state => {
      return state.dateTime;
    },
    getActiveConnections: state => {
      return state.activeConnections;
    },
    getPageData: state => {
      return state.pageData;
    },
    getEditMode: state => {
      return state.pageData.editMode;
    },
    getClientData: state => {
      return state.clientData;
    },
    // getIoTree: state => () => {
    //   console.log(">>> IO TREE:", state.ioTree);
    //   return state.ioTree;
    // },
    getIoTree: state => {
      return state.nodes.categoryTree2;
    },
    getAlarmLog: state => () => {
      return state.alarmLog;
    },
    getStyleColors: state => {
      return state.style.colors;
    },
    getStylePatterns: state => {
      return state.style.patterns;
    },
    getStyleTemplates: state => {
      return state.style.templates;
    },
    getChartTypes: state => {
      return state.style.chartType;
    },
    getNoval: state => {
      return state.style.typo.noval;
    },
    getTimeType: state => {
      return state.controllers.timeTypeSelect;
    },
    getTimeTypePlusMinus: state => {
      return state.controllers.timeTypeSelectPlusMinus;
    },
    getAlarmPrior: state => {
      return state.controllers.alarmPrior;
    },
    getObjectReadWrite: state => {
      return state.ioConfig.objectReadWrite;
    },
    getObjectFactor: state => {
      return state.ioConfig.factor;
    },
    getObjectDecimal: state => {
      return state.ioConfig.decimal;
    },
    getWeatherWarnings: state => {
      return state.weatherWarnings;
    }
  },
  mutations: {
    AUTH_REQUEST(state) {
      state.status = 'loading';
    },
    AUTH_SUCCESS(state, {token, user}) {
      state.status = 'success';
      state.token   = token;
      state.user    = user;
    },
    AUTH_ERROR(state) {
      state.status = 'error';
    },
    AUTH_LOGOUT(state) {
      state.status = '';
      state.token = '';
      state.user = null;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_APP_LOADING: (state, loading) => {
      if (loading) {
        state.globalLoader.loading = true;
      } else {
        state.globalLoader.loading = false;
      }
    },
    SET_LOADING: (state, loading) => {
      if (loading !== 0) {
        state.loading += loading;
      } else {
        state.loading = 0;
      }
      // console.log(state.loading);
    },
    SET_STANDBY: (state, standby) => {
      state.standby = standby;
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_URL: (state, url) => {
      state.url = url;
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    },
    SET_PATH: (state, path) => {
      state.path = path;
    },
    SET_ALARMCOUNT: (state, count) => {
      state.alarmCount = count;
    },
    SET_SYSSTAT: (state, sysStat) => {
      state.systemStatus = sysStat;
    },
    SET_PARAMETERLIST: (state, param) => {
      state.allParam = param;
    },
    SET_PARAMETERLIST2: (state, param) => {
      state.allParam2 = param;
    },
    SET_PARAMETERLIST_CONTEXT: (state, param) => {
      state.allParamContext = param;
    },
    SET_SYSTEM_LOG: (state, log) => {
      state.systemLog = log;
    },
    SET_STATUS_IO: (state, ioList) => {
      state.statusIo = ioList;
      console.log(ioList);
    },
    SET_EVENT_VALUE: (state, value) => {
      // console.log(value);
      var index = state.values.findIndex(x => x._id === value._id);
      // console.log(index, value);
      if (index === -1) {
        console.log(value._id);
        // const date = new Date(value.timestamp);
        // const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        // date.toLocaleDateString('de-DE', options);
        // value.timestamp = date;
        // state.values.push(value);
      } else {
        // const date = new Date(value.timestamp);
        // const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        // date.toLocaleDateString('de-DE', options);
        // value.timestamp = date;
        // state.values[index] = value;
        state.values[index].value.value = value.value;
      }
      // state.values = value;
    },
    SET_VALUES: (state, value) => {
      // console.log(value);
      var index = state.values.findIndex(x => x._id === value._id);
      // console.log(index, value);
      if (index === -1) {
        const date = new Date(value.timestamp);
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        date.toLocaleDateString('de-DE', options);
        value.timestamp = date;
        if (value.source.parameter !== undefined && value.source.parameter !== "") {
          scaleVal2(value.children, null);
        }
        // if (typeof value.children == 'object' || Array.isArray(value.children)) {
        //   console.log("SETVAL");
        //   helpers.scaleVal(value.children, null);
        // }
        state.values.push(value);
      } else {
        const date = new Date(value.timestamp);
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        date.toLocaleDateString('de-DE', options);
        value.timestamp = date;
        // if (value.source.parameter !== undefined && value.source.parameter !== "") {
        if (value.source.parameter !== undefined && value.source.parameter !== "") {
          scaleVal2(value.children, null);
        }
        // if (typeof value.children == 'object' || Array.isArray(value.children)) {
        //   console.log("SETVAL");
        //   helpers.scaleVal(value.children, null);
        //   console.log(value);
        // }
        state.values[index] = value;
      }
      // console.log(index, value);
      // state.values = value;
    },
    SET_VALUES2: (state, value) => {
      // console.log("UUIDVAL: ", value);
      const node = findByUuid(state.values, value.uuid);
      scaleValSingle(node, value.value);
      // console.log(node);
      // const node = state.values.findIndex(x => x._id === value._id);
    },
    SET_NODES_IO: (state, value) => {
      //state.values = value;
      // state.values = value.widgets;
      state.nodes.ids = value.ids;
      state.nodes.widgets = value.widgets;
      // console.log(">>> NODES FROM WORKER TO VUEX:", value.ids, value.widgets);
      // console.log(">>> STATE VALUES: ", state.values);
    },
    SET_NODES_IO_NEW: (state, value) => {
      console.log(">>> NODES NEW:", value);
      value.forEach((element) => {
        const index = state.nodes.ids.indexOf(element._id);
        if (index === -1) {
          // console.log(">>> NO INDEX FOR:", element._id);
          state.nodes.ids.push(element._id);
        }
        state.nodes.widgets[element._id] = processValues(element);
      });
      // const categoryTree = helpers.groupBy(value, (c) => c.metadata.category);
      // Object.entries(categoryTree).forEach(([key, value], index) => {
      //   console.log(">>> CATEGROY-TREE ENTRY:", index, key, value);
      //   if (Array.isArray(value)) {
      //     let newArr = [];
      //     let newCat = {
      //       label: key,
      //       key: key + index,
      //       children: []
      //     };
      //     value.forEach((element, ) => {
      //       let node = null;
      //       let childs = null;
      //       if (element.children) {
      //         childs = [ ...element.children];
      //       }
      //       node = {
      //         label: element.metadata.name,
      //         key: element._id,
      //         uuid: element._id,
      //         children: childs
      //       };
      //       // newCat.children = { ...node};
      //       newArr.push(node);
      //     });
      //     if (newArr.length > 0) {
      //       newCat.children = [ ...newArr];
      //       state.nodes.categoryTree2.push(newCat);
      //     }
      //   }
      // });
      // console.log(">>> CATEGORY TREE:", categoryTree);
      // console.log(">>> CATEGORY TREE2:", state.nodes.categoryTree2);
      // state.nodes.categoryTree = categoryTree;

      // Object.(state.nodes.widgets);
      // Object.freeze(state.nodes.categoryTree);

      // state.nodes.ids = value.ids;
      // state.nodes.widgets = value.widgets;
      // console.log(">>> NODES FROM WORKER TO VUEX:", value.ids, value.widgets);
      // console.log(">>> STATE VALUES: ", state.values);
    },
    SET_NODES_MAP: (state, value) => {
      state.nodes.valueMap = new Map();
      let tempMap = new Map();
      value.forEach((element, key) => {
        scaleMapSingle(element);
        tempMap.set(key, element);
      });
      state.nodes.map = new Map(tempMap);
      tempMap = null;
      console.log(">>> NODES->MAP:", state.nodes.map);
      state.nodes.mapLoaded = true;
    },
    // SET_NODES_UUID: (state, value) => {
    //   console.log(">>> SOCKET UUID EVENT:", value);
    //   // value.forEach((element) => {
    //   //   helpers.scaleValSingle(element, element.value);
    //   //   state.nodes.map.set(element.uuid, element);
    //   // });
    //   for (let element of value) {
    //     helpers.scaleValSingle(element, element.value);
    //     state.nodes.map.set(element.uuid, element);
    //   }
    // },
    SET_NODES_UUID: (state, value) => {
      console.log(">>> SOCKET UUID EVENT:", value);
      // value.forEach((element) => {
      //   helpers.scaleValSingle(element, element.value);
      //   state.nodes.map.set(element.uuid, element);
      // });
      // let tempMap = new Map();

      // if (state.nodes.mapLoaded) {
      //   let mapEntry = null;
      //   for (let element of value) {
      //     mapEntry = {...state.nodes.map.get(element.uuid)};
      //     helpers.scaleValSingle(mapEntry, element.value);
      //     // state.nodes.map.set(element.uuid, element);
      //     if (mapEntry.value !== element.value) {
      //       state.nodes.map.set(element.uuid, {...mapEntry, value: mapEntry.value});
      //     }
      //     // tempMap.set(element.uuid, element);
      //   }
      // }
      let mapEntry = null;
      let mapValue = null;
      if (state.nodes.map !== null && state.nodes.valueMap !== null) {
        for (let element of value) {
          mapEntry = {...state.nodes.map.get(element.uuid)};
          mapValue = {...state.nodes.valueMap.get(element.uuid)};
          if (mapEntry) {
            scaleValSingle(mapEntry, element.value);
            if (mapValue !== element.value) {
              state.nodes.valueMap.set(element.uuid, mapEntry.value);
            }
          }
          // tempMap.set(element.uuid, element);
        }
      }

      // for (let [key, element] of tempMap) {
      //   console.log("ELMKEY:", key, element);
      //   state.nodes.map.set(key, element);
      // }
    },
    SET_NODES_IO_VALUES_NEW: (state, value) => {
      // let idArr = [];
      // let newNodes = {};
      console.log(">>> SOCKET VALUE EVENT:", value);
      value.forEach((element) => {
        state.nodes.widgets[element.data._id] = processValues(element.data);
        // idArr.push(element.data._id);
        // newNodes[element.data._id] = state.nodes.widgets[element.data._id];

      });
    },
    SET_NODES_IO2: (state, value) => {
      //state.values = value;
      // state.values = value.widgets;
      state.nodes.widgets = value;
      // console.log(">>> NODES FROM WORKER TO VUEX:", value.ids, value.widgets);
      // console.log(">>> STATE VALUES: ", state.values);
    },
    SET_NODES_IO_INDEX: (state, value) => {
      // console.log(">>> INDEX/VALUE:", value.index, value.value);
      state.values[value.index] = value.value;
      // console.log(">>> STATE VALUES: ", state.values);
    },
    SET_NODES_IO_INDEX2: (state, value) => {
      // console.log(">>> INDEX/VALUE:", value.index, value.value);
      state.nodes.widgets[value.index] = value.value;
    },
    SET_NODES_IO_INDEX3: (state, value) => {
      for (let i=0; i<value.arr.length; i++) {
        state.nodes.widgets[value.arr[i]] = value.widgets[value.arr[i]];
        
      }
      // const result = state.nodes.ids.map( widgetId => value[widgetId] );
      // value.arr.forEach((node) => {
      //   // console.log(">>> NODEID RESULT:::", value.widgets[node]);
      //   state.nodes.widgets[node] = value.widgets[node];
      // });
      // const result = value.arr.map( widgetId => value.widgets[widgetId] );
      // console.log(">>> NODEID RESULT:::", result);
      // value.forEach((node) => {
      //   state.nodes.widgets[node._id] = node;
      // });
    },
    SET_NODES_IO_INDEX4: (state, value) => {
      // console.log(">>> INDEX/VALUE:", value.index, value.value);
      state.nodes.widgets[value._id] = _.cloneDeep(value);
    },
    SET_CONFIG_IO: (state, config) => {
      state.configIo = config;
      // state.configIo.types_icons.sort((a, b) => a.label.localeCompare(b.label));
      state.nodes.departments = new Map();
      for (let element of config.types_department) {
        state.nodes.departments.set(element.value, element.label);
      }
      // state.values = value;
    },
    SET_CONFIG_OBJECTS: (state, config) => {
      state.configObjects = config;
      // state.values = value;
    },
    SET_IOTREE: (state, tree) => {
      state.ioTree = tree;
    },
    SET_DATETIME: (state, dateTime) => {
      state.dateTime = dateTime;
    },
    SET_MENU: (state, value) => {
      state.menu.interface = value;
      state.menu.interface.sort((a, b) => (a.index > b.index ? 1 : -1));
    },
    SET_ACTIVE_CONNECTIONS: (state, connections) => {
      state.activeConnections = connections;
    },
    SET_PAGE_DATA: (state, pageData) => {
      state.pageData = pageData;
    },
    SET_CLIENT_DATA: (state, clientData) => {
      state.clientData = clientData;
    },
    SET_ALARM_LOG: (state, log) => {
      state.alarmLog = log;
    },
    SET_GLOBAL_LOADER: (state, loader) => {
      state.globalLoader = loader;
    },
    SET_SOCKETIO_WORKING: (state, payload) => {
      state.socketIo.working = payload;
      if (payload) {
        console.log(">>> SocketIO loading...");
      } else {
        console.log(">>> SocketIO finished!");
      }
    },
    SET_SOCKETIO_SETUP: (state, payload) => {
      state.socketIo.socket = payload;
      if (payload) {
        console.log(">>> SocketIO Socket:", payload);
      }
    },
    SET_SOCKETIO_ERROR: (state, payload) => {
      state.socketIo.error = payload;
      if (payload) {
        console.log(">>> SocketIO Error:", payload);
      }
    },
    SET_SOCKETIO_EVENTS: (state, payload) => {
      state.socketIo.lastEvent = payload;
      if (payload) {
        console.log(">>> SocketIO Event:", payload);
      }
    },
    SET_SOCKETIO_EVENTS_ERROR: (state, payload) => {
      console.log(">>> SocketIO Error:", payload.message);
      if (payload) {
        if (payload.message.includes('Authentication error')) {
          console.log(">>> SocketIO AUTH ERROR!");
        }
      }
    },
    SET_NUMS: (state, payload) => {
      state.nums = payload;
      console.log(">>> DIE NUMSE:", payload);
    },
    SET_WEATHER_WARNING(state, payload) {
      console.log(`[GEOSPHERE] set weather warning:`, payload);
      console.log(`[GEOSPERE] location:`, payload.data.properties.location.properties.name);
      console.log(`[GEOSPERE] warnings:`, payload.data.properties.warnings);
      state.weatherWarnings.location = payload.data.properties.location.properties.name;
      state.weatherWarnings.warnings = [ ...payload.data.properties.warnings ];
    },
  },
  actions: {
    login: ({ commit }, user) => {
      // let url = helpers.getWindowUrl().protocol + "//" + helpers.getWindowUrl().host + ":" + 3000 + helpers.getWindowUrl().path;
      const url = "https:" + "//" + getWindowUrl().host + ":" + 3001 + getWindowUrl().path;
      console.log(url);
      // let url = "http://192.168.88.247:" + 3000 + getWindowUrl().path;
      return new Promise((resolve, reject) => {
        commit('AUTH_REQUEST')
        axios.post(url + 'login', {
          username: user.username,
          password: user.password
        })
        .then(response => {
            const token = response.data.token;
            const user  = response.data.user;
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));
            axios.defaults.headers.common['Authorization'] = "Bearer" +token;
            commit('AUTH_SUCCESS', {token, user});
            console.log(response);
            resolve(response);
        }).catch(err => { 
            commit('AUTH_ERROR');
            localStorage.removeItem('token');
            reject(err);
        })
      })
    },
    logout: ({ commit }) => {
      return new Promise((resolve) => {
        commit('AUTH_LOGOUT');
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        delete axios.defaults.headers.common['Authorization'];
        SocketioService.disconnect();
        commit('RESET', '');
        console.log("LOGOUT");
        resolve();
      });
    },
    clearStates: () => {
      SocketioService.disconnect();
    },
    setAppLoading: ({ commit }, loading) => {
      commit('SET_APP_LOADING', loading);
    },
    setLoading: ({ commit }, loading) => {
      commit('SET_LOADING', loading);
    },
    setStandby: ({ commit }, standby) => {
      commit('SET_STANDBY', standby);
      if (standby) {
        // SocketioService.removeListeners();
        SocketioService.disconnect();
      } else {
        // store.dispatch("startSocketIo", null);
        store.dispatch("startSocketIo", null).then(() => { 
          console.log(`[INIT] all done (standby)`);
          store.dispatch("setGlobalLoader", { loading: false, init: true, title: '' });
          console.log(`[INIT] initial connection phase done!`);
        });
      }
    },
    setUrl: ({ commit }, url) => {
      commit('SET_URL', url);
    },
    reset: ({ commit }) => {
      commit('RESET', '');
    },
    setPath: ({ commit }, path) => {
      commit('SET_PATH', path);
    },
    setAlarmCount: ({ commit }, count) => {
      commit('SET_ALARMCOUNT', count);
    },
    setSystemStatus: ({ commit }, sysStat) => {
      commit('SET_SYSSTAT', sysStat);
    },
    setParameterList: ({ commit }, parameterList) => {
      commit('SET_PARAMETERLIST', parameterList);
    },
    setParameterList2: ({ commit }, parameterList2) => {
      commit('SET_PARAMETERLIST2', parameterList2);
    },
    setParameterListContext: ({ commit }, parameterListContext) => {
      commit('SET_PARAMETERLIST_CONTEXT', parameterListContext);
    },
    setSystemLog: ({ commit }, systemLog) => {
      commit('SET_SYSTEM_LOG', systemLog);
    },
    setGlobalLoader: ({ commit }, loader) => {
      commit('SET_GLOBAL_LOADER', loader);
    },
    // loadStatusIo: ({ commit }) => {
    //   commit('SET_LOADING', 1);
    //   SocketioService.getServerData("status", "io", (err, response) => {
    //     console.log(response);
    //     response.forEach((element) => {
    //       commit('SET_VALUES', element);
    //     });
    //     commit('SET_LOADING', -1);
    //   });
    // },
    // loadConfigIo: ({ commit }) => {
    //   commit('SET_LOADING', 1);
    //   SocketioService.getServerData("config", "io", (err, response) => {
    //     commit('SET_CONFIG_IO', response);
    //     commit('SET_LOADING', -1);
    //   });
    // },
    loadStatusIo: ({ commit }, statusIo) => {
      statusIo.forEach((element) => {
        commit('SET_VALUES', element);
      });
    },
    loadConfigIo: ({ commit }, configIo) => {
      commit('SET_CONFIG_IO', configIo);
    },
    getValues: ({ commit, state }, id) => {
      var index = state.values.findIndex(x => x._id === id);
      if (index === -1) {
        commit('SET_LOADING', 1);
        SocketioService.sendEvent({
          type: 'getValue',
          data: id
        }, (response => {
          // console.log(response);
          // state.values.push(response);
          // state.loading += -1;
          commit('SET_LOADING', -1);
          commit('SET_VALUES', (state, response));
        }));
      }
    },
    setEventValue: ({ commit }, value) => {
      commit('SET_EVENT_VALUE', value);
    },
    setValues: ({ commit }, values) => {
      commit('SET_VALUES', values);
    },
    setValues2: ({ commit }, values) => {
      commit('SET_VALUES2', values);
    },
    setDateTime: ({ commit }, dateTime) => {
      commit('SET_DATETIME', dateTime);
    },
    setActiveConnections: ({ commit }, connections) => {
      commit('SET_ACTIVE_CONNECTIONS', connections);
    },
    setPageData: ({ commit }, pageData) => {
      commit('SET_PAGE_DATA', pageData);
    },
    setClientData: ({ commit }, clientData) => {
      commit('SET_CLIENT_DATA', clientData);
      console.log(clientData);
    },
    loadClientData: ({ commit }) => {
      commit('SET_LOADING', 1);
      SocketioService.getServerData("config", "clientData", (err, response) => {
        console.log(response);
        commit('SET_CLIENT_DATA', response);
        commit('SET_LOADING', -1);
      });
    },
    setIoTree: ({ commit }, data) => {
      // console.log("GOT IO TREE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
      // console.log(data);
      commit('SET_IOTREE', data);
      /*
      SocketioService.sendEvent({
        type: 'getIoTree',
        data: data
      }, (response => {
        console.log(response);
        // state.values.push(response);
        commit('SET_LOADING', -1);
        commit('SET_IOTREE', response);
      }));
      */
    },
    setAlarmLog: ({ commit }, alarmLog) => {
      commit('SET_ALARM_LOG', alarmLog);
    },
    setNodes: ({ commit }, response) => {
      response.forEach((element) => {
        commit('SET_NODES_IO_INDEX4', element);
      });
    },
    socketIoSetup: () => {
      SocketioService.setupSocketConnection(window.location.hostname, window.location.port, 'https:');
    },
    socketIoConnectionTimer: ({ commit }) => {
      SocketioService.connectionTimer();
      SocketioService.sockEventEssential((response, dateTime) => {
        commit('SET_SYSSTAT', response);
        commit('SET_DATETIME', dateTime);
      });
    },
    socketIoRegisterConnectionEvent: ({ commit }) => {
      SocketioService.registerConnectionEvent(response => {
        commit('SET_ACTIVE_CONNECTIONS', response);
      });
    },
    socketIoEventConnection: () => {
      SocketioService.socketEventConnection(response => {
        store.commit('SET_SOCKETIO_EVENTS', response)
      });
    },
    socketIoEventConnectionError: ({ dispatch }) => {
      SocketioService.socketEventConnectionError(response => {
        console.log(">>> SocketIO Error:", response.message);
        if (response) {
          if (response.message.includes('Authentication error')) {
            console.log(">>> SocketIO AUTH ERROR! Calling LOGOUT action...");
            dispatch('logout');
          }
        }
        //store.commit('SET_SOCKETIO_EVENTS_ERROR', response)
      });
    },
    socketIoLoadClientData: ({ commit }) => {
      SocketioService.getServerData("config", "clientData", (err, response) => {
        console.log(">>> CLIENT DATA:", response);
        commit('SET_CLIENT_DATA', response);
      });
    },
    socketIoLoadConfigIo: ({ commit }) => {
      SocketioService.getServerData("config", "io", (err, response) => {
        console.log(">>> CONFIG IO:", response);
        commit('SET_CONFIG_IO', response);
      });
    },
    socketIoLoadParameterObjects: ({ commit }) => {
      SocketioService.getServerData("config", "parameterObjects", (err, response) => {
        console.log(">>> PARAMETER OBJECTS:", response);
        commit('SET_CONFIG_OBJECTS', response);
      });
    },
    socketIoLoadActiveConnections: () => {
      SocketioService.getServerData("status", "clientInfo", (err, response) => {
        console.log(">>> CONNECTIONS:", response);
      });
    },
    socketIoLoadStatusIo: ({ commit }) => {
      SocketioService.getServerData("status", "io", (err, response) => {
        commit('SET_NODES_IO_NEW', response);
      });
    },
    socketIoLoadMap: ({ commit }) => {
      SocketioService.getServerData("status", "nodeMap", (err, response) => {
        // commit('SET_NODES_IO_NEW', response);
        let nodeMap = new Map(JSON.parse(response));
        console.log(">>> NODE-MAP:", nodeMap);
        commit('SET_NODES_MAP', nodeMap);
      });
    },
    socketIoLoadStatusTree: () => {
      SocketioService.getServerData("status", "ioTreeView", (err, response) => {
        console.log(">>> STATUS IO-TREE:", response);
      });
    },
    socketIoLoadOpcTree: ({ commit }) => {
      SocketioService.getServerData("config", "ioTreeView", (err, response) => {
        console.log(">>> OPC-TREE:", response);
        commit('SET_IOTREE', response);
      });
    },
    socketIoLoadMenu: ({ commit }) => {
      SocketioService.getServerData("view", "menu", (err, response) => {
        console.log(">>> MENU:", response);
        commit('SET_MENU', response);
      });
    },
    socketIoLoadPage: () => {
      SocketioService.getServerData("view", "page", (err, response) => {
        console.log(">>> PAGE:", response);
      });
    },
    socketIoLoadUser: () => {
      SocketioService.getServerData("users", "list", (err, response) => {
        console.log(">>> USER:", response);
      });
    },
    socketIoLoadUserGroup: () => {
      SocketioService.getServerData("users", "grouplist", (err, response) => {
        console.log(">>> USERGROUP:", response);
      });
    },
    socketIoLoadSystemLog: () => {
      SocketioService.getServerData("log", "system", (err, response) => {
        console.log(">>> SYSTEM LOG:", response);
      });
    },
    socketIoLoadAlarmLog: () => {
      SocketioService.getServerData("log", "alarm", (err, response) => {
        console.log(">>> ALARM LOG:", response);
      });
    },
    socketIoLoadChangeLog: () => {
      SocketioService.getServerData("log", "change", (err, response) => {
        console.log(">>> CHANGE LOG:", response);
      });
    },
    socketIoValueEvent: ({ commit }) => {
      SocketioService.socketEventValue(response => {
        commit('SET_NODES_IO_VALUES_NEW', response);
      });
    },
    socketIoUuidEvent: ({ commit }) => {
      SocketioService.socketEventUuid(response => {
        // socketWorker.postMessage({ method: "setup", args: [window.location.hostname, window.location.port, 'http:'] });
        commit('SET_NODES_UUID', response);
        // console.log(response, commit);
      });
    },
    async setNodes2(payload) {
      store.dispatch("setNodes", payload);
    },
    async generateItems() {
      console.log(">>> GENERATE ACTION ...");
      // socketWorker.postMessage({ method: "calculatePrimes", args: [400, 1000000000] });
    },
    async processUuid() {
      console.log(">>> PROCESS UUID ...");
      // socketWorker.postMessage({ method: "processUuid", args: [400, 1000000000] });
    },
    async startSocketIo() {

      let promisedEvents = [];

      console.log(`[INIT] initialize server connection`);
      store.dispatch("setGlobalLoader", { loading: true, init: false, title: 'lade Server Objekte' });

      store.dispatch("socketIoSetup", null);
      store.dispatch("socketIoEventConnection", null);
      store.dispatch("socketIoEventConnectionError", null);
      // store.dispatch("socketIoConnectionTimer", null);
      // store.dispatch("socketIoRegisterConnectionEvent", null);
      // store.dispatch("socketIoLoadClientData", null);
      // store.dispatch('socket/loadSystemInstallation', null);

      console.log(`[INIT] load installation`);
      promisedEvents.push(store.dispatch('socket/loadSystemInstallation', true));
      console.log(`[INIT] load app settings`);
      promisedEvents.push(store.dispatch('socket/loadSystemApp', true));
      console.log(`[INIT] load copyright`);
      promisedEvents.push(store.dispatch('socket/loadSystemCopyright', true));
      console.log(`[INIT] load menu`);
      promisedEvents.push(store.dispatch('socket/loadViewMenu', true));
      console.log(`[INIT] load OPC-UA servers`);
      promisedEvents.push(store.dispatch('opcua/loadOpcuaServers', true));
      console.log(`[INIT] load alarms`);
      promisedEvents.push(store.dispatch('opcua/loadAlarms', true));
      console.log(`[INIT] load categories`);
      promisedEvents.push(store.dispatch('types/loadCategoryTypes', true));
      console.log(`[INIT] load department`);
      promisedEvents.push(store.dispatch('types/loadDepartmentTypes', true));
      console.log(`[INIT] load modules`);
      promisedEvents.push(store.dispatch('opcua/loadModules', true));

      let result;
      try {
        result = await Promise.all(promisedEvents);
        store.dispatch("setGlobalLoader", { loading: false, init: true, title: '' });
        console.log(`[INIT] loading finished!`);
        return result;
      } catch (error) {
        console.error(`[INIT] Promise error!`)
      }
      // return Promise.all(promisedEvents);

      // store.dispatch('socket/loadSystemInstallation', null).then(() => { 
      //   console.log(`[SS] finish sysInst`);
      // });
      // store.dispatch('socket/loadSystemCopyright', null).then(() => { 
      //   console.log(`[SS] finish sysCopy`);
      // });
      // store.dispatch('socket/loadViewMenu', null).then(() => { 
      //   console.log(`[SS] finish viewMenu`);
      // });
      // store.dispatch('opcua/loadAlarms', null).then(() => { 
      //   console.log(`[SS] finish loadAlarms`);
      // });

      // store.dispatch("socketIoLoadConfigIo", null);
      // store.dispatch("socketIoLoadParameterObjects", null);
      // store.dispatch("socketIoLoadStatusIo", null);
      // store.dispatch("socketIoLoadStatusTree", null);
      // store.dispatch("socketIoLoadOpcTree", null);
      // store.dispatch("socketIoLoadMenu", null);
      // store.dispatch("socketIoLoadPage", null);
      // store.dispatch("socketIoLoadUser", null);
      // store.dispatch("socketIoLoadUserGroup", null);
      // store.dispatch("socketIoLoadSystemLog", null);
      // store.dispatch("socketIoLoadAlarmLog", null);
      // store.dispatch("socketIoLoadChangeLog", null);
      // store.dispatch("socketIoLoadActiveConnections", null);
      // store.dispatch("socketIoLoadMap");
      // store.dispatch("socketIoValueEvent", null);
      // store.dispatch("socketIoUuidEvent", null);
      //store.dispatch("generateItems", null);
    },
    loadWeatherWarnings: ({ commit }, location) => {
      // const url = "https:" + "//" + getWindowUrl().host + ":" + 3001 + getWindowUrl().path;
      const url = `https://warnungen.zamg.at/wsapp/api/getWarningsForCoords?lon=${location.lon}&lat=${location.lat}&lang=${location.lang}`
      // const url = `https://warnungen.zamg.at/wsapp/api/getWarningsForCoords`
      console.log(`[GEOSPHERE] request url: ${url}`);
      return new Promise((resolve, reject) => {
        // commit('AUTH_REQUEST')
        axios.post(url, {
          lon: location.lon,
          lat: location.lat,
          lang: location.lang
        })
        .then(response => {
            // const token = response.data.token;
            // const user  = response.data.user;
            // localStorage.setItem('token', token);
            // localStorage.setItem('user', JSON.stringify(user));
            // axios.defaults.headers.common['Authorization'] = "Bearer" +token;
            // commit('AUTH_SUCCESS', {token, user});
            commit('SET_WEATHER_WARNING', response);
            resolve(response);
        }).catch(err => { 
            // commit('AUTH_ERROR');
            // localStorage.removeItem('token');
            reject(err);
        })
      })
    },
    // async socketIoSetup() {
    //   console.log(">>> INITIATE SOCKETIO CONNECTION ...");
    //   // socketWorker.postMessage({ method: "setup", args: [window.location.hostname, window.location.port, 'http:'] });
    //   SocketioService.setupSocketConnection(window.location.hostname, window.location.port, 'http:');
    // },
    // async socketIoConnectionTimer() {
    //   // socketWorker.postMessage({ method: "connectionTimer" });
    //   SocketioService.connectionTimer();
    // },
    // async socketIoEvents() {
    //   // socketWorker.postMessage({ method: "onEventListener" });
    //   SocketioService.socketEventConnection(payload => {
    //     store.commit('SET_SOCKETIO_EVENTS', payload)
    //   });
    // },
    // async socketIoLoadStatusIo() {
    //   // socketWorker.postMessage({ method: "loadStatusIo" });
    //   store.commit('socketIoLoadStatusIo');
    // },
    // async socketIoValueEvent() {
    //   socketWorker.postMessage({ method: "onEventValue" });
    // },
    // async socketIoUuidEvent() {
    //   socketWorker.postMessage({ method: "onEventUuid" });
    // },
    // async socketIoWrite(command, parameter, payload, callback) {
    //   // SocketioService_.setServerData('writeOpc', 'value', nodesToWrite, (err, response) => {
    //   SocketioService_.setServerData(command, parameter, store.getters.getUser, payload, (err, response) => {
    //     callback(err, response);
    //   });
    // }
  }
});

// WORKER
// socketWorker.onmessage = e => {
//   // console.log(">>> ONMESSAGE ...");
//   // if (event.data.type === "working") {
//   //   if (event.data.payload) {
//   //     console.log(">>> NOW LOADING NUMSE...");
//   //   } else {
//   //     console.log(">>> NUMSE LOADED!");
//   //   }
//   // }
//   // if (event.data.type === "nums") {
//   //   console.log(">>> DIE NUMSE:", event.data.payload);
//   // }
//   // if (event.data.type === "socketio") {
//   //   store.commit(event.data.type, event.data.payload);
//   // }
//   if (e.data.type === "async") {
//     console.log(">>> ASYNC REQUEST");
//     store.dispatch("setNodes", e.data.payload);
//   } else {
//     store.commit(e.data.type, e.data.payload);
//   }
// };

export default store;
